import React from "react";
import DateFormating from './components/DateFormating';
import Loading from './components/Loading';
import Table from './components/Table';
import AccountContentHeader from './components/AccountContentHeader';
import AccountContentEmpty from './components/AccountContentEmpty';
import Button from './components/Button';
import TabItems from './components/TabItems';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ResultQuery from './components/ResultQuery';
import ResultErrors from './components/ResultErrors';
import Pagination from './components/Pagination';
import Filter from './components/Filter';
import FilterSearch from './components/FilterSearch';
import { Link } from "react-router-dom";
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import sprite from './media/icons.svg';
import books_img from './media/books.png';
import ratingStar from './media/star-rating.svg';
import star from './media/star.svg';
import { getAuth } from "firebase/auth";
class AccountLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      error: false,
      isLoaded: false,
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      library: [],
      dataBookModal: [],
      totalPages: null,
      currentPage: 0,
      isLoadedEvents: false,
      errorLibrary: null,
      indexRowLibrary: null,
      removeIndex: null,
      totalItemsLibrary: null,
      totalItemsReviews: null,
      totalItemsRequests: null,
      modalLibraryBook: false,
      successLibrarySubmit: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("successLibrarySubmit") ? JSON.parse(localStorage.getItem("successLibrarySubmit")) : null) : '',
      modalDeleteLibrary: false,
      idDeleteLibrary: null,
      heightInfo: 'auto',
      controlRow: null,
      modalIndexRow: null,
      bookingRow: null,
      heightThumb: null,
      newRewiews: null,
      isLoadedSearch: false,
      searchBookValue: '',
      searchEmpty: false,
      enabledModal: false,
      bookingUserName: null,
      bookingDate: null,
      bookingID: null,
      bookingIndex: null,
      loadingStatusSubmit: false
    }
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
      dataBookModal: []
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modalLibraryBook: false,
        enabledModal: false,
        modalDeleteLibrary: false,
        idDeleteLibrary: null,
        modalIndexRow: null,
        bookingUserName: null,
        bookingDate: null,
        bookingID: null,
        bookingIndex: null
      }));
    }, 500);
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerPaginationItems = (eventsItems, current) => {
    this.setState({
      library: eventsItems,
      currentPage: current
    });
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/library/books/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "itemsPerPage": 20,
        "orderField": "creationDate"
      })
    }).then(res => {
      if(res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            library: data.data,
            totalPages: data.totalPages,
            totalItemsLibrary: data.totalItems,
            isLoaded: true
          });
        });
        fetch(`${that.props.queryLink}/api/v1/library/books/reviews/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "itemsPerPage": 20
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                totalItemsReviews: data.totalItems
              });
            });
            fetch(`${that.props.queryLink}/api/v1/library/books/requests/search`, {
              method: 'POST',
              headers: myHeaders,
              body: JSON.stringify({
                "itemsPerPage": 20
              })
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors
                  })
                });
              } else {
                res.json().then(function(data) {
                  that.setState({
                    totalItemsRequests: data.totalItems,
                    isLoadedLibrary: true
                  })
                });
              }
            })
          }
        })

      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            errors: [data.message],
            error: true,
            isLoaded: true
          })
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            error: true,
            isLoaded: true
          })
        })
      }
    })
    })
  }

  handlerSearch(event) {
    let that = this;
      this.setState({
      isLoadedSearch: true,
      searchBookValue: event.target.value,
      searchEmpty: false
    });
    let timeoutId = setTimeout(() => {
      getAuth().currentUser.getIdToken().then(function(idToken) {
              const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/library/books/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20, "query": that.state.searchBookValue})
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedSearch: false
              });
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                isLoadedSearch: false,
                library: data.data,
                totalPages: data.totalPages,
                totalItemsLibrary: data.totalItems,
              });
              if(data.data.length < 1){
                that.setState({
                  searchEmpty: true
                });
              }
            });
          }
        });
      });
    }, 500);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = timeoutId;
  }

  handlerStatusModal(event){
    let that = this;
    let arr = that.state.library;
    that.setState({
      loadingStatusSubmit: true
    })
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/library/books/${that.state.bookingID}/enable`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        "enable": !arr[that.state.bookingIndex].enable,
      })
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            loadingStatusSubmit: false
          })
        });
      } else {
        that.handleModalDisabled();
        that.setState(prevState => ({
          library: prevState.library.map(x => x.bookID === that.state.bookingID ? {...x, enable: !arr[that.state.bookingIndex].enable, bookingDate: null, bookingUserName: null} : x),
          loadingStatusSubmit: false
        }));
        if(that.state.dataBookModal){
          let modalData = that.state.dataBookModal;
          modalData.enable = !arr[that.state.bookingIndex].enable;
          modalData.bookingDate = null;
          modalData.bookingUserName = null;
          that.setState({
            dataBookModal: modalData
          })
        }
      }
    })
   })
  }

  handlerStatus(idBook, index, modal, event){
    let that = this;
    let arr = that.state.library;
    if(modal){
      that.setState({
        bookingUserName: arr[index].bookingUserName,
        bookingDate: arr[index].bookingDate,
        bookingID: idBook,
        bookingIndex: index,
        enabledModal: true
      })
    } else {
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/library/books/${idBook}/enable`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          "enable": !arr[index].enable,
        })
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          that.setState(prevState => ({library: prevState.library.map(x => x.bookID === idBook ? {...x, enable: !arr[index].enable} : x)}));
          if(that.state.dataBookModal){
            let modalData = that.state.dataBookModal;
            modalData.enable = !arr[index].enable;
            that.setState({
              dataBookModal: modalData
            })
          }
        }
      })
     })
    }
  }
  handlerDeleteLibraryModal(id, index, event){
    if(this.state.modalLibraryBook){
      this.handleModalDisabled();
      setTimeout(() => {
        this.setState({
          modalDeleteLibrary: true,
          removeIndex: index,
          idDeleteLibrary: id
        });
      }, 500)
    } else {
      this.setState({
        modalDeleteLibrary: true,
        removeIndex: index,
        idDeleteLibrary: id
      });
    }
  }
  handlerDeleteBook(id, event){
    let that = this;
      that.setState({
        loadingDeleteSumbit: true
      })
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/library/books/${id}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          })
        });
      } else {
        that.handleModalDisabled();
        that.setState({
          indexRowLibrary: that.state.removeIndex
        });
        fetch(`${that.props.queryLink}/api/v1/library/books/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20, "page": that.state.library.length < 2 && that.state.library.length > 1 ? (that.state.currentPage - 1) : that.state.currentPage})
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                error: true
              })
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                indexRowLibrary: null,
                removeIndex: null,
                library: data.data,
                totalPages: data.totalPages,
                totalItemsLibrary: data.totalItems,
                loadingDeleteSumbit: false
              });
            })
          }
        })
      }
    })
  })
  }
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successLibrarySubmit: null
    }));
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      localStorage.removeItem('successLibrarySubmit');
    }
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerModalGetBook(id, index, event) {
      let that = this;
      if(!event.target.closest('.control-btn, .control-content')) {
        getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/library/books/${id}`, {
          method: 'GET',
          headers: myHeaders,
        }).then(res => {
          if (res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState(prevState => ({
                dataBookModal: data,
                modalLibraryBook: true,
                modalIndexRow: index,
              }));
              if(typeof window !== "undefined" && typeof document !== 'undefined'){
                let infoDiv = document.querySelector('.modal__order-content-col-info');
                let height = infoDiv.clientHeight;
                that.setState(prevState => ({
                  heightInfo: height
                }));
              }
              if(data.reviews){
                setTimeout(() => {
                  let thumb = document.querySelector('.thumb-vertical');
                  let thumbHeight = thumb.clientHeight;
                  that.setState(prevState => ({
                    heightThumb: thumbHeight
                  }));
                }, 100)
              }
            })
          }
        })
      })
    }
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerCloseElements(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const div = document.querySelector( '.control-wgt');
      if(div) {
        document.addEventListener('click', e => {
          const withinBoundaries = e.composedPath().includes(div);
          if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
            if ( ! withinBoundaries ) {
              this.setState( {
                controlRow: null
              } );
            }
          }
        });
      }
    }
  }
  handlerReturnBook(id, booking, index, event){
    let that = this;
    that.setState({
      bookingRow: id
    })
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/library/books/${id}/booking/${booking}/status`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({'bookingID': booking, 'bookingStatus': "FINISHED"})
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              bookingRow: null
            })
          });
        } else {
            if(that.state.dataBookModal){
              let modalData = that.state.dataBookModal;
              let booksData = that.state.library;
              modalData.booking = null;
              booksData[index].bookingUserName = null;
              booksData[index].bookingDate = null;
              that.setState({
                dataBookModal: modalData,
                bookingRow: null,
                library: booksData
              })
            }
        }
      })
    })
  }

handlerGetLibraryStatus(status, event){
  let that = this;
  getAuth().currentUser.getIdToken().then(function(idToken) {
          const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/library/books/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({"itemsPerPage": 20, "status": status})
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoadedSearch: false
          });
        });
      } else {
        res.json().then(function(data) {
          that.setState({
            isLoadedSearch: false,
            library: data.data,
            totalPages: data.totalPages,
            totalItemsLibrary: data.totalItems,
          });
          if(data.data.length < 1){
            that.setState({
              searchEmpty: true
            });
          }
        });
      }
    });
  });
}

  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('library-Title') + ' | ' + this.props.companyName;
    }
    if(this.state.successLibrarySubmit){
      setTimeout(() => {
        this.handlerCloseSuccess();
      }, 5000);
    }
    let resultLibrary = '';
    let resultLibraryMob = '';
    if(this.state.error) {
      resultLibrary = <div>Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultLibrary = <Loading />;
    } else {
      resultLibraryMob =               <div className="library-mob">
      {this.state.library.map((item, index) => {
      return <div className="library-wgt" key={index}>
        <div className="library-wgt__header">
          <div className="library-wgt__name">{item.title}</div>
          <div className="library-nav library-wgt__nav">
            <Link className="library-wgt__nav-btn" to={this.props.getPermalink(`/account/library/edit/${item.bookID}`)}>
              <svg className="library-wgt__nav-icon">
                <use href={`${sprite}#edit`}></use>
              </svg>
            </Link>
            <button className="library-wgt__nav-btn" type="button">
              <svg className="library-wgt__nav-icon">
                <use href={`${sprite}#delete`}></use>
              </svg>
            </button>
            <div className="tg-list-item">
              <input className="tgl tgl-light" id={`cbm-${index}`} type="checkbox" onChange={this.handlerStatus.bind(this, item.bookID, index)} checked={item.enable} /><label className="tgl-btn" htmlFor={`cbm-${index}`}></label>
            </div>
          </div>
        </div>
        <div className="library-wgt__content">
          <div className="library-wgt__content-row library-wgt__content-row_padding">
            <div className="library-wgt__content-wgt">
              <div className="library-wgt__content-wgt-label">{this.props.getTranslate('library-tableCategory')}</div>
              <div className="library-wgt__content-wgt-value">{item.bookCategory ? item.bookCategory.name : ''}</div>
            </div>
            <div className="library-wgt__content-wgt">
              <div className="library-wgt__content-wgt-label">{this.props.getTranslate('library-tableReview')}</div>
              <div className="library-wgt__content-wgt-value">{item.reviewCount}</div>
            </div>
          </div>
          <div className="library-wgt__content-row library-wgt__content-row_padding">
            <div className="library-wgt__content-wgt">
              <div className="library-wgt__content-wgt-label">{this.props.getTranslate('library-tableQR')}</div>
              <div className="library-wgt__content-wgt-value">{item.code}</div>
            </div>
            <div className="library-wgt__content-wgt">
              <div className="library-wgt__content-wgt-label">{this.props.getTranslate('library-tableStatus')}</div>
              <div className="library-wgt__content-wgt-value library-wgt__content-wgt-value_stock">В наличии</div>
            </div>
          </div>
        </div>
      </div>})}
                    </div>;
                    resultLibrary = <>

                    <div className="account-wgt account-wgt__library">

                    {/* Table component */}
                    <Table
                      header={[
                        this.props.getTranslate('library-tableName'),
                        this.props.getTranslate('library-tableCategory'),
                        this.props.getTranslate('library-tableReview'),
                        this.props.getTranslate('library-tableQR'),
                        this.props.getTranslate('library-tableStatus'),
                        ''
                      ]}
                      handlerRow={this.handlerModalGetBook.bind(this)}
                      bodyData={this.state.library}
                      bodyDataNames={['title', 'bookCategory', ['reviewCount', 'newReviewCount'], 'code', 'bookingUserName']}
                      libraryStatus={true}
                      objData={{'bookCategory': 'name'}}
                      controlItemsStatus={true}
                      controlItems={{
                        'enabled': {'status': true, 'handler': this.handlerStatus.bind(this), 'label': {'hide': this.props.getTranslate('tableDropdown-StatusLabel'), 'show': this.props.getTranslate('tableDropdown-StatusLabelShow')}},
                        'editButton': false,
                        'editLink': {
                          'status': true,
                          'handler': this.props.getPermalink('/account/library/edit/'),
                          'label': this.props.getTranslate('tableDropdown-EditLabel')
                        },
                        'delete': {'status': true, 'handler': this.handlerDeleteLibraryModal.bind(this), 'label': this.props.getTranslate('tableDropdown-EditRemove')}
                      }}
                      itemIDName={'bookID'}
                      itemEnableName={'enable'}
                      libraryStatusName={this.props.getTranslate('library-StatusInStock')}
                      libraryStatusTaken={[this.props.getTranslate('library-StatusTakeBy'), this.props.getTranslate('library-StatusTakeByFrom')]}
                      removeIndex={this.state.removeIndex}
                    />
</div></>;
    }
    let ratingReviews = '';
    if(this.state.dataBookModal){
      for (var i = 0; i < 5; i++) {
        let rating = false;
        if(i < this.state.dataBookModal.rating){
          rating = true;
        }
        ratingReviews += `<li><img src=${rating ? ratingStar : star} alt="" /></li>`;
      }
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {this.state.successLibrarySubmit && <ResultQuery success={this.state.successLibrarySubmit.status} resultText={this.state.successLibrarySubmit.text} handlerSuccess={this.handlerCloseSuccess} handlerError={this.handlerCloseError} />}

    <AccountHeader
      queryLink={this.props.queryLink}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      getTranslate={this.props.getTranslate.bind(this)}
      completed={this.props.completed}
    />

    <div className="account-wrap" onClick={this.handlerCloseElements.bind(this)}>

      {/* Navigation component */}
      <AccountNav
        getPermalink={this.props.getPermalink}
        queryLink={this.props.queryLink}
        configsApp={this.props.configsApp}
        newReview={this.props.newReview}
        companyName={this.props.companyName}
        token={this.props.token()}
        status={this.state.mobileMenu}
        statusStart={this.state.mobileMenuStart}
        handler={this.handlerMobileMenu}
        dropdownStatus={this.state.dropdownStatus}
        dropdownHandler={this.handlerDropdown}
        getTranslate={this.props.getTranslate}
        completed={this.props.completed}
      />

      <div className="account-content">
        <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            title={this.props.getTranslate('library-Title')}
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
          />

              {this.state.library.length < 1 && this.state.isLoaded && !this.state.error ?<>
                {/* Tabs component */}
                {this.state.isLoaded ?

                  <TabItems items={[
                    {
                      label: this.props.getTranslate('library-ReferencesTab'),
                      path: this.props.getPermalink('/account/library'),
                      amount: this.state.totalItemsLibrary,
                      disabled: false
                    },
                    {
                      label: this.props.getTranslate('library-ReviewTab'),
                      path: this.props.getPermalink('/account/library/reviews'),
                      amount: this.state.totalItemsReviews,
                      disabled: this.state.totalItemsReviews === 0 ? true : false,
                      className: this.props.newReview && this.props.newReview > 0 ? 'tab_theme_green' : ''
                    },
                    {
                      label: this.props.getTranslate('library-RequestTab'),
                      path: this.props.getPermalink('/account/library/request'),
                      amount: this.state.totalItemsRequests,
                      disabled: this.state.totalItemsRequests === 0 ? true : false
                    }
                  ]} /> : ''}
                  <div className="tab-navs">
                    {/* Button for add category */}
                    <Button
                      type="link"
                      icon={true}
                      iconItem="#plus-icon"
                      text={this.props.getTranslate('library-AddBtn')}
                      addClass="header-content-nav__btn"
                      handler={this.props.getPermalink('/account/library/create')}
                    />
                    {/* Filter component */}
                    <Filter
                      className="library-filter-dropdown"
                      buttonName={this.props.getTranslate('ordersFilter-Title')}
                      dataSort={[
                        {
                          name: 'Всі',
                          value: null
                        },
                        {
                          name: 'В наявності',
                          value: 'AVAILABLE'
                        },
                        {
                          name: 'Приховані',
                          value: 'HIDDEN'
                        },
                        {
                          name: 'Заброньовані',
                          value: 'BOOKED'
                        }
                      ]}
                    handler={this.handlerGetLibraryStatus.bind(this)}
                    labelDisabled={true}
                  />
                  {/* Filter search input component */}
                  <FilterSearch
                    placeholder="Пошук"
                    value={this.state.searchBookValue}
                    handler={this.handlerSearch.bind(this)}
                  />
                  </div>
                <div className="account-content__content account-content__content_empty">
                <div className="empty-wrapper">

                  {/* If the response is empty */}
                  <AccountContentEmpty
                    img={books_img}
                    title={this.props.getTranslate('library-EmptyLibraryTitle')}
                    description={this.props.getTranslate('library-EmptyLibraryDescription')}
                    buttonType="link"
                    buttonText={this.props.getTranslate('library-AddBtn')}
                    buttonIcon="#plus-icon"
                    buttonHandler={this.props.getPermalink('/account/library/create')}
                  />
                </div>
              </div></> : <div className="account-content__content">
              {/* Tabs component */}
              {this.state.isLoaded ?

              <TabItems items={[
                {
                  label: this.props.getTranslate('library-ReferencesTab'),
                  path: this.props.getPermalink('/account/library'),
                  amount: this.state.totalItemsLibrary,
                  disabled: false
                },
                {
                  label: this.props.getTranslate('library-ReviewTab'),
                  path: this.props.getPermalink('/account/library/reviews'),
                  amount: this.state.totalItemsReviews,
                  disabled: this.state.totalItemsReviews === 0 ? true : false,
                  className: this.props.newReview && this.props.newReview > 0 ? 'tab_theme_green' : ''
                },
                {
                  label: this.props.getTranslate('library-RequestTab'),
                  path: this.props.getPermalink('/account/library/request'),
                  amount: this.state.totalItemsRequests,
                  disabled: this.state.totalItemsRequests === 0 ? true : false
                }
              ]} /> : ''}
              <div className="tab-navs">
                {/* Button for add category */}
                <Button
                  type="link"
                  icon={true}
                  iconItem="#plus-icon"
                  text={this.props.getTranslate('library-AddBtn')}
                  addClass="header-content-nav__btn"
                  handler={this.props.getPermalink('/account/library/create')}
                />
                {/* Filter component */}
                <Filter
                  className="library-filter-dropdown"
                  buttonName={this.props.getTranslate('ordersFilter-Title')}
                  dataSort={[
                    {
                      name: 'Всі',
                      value: null
                    },
                    {
                      name: 'В наявності',
                      value: 'AVAILABLE'
                    },
                    {
                      name: 'Приховані',
                      value: 'HIDDEN'
                    },
                    {
                      name: 'Заброньовані',
                      value: 'BOOKED'
                    }
                  ]}
                handler={this.handlerGetLibraryStatus.bind(this)}
                labelDisabled={true}
              />
              {/* Filter search input component */}
              <FilterSearch
                placeholder="Пошук"
                value={this.state.searchBookValue}
                handler={this.handlerSearch.bind(this)}
              />
              </div>
              {resultLibraryMob}
                              {resultLibrary}</div> }
          <div className="account-content__footer">

            {/* Pagination component */}
            <Pagination
              total={this.state.totalPages}
              perPage="20"
              path={`${this.props.queryLink}/api/v1/library/books/search`}
              currentPage={this.state.currentPage}
              items={this.handlerPaginationItems}
            />

          </div>
        </div>
      </div>
    </div>
    {this.state.modalLibraryBook ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal modal__posts">
      <div className="modal-header">
        <h2 className="modal-headline">{this.state.dataBookModal.title}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <div className="modal-nav">
        {this.state.dataBookModal.booking ? <div className="modal-nav__item">
          <div className="modal-status">
            <div className="modal-status__label modal-status__label_theme_black">Взята {this.state.dataBookModal.booking.userName} з 12.03.22</div>
            <button className="modal-status__btn" type="button" onClick={this.handlerReturnBook.bind(this, this.state.dataBookModal.bookID, this.state.dataBookModal.booking.bookingID, this.state.modalIndexRow
          )}>Повернути {this.state.dataBookModal.bookID === this.state.bookingRow ? <Loading /> : ''}</button>
          </div>
        </div> : ''}
        <div className="modal-nav__item">
          <div className="modal-status">
            <div className="modal-status__label">В наявності</div>
            <div className="tg-list-item">
              <input className="tgl tgl-light" id={`modal-${'1'}`} type="checkbox" onChange={this.handlerStatus.bind(this, this.state.dataBookModal.bookID, this.state.modalIndexRow)} checked={this.state.dataBookModal.enable} /><label className="tgl-btn" htmlFor={`modal-${'1'}`}></label>
            </div>
          </div>
        </div>
        <div className="modal-nav__item">
          <Link className="btn-edit" to={this.props.getPermalink(`/account/library/edit/${this.state.dataBookModal.bookID}`)}>
            <svg className="btn-edit__icon">
              <use href={`${sprite}#edit-icon`}></use>
            </svg>
          </Link>
        </div>
        <div className="modal-nav__item">
          <button className="btn-edit btn-edit_theme_remove" onClick={this.handlerDeleteLibraryModal.bind(this, this.state.dataBookModal.bookID, this.state.modalIndexRow)}>
            <svg className="btn-edit__icon">
              <use href={`${sprite}#delete-red`}></use>
            </svg>
          </button>
        </div>
      </div>
        <div className="modal__order-content">
        <div className="modal__order-content-col modal__order-content-col-info">
          <div className="modal__order-info-headline">Інформація</div>
          <div className="modal-library-content__wrap">
            {this.state.dataBookModal && this.state.dataBookModal.bookPhotos ?
              <div className="modal-library-content__img">
                <img src={this.state.dataBookModal.bookPhotos[this.state.dataBookModal.bookPhotos.map(el => el.main).indexOf(true)].url} alt="" />
              </div> : ''}
            <div className="modal-library-content__headline">{this.props.getTranslate('libraryModal-ReviewsAndRating')}:</div>
            <div className="modal-library-content__value modal-library-content__value-rating">{this.state.dataBookModal.reviews ? this.state.dataBookModal.reviews.length : 0}
            <ul className="modal-library-content__rating" dangerouslySetInnerHTML={{__html: ratingReviews}}></ul></div>
            <div className="modal-library-content__headline">{this.props.getTranslate('libraryModal-Status')}:</div>
            <div className="modal-library-content__value">Активна</div>
            <div className="modal-library-content__headline">{this.props.getTranslate('libraryModal-Code')}:</div>
            <div className="modal-library-content__value">{this.state.dataBookModal.code}</div>
          </div>
        </div>
        <div className="modal__order-content-col modal__order-content-col-change">
          <div className="modal__order-content-history">
          <div className="modal__order-info-headline">Коментарі<span className="users-tab__link-amount">{this.state.dataBookModal.reviews && this.state.dataBookModal.reviews.length > 0 ? this.state.dataBookModal.reviews.length : '0'}</span></div>
            <Scrollbars
                renderTrackVertical={props => <div {...props} className={`track-vertical ${this.state.heightThumb > 0 ? 'track-vertical__active' : ''}`}/>}
                renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                autoHeight
                autoHeightMin={0}
                autoHeightMax={this.state.heightInfo}
                  renderView={props => <div {...props} />}>
                  {this.state.dataBookModal.reviews ? <>
                    {this.state.dataBookModal.reviews.map((item, index) => {
                      return <div className="comment-modal-wgt" key={index}>
                                                  <div className="comment-modal-wgt__inner">
                                                    <div className="comment-modal-wgt__avatar">
                                                      <Link to={this.props.getPermalink(`/account/users/view/${item.userID}`)}>
                                                        <img className="comment-modal-wgt__avatar-img" src={item.userPhoto} alt="" />
                                                      </Link>
                                                    </div>
                                                    <div className="comment-modal-wgt__content">
                                                      <div className="comment-modal-wgt__content-name">
                                                        <Link className="comment-modal-wgt__content-link" to={this.props.getPermalink(`/account/users/view/${item.userID}`)}>{item.userName}</Link>
                                                        <div className="comment-modal-wgt__content-date"><DateFormating start={item.creationDate} /></div>
                                                        <div className="comment-modal-wgt__content-txt">{item.review}</div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                    })}</> : ''}
            </Scrollbars>
          </div>
        </div>
        </div>
    </div></div> : ''}

    {this.state.modalDeleteLibrary ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('modal-deleteLibraryTitle')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
        <div className="delete-category-form__description">{this.props.getTranslate('modal-deleteLibraryDescription')}</div>
          <div className="field-wrap__submit">
            <button className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" type="button" disabled={this.state.loadingDeleteSumbit? true : false} onClick={this.handlerDeleteBook.bind(this, this.state.idDeleteLibrary)}>{this.props.getTranslate('removeModal-ButtonSubmit')}{this.state.loadingDeleteSumbit && <Loading />}</button>
          </div>
    </div>
    </div> : ''}

    {this.state.enabledModal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">Приховати літературу</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
        <div className="delete-category-form__description">Зверніть увагу, що дана література зараз використовує {this.state.bookingUserName} з <DateFormating start={this.state.bookingDate} time="off" />. Якщо ви приховаєте цю літературу, вона буде автоматично знята з бронювання.</div>
        <div className="modal-info__nav">
          <div className="modal-info__nav-col">
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="button" disabled={this.state.loadingStatusSubmit? true : false} onClick={this.handlerStatusModal.bind(this)}>Приховати{this.state.loadingStatusSubmit && <Loading />}</button>
            </div>
          </div>
          <div className="modal-info__nav-col">
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg btn_theme_secondary btn_width_100 user-info__nav-btn" type="button" onClick={this.handleModalDisabled.bind(this)}>Скасувати</button>
            </div>
          </div>
        </div>
    </div>
    </div> : ''}

    </>;
  }
}
export default AccountLibrary;
