import React from "react";
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import { getAuth } from "firebase/auth";
class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      configsNotification: {},
      modalStatus: false,
      isLoadedStatus: false,
      errors: null,
      isLoaded: false
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    if (typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
      modalStatus: false
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalStatus: false,
        modalDisabled: false
      }));
    }, 500);
  }
  componentDidMount() {
    if (typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/emails/config`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        res.json().then(function(data) {
          that.setState(prevState => ({
            configsNotification: data
          }));
        })
      })
    })
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerStatus(name, event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      let obj = that.state.configsNotification;
      obj[name] = !obj[name];
      fetch(`${that.props.queryLink}/api/v1/emails/config`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(obj)
      }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            configsNotification: obj
          });
        });
      }
    })
    })
  }
  render() {
    if (typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('settings-Title') + ' | ' + this.props.companyName;
    }
    return <>
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
    <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
    <div className="account-content">
      <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
          title={this.props.getTranslate('settings-Title')}
        />

        <div className="account-content__content">
          <div className="account-wgt account-wgt__settings">
            <table className="team-table table-settings">
              <thead>
                <tr>
                  <th>{this.props.getTranslate('settingsNotification-TableName')}</th>
                  <th>{this.props.getTranslate('settingsNotification-TableSettings')}</th>
                </tr>
              </thead>
              <tbody>
                <tr className={`team-table__row ${this.state.configsNotification.newOrder ? '' : 'team-table__row_status_disabled'}`}>
                  <td className="team-table__name">{this.props.getTranslate('settingsNotification-TableValueStore')}</td>
                  <td className="team-table__control">
                    <div className="team-table__nav">
                      <div className="tg-list-item">
                        <input className="tgl tgl-light" id="order" checked={this.state.configsNotification.newOrder} type="checkbox" onChange={this.handlerStatus.bind(this, 'newOrder')} /><label className="tgl-btn" htmlFor="order"></label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className={`team-table__row ${this.state.configsNotification.bonusNomination ? '' : 'team-table__row_status_disabled'}`}>
                  <td className="team-table__name">{this.props.getTranslate('settingsNotification-TableValueBonuses')}</td>
                  <td className="team-table__control">
                    <div className="team-table__nav">
                      <div className="tg-list-item">
                        <input className="tgl tgl-light" id="bonuses" checked={this.state.configsNotification.bonusNomination} type="checkbox" onChange={this.handlerStatus.bind(this, 'bonusNomination')} /><label className="tgl-btn" htmlFor="bonuses"></label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className={`team-table__row ${this.state.configsNotification.surveyEnding ? '' : 'team-table__row_status_disabled'}`}>
                  <td className="team-table__name">{this.props.getTranslate('settingsNotification-TableValuePolls')}</td>
                  <td className="team-table__control">
                    <div className="team-table__nav">
                      <div className="tg-list-item">
                        <input className="tgl tgl-light" id="survey" checked={this.state.configsNotification.surveyEnding} type="checkbox" onChange={this.handlerStatus.bind(this, 'surveyEnding')} /><label className="tgl-btn" htmlFor="survey"></label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className={`team-table__row ${this.state.configsNotification.newAd ? '' : 'team-table__row_status_disabled'}`}>
                  <td className="team-table__name">{this.props.getTranslate('settingsNotification-TableValueMarketplace')}</td>
                  <td className="team-table__control">
                    <div className="team-table__nav">
                      <div className="tg-list-item">
                        <input className="tgl tgl-light" id="ad" checked={this.state.configsNotification.newAd} type="checkbox" onChange={this.handlerStatus.bind(this, 'newAd')} /><label className="tgl-btn" htmlFor="ad"></label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className={`team-table__row ${this.state.configsNotification.bookRequest ? '' : 'team-table__row_status_disabled'}`}>
                  <td className="team-table__name">{this.props.getTranslate('settingsNotification-TableValueLibrary')}</td>
                  <td className="team-table__control">
                    <div className="team-table__nav">
                      <div className="tg-list-item">
                        <input className="tgl tgl-light" id="library" checked={this.state.configsNotification.bookRequest} type="checkbox" onChange={this.handlerStatus.bind(this, 'bookRequest')} /><label className="tgl-btn" htmlFor="library"></label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>
      </div>
    </div>
    </>;
  }
}
export default Notification;
