import React from "react";
import DateFormating from './components/DateFormating';
import Loading from './components/Loading';
import ResultQuery from './components/ResultQuery';
import ResultErrors from './components/ResultErrors';
import AccountContentHeader from './components/AccountContentHeader';
import AccountContentEmpty from './components/AccountContentEmpty';
import { Link } from "react-router-dom";
import Button from './components/Button';
import Table from './components/Table';
import Pagination from './components/Pagination';
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import ads_img from './media/ads.png';
import sprite from './media/icons.svg';
import { getAuth } from "firebase/auth";
class Reminder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      error: false,
      isLoaded: false,
      indexRowEvent: null,
      removeIndex: null,
      errors: null,
      currentPage: 0,
      totalPages: null,
      controlRow: null,
      reminders: [],
      successText: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("successReminderSubmit") ? JSON.parse(localStorage.getItem("successReminderSubmit")).text : null) : '',
      successReminderSubmit: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("successReminderSubmit") ? JSON.parse(localStorage.getItem("successReminderSubmit")) : null) : '',
    }
  }
  /* Handler for get mobile menu */
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };

  /* Handler for dropdown */
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }

  /* Pagination handler */
  handlerPaginationItems = (categoriesItems, current) => {
    this.setState({
      ads: categoriesItems,
      currentPage: current
    });
  }

  /* Handler for close success labels */
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successReminderSubmit: null,
      successText: null
    }));
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      localStorage.removeItem('successReminderSubmit');
    }
  }

  /* Handler for close errors labels */
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }

  /* Handler get data mount component */
  componentDidMount() {
    let that = this;
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/reminders/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(
        {
          "itemsPerPage": 20,
          "orderField": "creationDate"
        }
      )
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            reminders: data.data,
            totalPages: data.totalPages
          })
        });
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            errors: [data.message],
            error: true
          })
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            errors: data.errors,
            error: true
          })
        });
      }
    })
  })
  }

  /* Ads disabled modal */
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
      dataAdModal: []
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modalAd: false,
        modalDeleteAds: false,
        idDeleteAds: null
      }));
    }, 500);
  }

  /* Handler for change status */
  handlerEnabled(id, index, event){
    let that = this;
    let arr = that.state.reminders;
  }

  handlerDeleteRemindersModal(event){

  }

  /* Render component */
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.title = "Нагадування" + ' | ' + this.props.companyName;
    }
    if(this.state.successReminderSubmit){
      setTimeout(() => {
        this.handlerCloseSuccess();
      }, 5000);
    }
    let resultReminders = '';
    if(this.state.error) {
      resultReminders = <div>{this.props.getTranslate('loading-ErrorRenderContent')}</div>;
    } else if(!this.state.isLoaded) {
      resultReminders = <Loading />;
    } else {
      resultReminders = <>
        <div className="account-wgt account-wgt__board">
        {/* Table component */}
          <Table
            header={[
              "Назва",
              "Кіл. чол.",
              "Дата проведення",
              "Статус",
              ''
            ]}
            bodyData={this.state.reminders}
            bodyDataNames={['name', 'employeeCount', ['startTime', 'endTime'], 'reminderStatus']}
            controlItemsStatus={true}
            controlItems={{
              'enabled': false,
              'editButton': false,
              'editLink': {
                'status': true,
                'handler': this.props.getPermalink(`/account/reminder/edit/`),
                'label': this.props.getTranslate('tableDropdown-EditLabel')
              },
              'delete': {
                'status': true,
                'handler': this.handlerDeleteRemindersModal.bind(this),
                'label': this.props.getTranslate('tableDropdown-EditRemove')
              }
            }}
            translationValueItems={{
                'ACTIVE': "Активний",
                'FINISHED': "Закінчився",
                'PAUSED': "Зупинено"
              }}
            dateValueFormat={{
              start: 'startTime',
              end: 'endTime',
              timeOff: true,
              timeEnd: true,
              separatorTime: 'о'
            }}
            labelsStatus={{
              'reminderStatus': {
                'ACTIVE': 'blue',
                'FINISHED': 'red',
                'PAUSED ': 'gray'
              }
            }}
            itemIDName={'reminderID'}
            itemEnableNameForStatus={'FINISHED'}
            itemEnableNameForStatusData={'reminderStatus'}
            enabledMobileContent={true}
            mobileContentType={'block'}
            mobileContentHeader={[
              this.props.getTranslate('marketplace-User'),
              this.props.getTranslate('marketplace-Date'),
              this.props.getTranslate('marketplace-Status')
            ]}
            mobileContentData={['name', 'employeeCount', ['startTime', 'endTime'], 'reminderStatus']}
            removeIndex={this.state.removeIndex}
          />
        </div>
      </>
    }

    return <>
    {/* Error handler */}
    <ResultErrors
      errors={this.state.errors}
      closeHandler={this.handlerCloseError.bind(this)}
    />

    {/* Query result handler */}
    {this.state.successReminderSubmit && <ResultQuery
      success={this.state.successReminderSubmit}
      resultText={this.state.successText}
      handlerSuccess={this.handlerCloseSuccess}
      handlerError={this.handlerCloseError}
    />}

    {/* Header component */}
    <AccountHeader
      queryLink={this.props.queryLink}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      getTranslate={this.props.getTranslate.bind(this)}
      completed={this.props.completed}
    />

    <div className="account-wrap">

    {/* Navigation component */}
    <AccountNav
      getPermalink={this.props.getPermalink}
      queryLink={this.props.queryLink}
      configsApp={this.props.configsApp}
      newReview={this.props.newReview}
      companyName={this.props.companyName}
      token={this.props.token()}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      dropdownStatus={this.state.dropdownStatus}
      dropdownHandler={this.handlerDropdown}
      getTranslate={this.props.getTranslate}
      completed={this.props.completed}
    />

    <div className="account-content">
      <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          title="Нагадування"
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
        />

        <div className="team-nav team-nav_space_between">

          {/* Button for add category */}
          <Button
            type="link"
            icon={true}
            iconItem="#plus-icon"
            text="Додати нагадування"
            addClass="header-content-nav__btn"
            handler={this.props.getPermalink('/account/reminder/create')}
          />


        </div>

        {this.state.reminders.length < 1 && this.state.isLoaded && !this.state.error ? <>
          <div className="account-content__content account-content__content_empty">
            <div className="empty-wrapper">

              {/* If the response is empty */}
              <AccountContentEmpty
                img={ads_img}
                title={this.props.getTranslate('ads-EmptyAdsTitle')}
                buttonType="link"
                buttonText="Додати нагадування"
                buttonIcon="#plus-icon"
                buttonHandler={this.props.getPermalink('/account/reminder/create')}
              />

            </div>
            </div></> : <><div className="account-content__content">

              {/* Query result */}
              {resultReminders}

            </div></>}
            <div className="account-content__footer">

              {/* Pagination component */}
              <Pagination
                total={this.state.totalPages}
                perPage="20"
                path={`${this.props.queryLink}/api/v1/reminder/search`}
                currentPage={this.state.currentPage}
                items={this.handlerPaginationItems}
              />

            </div>
          <div>
        </div>
      </div>
    </div>
    </div>

    </>;
  }
}
export default Reminder;
