import React from "react";
import Loading from './components/Loading';
import { Link, useParams } from "react-router-dom";
import AccountHeader from './components/AccountHeader';
import Navigation from './components/Navigation';
import ResultErrors from './components/ResultErrors';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
class ControlPanelAccessEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      errors: null,
      error: false,
      isLoaded: false
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modal: false
      }));
    }, 500);
  }
  componentDidMount() {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/users/${that.props.id}/roles/`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          console.log(data);
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            isLoaded: true
          });
        })
      }
    })
  })
}
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  render() {
    let resultUsers = '';
    if(this.state.error) {
      resultUsers = <div>Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultUsers = <Loading />;
    } else {

    }
    return <>
      <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
      <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-wrap account-wrap__admin">
        <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
        <div className="account-content">
          <div className="account-content__inner">
            <div className="account-content__header">
              <div className="account-content__header-inner">
                <h1 className="account-content__headline">Доступы</h1>
              </div>
              <a className="header__language header__language-content" href="#">RU</a>
            </div>
            <div className="account-content__content">

            </div>

          </div>
        </div>
      </div>
    </>
  }
}
export default (props) => <ControlPanelAccessEdit {...useParams()} {...props} />
