import React from "react";
import { Link } from "react-router-dom";
import Button from './Button';
import sprite from '../media/icons.svg';
class AccountContentEmpty extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return  <div className="empty-wrapper__inner">
                  <img className="empty-wrapper__img" src={this.props.img} alt="" />
                  <h1 className="empty-wrapper__headline">{this.props.title}</h1>
                  <p className="empty-wrapper__description">{this.props.description}</p>
                    {this.props.buttonType ? <Button type={this.props.buttonType} btnSize="large" icon={true} iconItem={this.props.buttonIcon} text={this.props.buttonText} handler={this.props.buttonHandler} /> : ''}
                </div>
  }
}
export default AccountContentEmpty;
