import React from "react";
import Loading from './components/Loading';
import { useParams, Navigate } from "react-router-dom";
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import sprite from './media/icons.svg';
import { getAuth } from "firebase/auth";
let arrayMoveImmutable;
if (typeof window !== 'undefined') {
  // Выполняем импорт только в клиентской среде
  const { arrayMoveImmutable: clientArrayMoveImmutable } = require('array-move');
  arrayMoveImmutable = clientArrayMoveImmutable;
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const SortableItem = SortableElement(({item, value, handlerDelete}) => <div><button className="edit-product-img__remove" type="button" onClick={handlerDelete.bind(this, value.photoID)}><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><span className="product-num">{item + 1}</span>{item < 1 ? <span className="product-label">Обложка</span> : ''}<img src={value.url} alt="" /></div>);
const SortableList = SortableContainer(({items, handlerDelete}) => {
  return (
    <div className="edit-product-img">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} item={index} index={index} value={value} handlerDelete={handlerDelete} />
      ))}
    </div>
  );
});
class LibraryEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      modalDisabled: false,
      modal: false,
      modalEditCategory: false,
      successSubmit: false,
      headlineValue: '',
      authorValue: '',
      descriptionValue: '',
      codeValue: '',
      enableValue: false,
      categoriesResult: [],
      bookCategory: {},
      photos: [],
      formData: null,
      percentValue: 0,
      newMainPhoto: {},
      isLoadedSubmit: false,
      newGategories: [],
      newCategoriesForSubmit: [],
      newCategoriesLoadingSubmit: false,
      editCategoriesIndex: [],
      newCategoriesName: [],
      errors: null,
      error: false,
      isLoaded: false,
      codeValueErrorLength: false
    }
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/library/books/${that.props.id}`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            error: true,
            isLoaded: true
          })
        })
      } else {
        fetch(`${that.props.queryLink}/api/v1/library/books/categories`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => {
          if (res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              })
            })
          } else {
            res.json().then(function(data) {
              let resultData = data;
              let newObj = resultData.map(function(obj) {
                return {label: obj.name, value: obj.categoryID}
              });
              that.setState( {categoriesResult: newObj} )
            });
          }
        })
        res.json().then(function(data) {
          let obj = {
            isLoaded: true,
            headlineValue: data.title,
            authorValue: data.author,
            descriptionValue: data.description,
            enableValue: data.enable,
            photos: data.bookPhotos,
            codeValue: data.code,
            oldCodeValue: data.code
          };
          if(data.bookCategory){
            obj.bookCategory = {value: data.bookCategory.categoryID, label: data.bookCategory.name};
          }
          that.setState(obj);
        });
      }
    })
  })
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(prevState => ({
      newMainPhoto: newIndex < 1 ? this.state.photos[oldIndex] : null,
      photos: arrayMoveImmutable(prevState.photos, oldIndex, newIndex)
    }));
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        modalEditCategory: false
      }));
    }, 500);
  }
  handlerHeadlineValue(event){
    this.setState({
      headlineValue: event.target.value
    });
  }
  handlerAuthorValue(event){
    this.setState({
      authorValue: event.target.value
    });
  }
  handlerDescriptionValue(event){
    this.setState({
      descriptionValue: event.target.value
    });
  }
  handlerCodeValue(event){
    if (event.target.value.length <= 6) {
      this.setState({
        codeValue: event.target.value,
        codeValueErrorLength: false
      });
    } else {
      this.setState({
        codeValueErrorLength: true
      });
    }
  }
  handlerEnableValue(){
    this.setState(prevState => ({
      enableValue: !prevState.enableValue
    }));
  }
  handleEditCategories () {
    this.setState(prevState => ({
      modal: !prevState.modal,
      modalEditCategory: true
    }));
  }
  handlerSetGategory(event){
    this.setState({
      bookCategory: event
    });
  }
  hanlderRemoveProductImage(id, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/library/books/${that.props.id}/photos/${id}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          })
        });
      } else {
        let photos = that.state.photos;
        let index = photos.map(el => el.photoID).indexOf(id);
        photos.splice(index, 1);
        that.setState(prevState => ({
          prevState: [...prevState.photos, photos]
        }));
      }
    })
  })
  }

  editDataFunction(myHeaders, idToken, event){
    let that = this;
    let obj = {
      "author": that.state.authorValue,
      "bookID": that.props.id,
      "category": {
        "categoryID": that.state.bookCategory.value,
        "name": that.state.bookCategory.label
      },
      "code": that.state.codeValue,
      "description": that.state.descriptionValue,
      "enable": that.state.enableValue,
      "title": that.state.headlineValue
    };
    fetch(`${that.props.queryLink}/api/v1/library/books/${that.props.id}`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(obj)
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            successSubmit: false,
            isLoadedSubmit: false,
            errors: data.errors
          });
        });
      } else {
        if(!that.state.formData && !that.state.newMainPhoto.photoID){
          if(typeof window !== "undefined" && typeof document !== 'undefined'){
            localStorage.setItem("successLibrarySubmit", JSON.stringify({status: true, text: 'Успешно сохранено'}));
          }
          that.setState({
            successSubmit: true,
            isLoadedSubmit: false
          });
          setTimeout(() => {
            that.setState({
              successSubmit: false
            });
          }, 5000);
        }
        res.json().then(function(data) {
        if(that.state.formData){
          const myHeaders = new Headers({
            'Authorization': 'Bearer ' + idToken
          });
          async function asyncAwaitWay(that) {
            let formData = new FormData();
            let i = that.state.formData.length;
            let countPercent = 100 / i;
                  for (let file of that.state.formData) {
                    formData.append('file', file);
                    await  fetch(`${that.props.queryLink}/api/v1/library/books/${data.bookID}/photos`, {
                      method: 'POST',
                      headers: myHeaders,
                      body: formData
                    }).then(res => {
                      if(res.status !== 200) {
                        res.json().then(function(data) {
                          that.setState({
                            errors: data.errors
                          });
                        });
                      } else {
                        that.setState(prevState => ({
                          percentValue: (Math.ceil(prevState.percentValue + countPercent))
                        }));
                        i--;
                        if(i < 1){
                          if(typeof window !== "undefined" && typeof document !== 'undefined'){
                            localStorage.setItem("successLibrarySubmit", JSON.stringify({status: true, text: 'Успешно сохранено'}));
                          }
                          that.setState({
                            isLoadingSubmit: false,
                            successSubmit: true
                          });
                          setTimeout(() => {
                            that.setState({
                              successSubmit: false
                            });
                          }, 5000);
                        }
                      }
                    })
                    formData.delete('file');
                  }
          }
          asyncAwaitWay(that);
        }
        if(that.state.newMainPhoto.photoID){
          const myHeaders = new Headers({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + idToken
          });
          fetch(`${that.props.queryLink}/api/v1/library/books/${that.props.id}/photos/${that.state.newMainPhoto.photoID}`, {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({
              "main": true,
              "photoID": that.state.newMainPhoto.photoID,
              "url": that.state.newMainPhoto.url
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                })
              });
            } else {
              if(typeof window !== "undefined" && typeof document !== 'undefined'){
                localStorage.setItem("successLibrarySubmit", JSON.stringify({status: true, text: 'Успешно сохранено'}));
              }
              that.setState({
                isLoadingSubmit: false,
                successSubmit: true
              });
              setTimeout(() => {
                that.setState({
                  successSubmit: false
                });
              }, 5000);
            }
          })
        }
      });

      }
    })
  }

  handlerAddPhotos(event){
    let files = event.target.files;
    let formData = this.state.formData;
    if(formData){
      const dt = new DataTransfer();
      for (let file of formData) {
        dt.items.add(file)
      }
      for (let file of files) {
        dt.items.add(file)
      }
      this.setState(prevState => ({
        formData: Array.from(dt.files)
      }));
    } else {
      this.setState(prevState => ({
        formData: Array.from(files)
      }));
    }
  }
  handlerSubmit(event) {
    event.preventDefault();
    let that = this;
    that.setState({
      isLoadedSubmit: true,
      errorCodeValid: false
    });
    let location;
    let language = 'uk-UA';
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      location = document.location.pathname;
      const isEnglish = location.startsWith('/en');
      if (isEnglish) {
        language = 'en-US'
      }
    }
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken,
          'Accept-Language': language
      });
      if(that.state.oldCodeValue !== that.state.codeValue){
    fetch(`${that.props.queryLink}/api/v1/library/books/code`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({code: that.state.codeValue, bookId: that.props.id})
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errorCodeValid: data.errors,
            isLoadedSubmit: false
          });
        });
      } else {
        that.editDataFunction(myHeaders, idToken);
      }
    })
  } else {
    that.editDataFunction(myHeaders, idToken);
  }
  })
  }
  handlerSubmitCategories(event){
    let that = this;
    if(this.state.newCategoriesForSubmit.length > 0 || this.state.newCategoriesName.length > 0){
      this.setState({
        newCategoriesLoadingSubmit: true
      });
    }
    if(this.state.newCategoriesForSubmit){
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      let categories = that.state.newCategoriesForSubmit
      async function asyncAwaitWay(that) {
        for (let category of categories) {
          fetch(`${that.props.queryLink}/api/v1/library/books/categories`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "categoryID": 0,
              "name": category
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                })
              });
              } else {
              categories.shift();
              if(categories.length < 1){
                that.setState(prevState => ({
                  newGategories: [],
                  newCategoriesLoadingSubmit: false
                }));
                that.handleModalDisabled();
                fetch(`${that.props.queryLink}/api/v1/library/books/categories`, {
                  method: 'GET',
                  headers: myHeaders
                }).then(res => {
                  if (res.status !== 200) {
                    res.json().then(function(data) {
                      that.setState({
                        errors: data.errors
                      })
                    });
                  } else {
                    res.json().then(function(data) {
                      let resultData = data;
                      let newObj = resultData.map(function(obj) {
                        return {label: obj.name, value: obj.categoryID}
                      });
                      that.setState( {categoriesResult: newObj} )
                    });
                  }
                })
              }
            }
          })
        }
      }
      asyncAwaitWay(that);
    })
    }
    if(this.state.newCategoriesName){
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      let categories = that.state.newCategoriesName;
      async function asyncAwaitWay(that) {
        for (let category of categories) {
          fetch(`${that.props.queryLink}/api/v1/library/books/categories/${category.categoryID}`, {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify(category)
          }).then(res => {
            if (res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                })
              });
            } else {
              res.json().then(function(data) {
                categories.shift();
                if(categories.length < 1){
                  that.setState(prevState => ({
                    newCategoriesName: [],
                    newCategoriesLoadingSubmit: false
                  }));
                  that.handleModalDisabled();
                }
              });
            }
          })
        }
      }
      asyncAwaitWay(that);
    })
    }
  }
  handlerAddCreateCategory(index, event){
    let obj = {label: this.state.newGategories[index]};
    this.setState(prevState => ({
      categoriesResult: [obj, ...prevState.categoriesResult],
      newCategoriesForSubmit: [...prevState.newCategoriesForSubmit, obj.label],
      newGategories: [...prevState.newGategories.slice(0, index), ...prevState.newGategories.slice(index + 1)]
    }));
  }
  handlerCreateCategory(event){
    this.setState(prevState => ({
      newGategories: ['', ...prevState.newGategories]
    }));
  }
  handerNewGategoryName(index, event){
    const newGategories = this.state.newGategories;
    newGategories.splice(index, 1, event.target.value);
    this.setState(prevState => ({
      newGategories: newGategories
    }));
  }
  handlerDeleteCategoryById(categoryID, index, event){
    let that = this;
    if(categoryID){
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/library/books/categories/${categoryID}`, {
        method: 'DELETE',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          that.setState(prevState => ({
            categoriesResult: prevState.categoriesResult.filter(el => el.value !== categoryID)
          }));
        }
      })
    })
    } else {
      this.setState(prevState => ({
        newGategories: [...prevState.newGategories.slice(0, index), ...prevState.newGategories.slice(index + 1)],
        categoriesResult: [...prevState.categoriesResult.slice(0, index), ...prevState.categoriesResult.slice(index + 1)]
      }));
    }
  }
  handlerDeleteCategory(index, event){
    this.setState(prevState => ({
      newGategories: [...prevState.newGategories.slice(0, index), ...prevState.newGategories.slice(index + 1)]
    }));
  }
  handlerEditCategoryName(index, event){
    this.setState(prevState => ({
      editCategoriesIndex: [...prevState.editCategoriesIndex, index]
    }));
  }
  handlerEditCategoryNameValue(index, event){
    const newGategories = this.state.categoriesResult;
    newGategories[index].label = event.target.value;
    this.setState(prevState => ({
      categoriesResult: newGategories
    }));
  }
  handlerSaveCategoryName(index, event){
    this.setState(prevState => ({
      newCategoriesName: [...prevState.newCategoriesName, {"categoryID": this.state.categoriesResult[index].value, "name": this.state.categoriesResult[index].label}],
      editCategoriesIndex: prevState.editCategoriesIndex.filter(el => el !== index)
    }));
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('library-editTitle') + ' | ' + this.props.companyName;
    }
    let resultContent = '';
    if(this.state.error) {
      resultContent = <div className="errors-content">Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultContent = <Loading />;
    } else {
      resultContent =             <div className="library-form-wrap">
              <form className="library-form" onSubmit={this.handlerSubmit.bind(this)}>
                <div className="library-form__inner">
                  <div className="field-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">{this.props.getTranslate('library-addFormTitle')}</label>
                    <input id="headline" className="field-wrap__input field-wrap__input_width_75" type="text" value={this.state.headlineValue} onChange={this.handlerHeadlineValue.bind(this)} placeholder={this.props.getTranslate('library-addFormTitlePlaceholder')} />
                  </div>
                  <div className="field-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="author">{this.props.getTranslate('library-addFormAuthor')}</label>
                    <input id="author" className="field-wrap__input" type="text" value={this.state.authorValue} onChange={this.handlerAuthorValue.bind(this)} placeholder={this.props.getTranslate('library-addFormAuthorPlaceholder')} />
                  </div>
                  <div className="field-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">{this.props.getTranslate('library-addFormDescription')}</label>
                    <textarea className="field-wrap__input field-wrap__input_textarea" value={this.state.descriptionValue} onChange={this.handlerDescriptionValue.bind(this)} placeholder={this.props.getTranslate('library-addFormDescriptionPlaceholder')} />
                  </div>
                  <div className="board-form__row">
                    <div className="field-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('library-addFormCategory')}</label>
                      <Select
                        styles={customStyles}
                        options={this.state.categoriesResult}
                        isSearchable={false}
                        value={this.state.bookCategory}
                        onChange={this.handlerSetGategory.bind(this)}
                        placeholder={this.props.getTranslate('library-addFormCategoryPlaceholder')}
                      />
                      <button className="library-form__categories-edit account-wgt__edit-btn" type="button" onClick={this.handleEditCategories.bind(this)}>{this.props.getTranslate('library-addFormCategoryEditBtn')}</button>
                    </div>
                    <div className="field-wrap field-wrap__tooltip">
                      <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-0" type="checkbox" checked={this.state.enableValue} onChange={this.handlerEnableValue.bind(this)} /><label htmlFor="styled-checkbox-0"></label></div><span>{this.props.getTranslate('library-addFormShow')}</span></div>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('library-addFormShowDescription')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="board-form__row">
                    <div className="field-wrap">
                      <div className="field-wrap__label-wrap">
                        <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="code">{this.props.getTranslate('library-addFormCode')}</label>
                        <div className="tooltip-info">
                          <span className="tooltip-info__icon">?</span>
                          <div className="tooltip-info__content">
                            <div className="arrow"></div>
                            <p>{this.props.getTranslate('tooltipLibrary-Code')}</p>
                          </div>
                        </div>
                      </div>
                      <input id="code" className={`field-wrap__input ${this.state.errorCodeValid || this.state.codeValueErrorLength ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.codeValue} onChange={this.handlerCodeValue.bind(this)} />
                      {this.state.errorCodeValid ? <div className="validation-error">{this.state.errorCodeValid}</div> : ''}
                      {this.state.codeValueErrorLength ? <div className="validation-error">Довжина коду не повина перевищувати 6 символів</div> : ''}
                    </div>
                  </div>
                  <div className="edit-user-form__imgs">
                    <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('library-addFormPhotos')}</label>
                    <div className="edit-user-form__imgs-inner">
                      <div className="edit-user-upload">
                        <input type="file" id="upload" multiple onChange={this.handlerAddPhotos.bind(this)} />
                        <label htmlFor="upload">
                        <svg className="upload__icon">
                          <use href={`${sprite}#img-upload`}></use>
                        </svg>
                        {this.props.getTranslate('library-addFormPhotos')} {this.state.formData && <span style={{marginTop: '5px'}}>{this.props.getTranslate('loadingImg-AmountTitle')} {this.state.formData.length}</span>}</label>
                      </div>
                      {this.state.formData && this.state.isLoadedSubmit ? <div className="edit-user-upload">
                        <div className="edit-user-upload__percent">
                          <span>{this.props.getTranslate('loadingImg-PercentTitle')} {this.state.percentValue}%</span>
                          <div className="edit-user-upload__bar">
                            <div className="edit-user-upload__bar-inner" style={{width: this.state.percentValue + '%'}}></div>
                          </div>
                        </div>
                      </div> : ''}
                      {this.state.photos ? <SortableList items={this.state.photos} onSortEnd={this.onSortEnd} axis={'xy'} handlerDelete={this.hanlderRemoveProductImage.bind(this)} /> : ''}
                    </div>
                  </div>
                </div>
                <div className="field-wrap__submit">
                  <button className="btn btn_size_lg" type="submit" disabled={this.state.isLoadedSubmit ? true : false}>{this.props.getTranslate('library-editBtnSubmit')}{this.state.isLoadedSubmit && <Loading />}</button>
                </div>
              </form>
            </div>
    }
    return <>
      <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
      {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to={this.props.getPermalink('/account/library')} replace={true} />) : ''}
      <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
      <div className="account-wrap">
        <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
        <div className="account-content">
          <div className="account-content__inner">

            {/* Account header component */}
            <AccountContentHeader
              handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
              languageActive={this.props.chooseLanguage}
              backBtn={true}
              backBtnType="link"
              backBtnClass="button-back_theme_gray"
              backBtnHandler={this.props.getPermalink("/account/library")}
              backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
            />

            <div className="account-content__header-title">
              <h1 className="account-content__headline">{this.props.getTranslate('library-editTitle')}</h1>
            </div>
            {resultContent}
          </div>
        </div>
      </div>
      {this.state.modalEditCategory ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">{this.props.getTranslate('modalCategories-EditTitle')}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <div>
          <div className="edit-category__add-category">
            <button className="btn btn_width_100 btn_theme_transparent btn_size_lg" onClick={this.handlerCreateCategory.bind(this)}>{this.props.getTranslate('modalCategories-addButton')}</button>
          </div>
          {this.state.newGategories ? <>
          {this.state.newGategories.map((item, index) => {
            return     <div className="categories-faq-wgt" key={index}>
            <div className="categories-faq-wgt__inner">
                        <input className="categories-faq-wgt__input" onChange={this.handerNewGategoryName.bind(this, index)} value={item} />
                        <div className="categories-faq-wgt__nav">
                          <button className="categories-faq-wgt__nav-btn categories-faq-wgt__nav-btn-add" type="button" onClick={this.handlerAddCreateCategory.bind(this, index)}>
                            <svg>
                              <use href={`${sprite}#check-icon`}></use>
                            </svg>
                          </button>
                          <button className="categories-faq-wgt__nav-btn" type="button">
                            <svg>
                              <use href={`${sprite}#edit`}></use>
                            </svg>
                          </button>
                          <button className="categories-faq-wgt__nav-btn" type="button" onClick={this.handlerDeleteCategory.bind(this, index)}>
                            <svg>
                              <use href={`${sprite}#delete`}></use>
                            </svg>
                          </button>
                        </div>
                        </div>
                      </div>
          })}</> : ''}
          {this.state.categoriesResult.map((item, index) => {
            return     <div className="categories-faq-wgt" key={index}>
            <div className="categories-faq-wgt__inner">
                        {this.state.editCategoriesIndex.indexOf(index) !== -1 ? <input className="categories-faq-wgt__input" value={item.label} onChange={this.handlerEditCategoryNameValue.bind(this, index)} /> : <div className="categories-faq-wgt__name">{item.label}</div>}
                        <div className="categories-faq-wgt__nav">
                        {this.state.editCategoriesIndex.indexOf(index) !== -1 ? <button className="categories-faq-wgt__nav-btn categories-faq-wgt__nav-btn-add" type="button" onClick={this.handlerSaveCategoryName.bind(this, index)}>
                          <svg>
                            <use href={`${sprite}#check-icon`}></use>
                          </svg>
                        </button> : ''}
                          <button className="categories-faq-wgt__nav-btn" onClick={this.handlerEditCategoryName.bind(this, index)} type="button">
                            <svg>
                              <use href={`${sprite}#edit`}></use>
                            </svg>
                          </button>
                          <button className="categories-faq-wgt__nav-btn" type="button" onClick={this.handlerDeleteCategoryById.bind(this, item.value, index)}>
                            <svg>
                              <use href={`${sprite}#delete`}></use>
                            </svg>
                          </button>
                        </div>
                        </div>
                      </div>
          })}
              <div className="field-wrap__submit">
                <button className="btn btn_size_lg btn_width_100 product-info__nav-btn" type="button" onClick={this.handlerSubmitCategories.bind(this)}disabled={this.state.newCategoriesLoadingSubmit ? true : false}>{this.props.getTranslate('modalCategories-SaveButton')}{this.state.newCategoriesLoadingSubmit && <Loading />}</button>
              </div>
          </div>
        </div>
        </div> : ''}
    </>;
  }
}
export default (props) => <LibraryEdit {...useParams()} {...props} />
