import React from "react";
import sprite from '../media/icons.svg';
import { Link } from "react-router-dom";
import logo from '../mate_logo.svg';
import logo_nav from '../logo_dark.svg';
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownMenuStatus: false,
      dropdownMenuId: null,
      clickedInside: false,
      dropdownStatus: false,
      isEnglish: (typeof window !== "undefined" && typeof document !== 'undefined') ? window.location.pathname.startsWith('/en') : false
    }
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.addEventListener('mousedown', this.handleClickOutside);
    }
  }

  componentWillUnmount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }
  handlerMobileMenu (event) {
    this.setState({
      mobileMenuStart: false,
      mobileMenu: !this.state.mobileMenu
    });
  }
  handlerDropdown (event) {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerGetDropdown(id, event){
    this.setState({
      dropdownMenuStatus: event.target.classList.contains('header__main-menu-btn-dropdown') && this.state.dropdownMenuStatus && id !== this.state.dropdownMenuId ? true : !this.state.dropdownMenuStatus,
      dropdownMenuId: id
    });
  }
  handlerClickInside(event){
    this.setState({
      dropdownMenuStatus: false,
      dropdownMenuId: null,
      dropdownStatus: false
    });
  }

  handleClickOutside(event) {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({
          dropdownMenuStatus: false,
          dropdownMenuId: null,
          dropdownStatus: false
        });
      }
    }
  }

  setWrapperRef(node) {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      this.wrapperRef = node;
    }
  }
  render() {
    let location;
    let isEnglish;
    let targetPath;
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      location = window.location.pathname;
      isEnglish = location.startsWith('/en');

      if (isEnglish) {
        targetPath = location === '/en' ? '/' : location.substring('/en'.length);
      } else {
        targetPath = `/en${location}`;
      }
    }
    return <header className="header header__website">
      <div className="header__website-overlay"></div>
        <div className="header__inner">
          <div className="header__collapse">
            <button className="header__collapse-btn" type="button" onClick={this.handlerMobileMenu.bind(this)}>
              <svg>
                <use href={`${sprite}#menu-icon`}></use>
              </svg>
            </button>
          </div>
          <div className="header__menu-nav">
            <div className="header__logo">
              <Link to={this.props.getPermalink('/')}>
                <img src={logo} alt="matespace.io" />
              </Link>
            </div>
            <nav className="header__main-menu">
              <ul ref={this.setWrapperRef}>
                <li>
                  <button className="header__main-menu-btn-dropdown" type="button" onClick={this.handlerGetDropdown.bind(this, 'product')}>{this.props.getTranslate('menu-link-product-dropdown')}
                    <svg className="header__main-menu-btn-dropdown-icon">
                      <use href={`${sprite}#arrow-down`}></use>
                    </svg>
                  </button>
                  <ul className={`header__main-menu-dropdown ${this.state.dropdownMenuStatus && this.state.dropdownMenuId === 'product' ? 'active' : ''}`}>
                    <li>
                      <Link to={this.props.getPermalink('/about')}>{this.props.getTranslate('menu-link-product-app')}</Link>
                    </li>
                    <li>
                      <Link to={this.props.getPermalink('/about-panel')}>{this.props.getTranslate('menu-link-product-management')}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className={location === this.props.getPermalink('/price') ? 'active' : ''} to={this.props.getPermalink('/price')}>{this.props.getTranslate('menu-link-price')}</Link>
                </li>
                <li>
                  <Link className={location === this.props.getPermalink('/contacts') ? 'active' : ''} to={this.props.getPermalink('/contacts')}>{this.props.getTranslate('menu-link-contacts')}</Link>
                </li>
                <li>
                  <button className="header__main-menu-btn-dropdown" type="button" onClick={this.handlerGetDropdown.bind(this, 'notes')}>{this.props.getTranslate('menu-link-notes-dropdown')}
                    <svg className="header__main-menu-btn-dropdown-icon">
                      <use href={`${sprite}#arrow-down`}></use>
                    </svg>
                  </button>
                  <ul className={`header__main-menu-dropdown ${this.state.dropdownMenuStatus && this.state.dropdownMenuId === 'notes' ? 'active' : ''}`}>
                    <li>
                      <Link to={this.props.getPermalink('/blog')}>{this.props.getTranslate('menu-link-blog')}</Link>
                    </li>
                    <li>
                      <Link to={this.props.getPermalink('/glossary')}>{this.props.getTranslate('menu-link-glossary')}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
          <div className="header__nav">
            {this.props.logged ?
              <Link className="btn registration-btn" to={this.props.getPermalink('/account')}>
                  <img className="registration-btn__photo" src={this.props.userPhoto} />
                  <span className="registration-btn__name">{this.props.userName}</span>
              </Link> : <><Link className="btn login-btn" to={this.props.getPermalink('/login')}>
              <svg>
                <use href={`${sprite}#login`}></use>
                </svg>
                <span className="login-btn__name">{this.props.getTranslate('interface-SignIn')}</span>
            </Link>
            <Link className="btn registration-btn" to={this.props.getPermalink('/registration')}>
              <svg>
                <use href={`${sprite}#registration-company`}></use>
                </svg>
                <span className="registration-btn__name">{this.props.getTranslate('interface-RegisterCompany')}</span>
            </Link></>}
            {this.props.handlerUpdateLocal ?
            <Link className="header__language" to={targetPath} onClick={() => {this.props.handlerChangeLanguage(); this.props.handlerUpdateLocal(isEnglish ? 'uk-UA' : 'en-US');}}>{isEnglish ? 'EN' : 'UA'}</Link>
            : <Link className="header__language" to={targetPath} onClick={this.props.handlerChangeLanguage}>{isEnglish ? 'EN' : 'UA'}</Link>}
          </div>
      </div>
      {this.state.mobileMenu && <div className="account-nav__background" onClick={this.handlerMobileMenu.bind(this, false)}></div>}
      <div className={this.state.mobileMenu ? 'mobile-menu account-nav active' : !this.state.mobileMenuStart ? 'mobile-menu account-nav disabled' : 'mobile-menu account-nav'}>
      <div className="account-nav__inner">
      <div className="account-nav__header">
        {this.state.mobileMenu ? <button className="account-nav__close-btn" type="button" onClick={this.handlerMobileMenu.bind(this, !this.state.mobileMenu)}>
          <svg className="account-nav__close-icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button> : ''}
        <img className="account-nav__logo" src={logo_nav} alt="" />
        <div></div>
      </div>
      <div className="account-nav__content">
      <ul className="account-nav-list account-nav-list__menu account-nav-list__personal">
        <li className="account-nav-list__item">
          <div className="dropdown">
            <button className="account-nav-list__link" type="button" onClick={this.handlerGetDropdown.bind(this, 'product')}>
              <span className="account-nav-list__name">
              <svg className="account-nav-list__icon">
                <use href={`${sprite}#about-menu`}></use>
              </svg>{this.props.getTranslate('menu-link-product-dropdown')}</span>
            </button>
            <div className={`dropdown-content dropdown-content__main-menu ${this.state.dropdownMenuStatus && this.state.dropdownMenuId === 'product' ? 'active' : ''}`}>
              <ul className="dropdown-nav">
              <li>
                <Link className="dropdown-nav__link" to={this.props.getPermalink('/about')}>{this.props.getTranslate('menu-link-product-app')}</Link>
              </li>
              <li>
                <Link className="dropdown-nav__link" to={this.props.getPermalink('/about-panel')}>{this.props.getTranslate('menu-link-product-management')}</Link>
              </li>
              </ul>
            </div>
          </div>
        </li>
        <li className="account-nav-list__item">
          <Link className="account-nav-list__link" to={this.props.getPermalink('/price')}>
            <span className="account-nav-list__name">
            <svg className="account-nav-list__icon">
              <use href={`${sprite}#price-menu`}></use>
            </svg>{this.props.getTranslate('menu-link-price')}</span>
          </Link>
        </li>
        <li className="account-nav-list__item">
          <Link className="account-nav-list__link" to={this.props.getPermalink('/contacts')}>
            <span className="account-nav-list__name">
            <svg className="account-nav-list__icon">
              <use href={`${sprite}#contacts-menu`}></use>
            </svg>{this.props.getTranslate('menu-link-contacts')}</span>
          </Link>
        </li>
        <li className="account-nav-list__item">
          <div className="dropdown">
            <button className="account-nav-list__link" type="button" onClick={this.handlerGetDropdown.bind(this, 'notes')}>
              <span className="account-nav-list__name">
              <svg className="account-nav-list__icon">
                <use href={`${sprite}#about-menu`}></use>
              </svg>{this.props.getTranslate('menu-link-notes-dropdown')}</span>
            </button>
            <div className={`dropdown-content dropdown-content__main-menu ${this.state.dropdownMenuStatus && this.state.dropdownMenuId === 'notes' ? 'active' : ''}`}>
              <ul className="dropdown-nav">
                <li className="dropdown-nav__item">
                  <Link className="dropdown-nav__link" to={this.props.getPermalink('/blog')}>{this.props.getTranslate('menu-link-blog')}</Link>
                </li>
                <li className="dropdown-nav__item">
                  <Link className="dropdown-nav__link" to={this.props.getPermalink('/glossary')}>{this.props.getTranslate('menu-link-glossary')}</Link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        </ul>
        <ul className="account-nav-list account-nav-list__personal account-nav-list__no-login">
        {this.props.logged ? <li className="account-nav-list__item">
                    <Link className="account-nav-list__link" to={this.props.getPermalink('/login')}>
                      <span className="account-nav-list__name">
                      <img className="registration-btn__photo" src={this.props.userPhoto} />{this.props.userName}</span>
                    </Link>
                  </li> : <>
          <li className="account-nav-list__item">
            <Link className="account-nav-list__link" to={this.props.getPermalink('/login')}>
              <span className="account-nav-list__name">
              <svg className="account-nav-list__icon">
                <use href={`${sprite}#login`}></use>
              </svg>{this.props.getTranslate('interface-SignIn')}</span>
            </Link>
          </li>
          <li className="account-nav-list__item">
            <Link className="account-nav-list__link" to={this.props.getPermalink('/registration')}>
              <span className="account-nav-list__name">
              <svg className="account-nav-list__icon">
                <use href={`${sprite}#registration-company`}></use>
              </svg>{this.props.getTranslate('interface-RegisterCompany')}</span>
            </Link>
          </li>
        </>}
        </ul>
      </div>
      <div className="account-nav__footer">
        <div className="dropdown">
          <button className="dropdown__btn" onClick={this.handlerDropdown.bind(this)}>
            <div className="dropdown__btn-name">
              <svg className="account-nav-list__icon">
                <use href={`${sprite}#lang-icon`}></use>
              </svg>{this.props.getTranslate('interface-Lang')}: {isEnglish ? 'English' : 'Українська'}
            </div>
            <svg className="dropdown__icon">
              <use href={`${sprite}#arrow-down`}></use>
            </svg>
          </button>
          <div className={`dropdown-content ${this.state.dropdownStatus ? 'active' : ''}`}>
            <ul className="dropdown-nav">
              <li className="dropdown-nav__item">
                {!isEnglish ? <span className="dropdown-nav__link">Українська</span> : <Link className="dropdown-nav__link" to={`/`} onClick={this.props.handlerChangeLanguage.bind(this)}>Українська</Link>}
              </li>
              <li className="dropdown-nav__item">
                {isEnglish ? <span className="dropdown-nav__link">English</span> : <Link className="dropdown-nav__link" to={`/en${location}`} onClick={this.props.handlerChangeLanguage.bind(this)}>English</Link>}
              </li>
            </ul>
          </div>
        </div>
        </div>
      </div>
      </div>
    </header>
  }
}
export default Header;
