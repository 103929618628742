import React from "react";
import { useParams } from "react-router-dom";
import AccountHeader from './components/AccountHeader';
import Table from '../components/Table';
import DeleteModal from '../components/DeleteModal';
import Navigation from './components/Navigation';
import Pagination from './components/Pagination';
import Loading from '../components/Loading';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
class ControlPanelCompanyUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      isLoaded: false,
      users: [],
      errors: null,
      modalDeleteUser: false,
      removeIndex: null,
      idDeleteUser: null,
      loadingDeleteSumbit: false,
      currentPage: 0,
      totalPages: null
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modal: false,
        modalDeleteUser: false,
        removeIndex: null,
        idDeleteUser: null,
        loadingDeleteSumbit: false
      }));
    }, 500);
  }
  componentDidMount() {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/bo/v1/users/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(
          {
            "itemsPerPage": 20,
            "companyID": that.props.id
          }
        )
      }).then(res => {
        if(res.status === 200) {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              users: data.data,
              totalPages: data.totalPages,
              totalItems: data.totalItems
            });
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              errors: data.errors
            })
          });
        }
      })
    })
  }
  handlerDeleteUserModal(id, index, event){
    this.setState({
      modalDeleteUser: true,
      removeIndex: index,
      idDeleteUser: id
    });
  }
  handlerDeleteUser(id, event){
    let that = this;
    that.setState({
      loadingDeleteSumbit: true
    })
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/bo/v1/users/${id}`, {
        method: 'DELETE',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                loadingDeleteSumbit: false
              })
            });
        } else {
          that.handleModalDisabled();
          fetch(`${that.props.queryLink}/bo/v1/users/search`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({"itemsPerPage": 20, "companyID": that.props.id, "page": that.state.users.length < 2 ? (that.state.currentPage - 1) : that.state.currentPage})
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors,
                  error: true
                })
              });
            } else {
              res.json().then(function(data) {
                that.setState({
                  users: data.data,
                  totalPages: data.totalPages,
                  totalItems: data.totalItems,
                  loadingDeleteSumbit: false
                });
              })
            }
          })
        }
      })
    })
  }
  handlerUserStatus(id, event){

  }
  render() {

    let resultUsers = '';
    if(this.state.error) {
      resultUsers = <div>Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultUsers = <Loading />;
    } else {
      resultUsers = <div className="account-wgt account-wgt__users">

            {/* Table component */}
              <Table
                header={[
                  'ПІБ',
                  'E-mail',
                  'Права',
                  'Статус',
                  ''
                ]}
                userTable={true}
                boUsers={true}
                bodyData={this.state.users}
                bodyDataNames={['fullName', 'email', 'roles', 'userStatus']}
                controlItemsStatus={false}
                itemIDName={'userID'}
                itemEnableNameForStatus={'NOT_ACTIVE'}
                itemEnableNameForStatusData={'userStatus'}
                usersRoles={{
                    'ROLE_ADMINISTRATOR': <div className="team-table__rights-tooltip" style={{paddingRight: '3px'}}><svg className="team-table__rights-icon">
                                <use href={`${sprite}#access-admin-icon`}></use>
                              </svg><span>Адміністратор</span></div>,
                    'ROLE_EMPLOYEE': <div className="team-table__rights-tooltip" style={{paddingRight: '3px'}}><svg className="team-table__rights-icon">
                                <use href={`${sprite}#access-user-icon`}></use>
                              </svg><span>Користувач</span></div>
                  }}
                  controlItemsStatus={true}
                  controlItems={{
                    'sendCalculation': false,
                    'sendMessage': false,
                    'enabled': {'status': true, 'handler': this.handlerUserStatus.bind(this), 'label': {'hide': 'Зробити не активним', 'show': 'Зробити активним'}},
                    'editButton': false,
                    'editLink': false,
                    'delete': {
                      'status': true,
                      'handler': this.handlerDeleteUserModal.bind(this),
                      'label': 'Видалити'
                    }
                  }}
                  translationValueItems={{
                      'ACTIVE': 'Активний',
                      'HIDDEN': 'Не активний'
                    }}
                removeIndex={this.state.removeIndex}
              />

      </div>
    }

    return <>
      <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-wrap account-wrap__admin">
        <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
        <div className="account-content">
          <div className="account-content__inner">
            <div className="account-content__header">
              <div className="account-content__header-inner">
                <h1 className="account-content__headline">Оплаты</h1>
              </div>
              <a className="header__language header__language-content" href="#">RU</a>
            </div>
            <div className="account-content__content">

              {/* Query result */}
              {resultUsers}

            </div>

          </div>
        </div>
      </div>

      {/* Delete item modal */}
      <DeleteModal
        modalStatus={this.state.modalDeleteUser}
        modalDisabledStatus={this.state.modalDisabled}
        modalDisabledClass={this.state.addClass}
        handlerModalDisabled={this.handleModalDisabled}
        modalTitle="Видалити користувача"
        modalDescription="Користувача буде видалено безповоротньо, та відновити його буде неможливо."
        modalHandlerDelete={this.handlerDeleteUser.bind(this, this.state.idDeleteUser)}
        modalHandlerBtnTxt="Видалити"
        modalHandlerBtnLoading={this.state.loadingDeleteSumbit}
      />

    </>
  }
}
export default (props) => <ControlPanelCompanyUsers {...useParams()} {...props} />
