import React from "react";
import Loading from './components/Loading';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { Link, Navigate } from "react-router-dom";
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import AccountContentHeader from './components/AccountContentHeader';
import sprite from './media/icons.svg';
import img_preview from './media/item-preview-ads.png';
import img from './media/img-event-2.png';
import contactPhone from './media/contacts-phone.png';
import contactEmail from './media/contacts-email.png';
import contactTelegram from './media/contacts-telegram.png';
import { getAuth } from "firebase/auth";
let Editor;
let EditorState;
let stateToHTML;
let ContentState;
let convertFromHTML;
let convertToRaw;

if (typeof window !== 'undefined') {
  Editor = require('react-draft-wysiwyg').Editor;
  EditorState = require('draft-js').EditorState;
  convertToRaw = require('draft-js').convertToRaw;
  stateToHTML = require('draft-js-export-html').stateToHTML;
  ContentState = require('draft-js').ContentState;
  convertFromHTML = require('draft-js').convertFromHTML;

  require('../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
}
let arrayMoveImmutable;
if (typeof window !== 'undefined') {
  // Выполняем импорт только в клиентской среде
  const { arrayMoveImmutable: clientArrayMoveImmutable } = require('array-move');
  arrayMoveImmutable = clientArrayMoveImmutable;
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const customStylesError = {
  control: () => ({
    height: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const SortableItem = SortableElement(({item, value, handlerDelete}) => <div><button className="edit-product-img__remove" type="button" onClick={handlerDelete.bind(this, item)}><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><span className="product-num">{item + 1}</span>{item < 1 ? <span className="product-label">Обложка</span> : ''}<img className="sort-img" src={URL.createObjectURL(value)} alt="" /></div>);

const SortableList = SortableContainer(({items, handlerDelete}) => {
  return (
    <div className="edit-product-img">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} item={index} index={index} value={value} handlerDelete={handlerDelete} />
      ))}
    </div>
  );
});
class AdsCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      isLoadedSubmitAddAd: false,
      errorAddAd: [],
      cardList: [img],
      inputHeadline: '',
      textDescription: '',
      selectType: 'AD',
      inputPrice: '0',
      successSubmit: false,
      errorFields: false,
      contentState: '',
      formData: null,
      percentValue: 0,
      currencyValue: '',
      contacts: [
        {
          contact: '',
          type: ''
        }
      ],
      contactChangeValue: '',
      contactValue: '',
      statusValue: '',
      previewStatus: false,
      objForSubmit: {},
      modalDeleteEvents: false,
      sendNotifications:false,
      currencyItems: [],
      errorLengthDescription: false,
      heightInfo: null
    }
  }
  componentDidMount(){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    setTimeout(() => {
      this.setState({
        currencyItems: [
          { value: 'USD', label: 'USD' },
          { value:  'UAH', label: 'ГРН'},
          { value: 'EUR', label: ' EUR'},
          { value: this.props.currency, label: this.props.currency }
        ]
      })
    }, 100)
  }
  handlerDeleteEventModal(event){
    this.setState({
      modalDeleteEvents: true
    });
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        modalDeleteEvents: false
      }));
    }, 500);
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(prevState => ({
      cardList: arrayMoveImmutable(prevState.cardList, oldIndex, newIndex)
    }));
  }
  handlerInputHeadline (event) {
    if(this.state.errorOnlySpecialHeadline){
      this.setState({
        errorOnlySpecialHeadline: false
      });
    }
    this.setState({
      inputHeadline: event.target.value
    });
  }
  handlerTextDescription (event) {
    this.setState({
      textDescription: event.target.value
    });
  }
  handlerRepeatAd (event) {
    this.setState({
      selectType: event.value
    });
  }
  handlerInputPrice(event) {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, '');
    this.setState({
      inputPrice: inputValue
    });
  }
  handleChangeCurrency(event){
    if(event !== null){
      this.setState({
        currencyValue: event.value
      });
    } else {
      this.setState({
        currencyValue: ''
      });
    }
  }
  handleChangeContact(index, event){
    let contacts = this.state.contacts;
    let item = contacts[index];
    item.type = event.value;
    this.setState(prevState => ({
      contacts: contacts
    }));
  }
  handlerInputContact(index, event){
    let contacts = this.state.contacts;
    let item = contacts[index];
    item.contact = event.target.value;
    this.setState(prevState => ({
      contacts: contacts
    }));
  }
  handleStatus(event){
    this.setState({
      statusValue: event.value
    });
  }
  handlerAddContact(event){
    this.setState(prevState => ({
      contacts: [...prevState.contacts, {contact: '', type: ''}]
    }));
  }
  handleFileSelect() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      var file = this.state.formData;
      var f = file[0];
      var reader = new FileReader();
      reader.onload = (function(theFile) {
          return function(e) {
              var span = document.querySelector('.account-preview__wgt_col-logo');
              span.innerHTML = ['<img class="thumb" title="', escape(theFile.name), '" src="', e.target.result, '" />'].join('');
          };
      })(f);
      reader.readAsDataURL(f);
    }
  }
  handlerNextPreview(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.body.classList.add('preview');
    }
    let that = this;
    this.setState(prevState => ({
      errorFields: false,
      errorOnlySpecialHeadline: false,
      errorDescriptionOnlySpace: false,
      errorContacts: false
    }));
    const headlineWithoutSpaces = this.state.inputHeadline.trim();
    this.setState({
      inputHeadline: headlineWithoutSpaces
    });
    const isValidHeadline = /[a-zA-Z0-9а-яА-Я\s]/.test(headlineWithoutSpaces);
    if(!isValidHeadline){
      this.setState({
        errorOnlySpecialHeadline: true
      });
    }
    let currencyError = false;
    if ((this.state.inputPrice !== '0' && this.state.inputPrice !== 0 && this.state.inputPrice !== '') && this.state.currencyValue === '') {
      currencyError = true;
    }

    let priceError = false;
    if ((this.state.inputPrice === '0' || this.state.inputPrice === '' || this.state.inputPrice === 0) && this.state.currencyValue !== '') {
      priceError = true;
    }
    const contentState = this.state.contentState;
    let s;
    let blocks;
    let isOnlySpaces = true;
    if(contentState === ''){
      isOnlySpaces = true;
    } else {
      s = contentState.getCurrentContent();
      blocks = s.getBlockMap().toArray();
      isOnlySpaces = blocks.every(block => block.getText().trim() === '');
    }
    if(isOnlySpaces){
      this.setState({
        errorDescriptionOnlySpace: true
      });
    }
    let errorContacts = false;
    const updatedArray = this.state.contacts.map(obj => {
      let contact = obj.contact.trim();
      if (contact === "" || obj.type === "") {
        that.setState({
          errorContacts: true
        });
        errorContacts = true;
        return { ...obj, contact: contact, empty: true };
      }
      if(obj.type === 'EMAIL'){
        let emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        let isValidEmail = emailRegex.test(String(contact).toLowerCase());
        if(!isValidEmail){
          errorContacts = true;
          return { ...obj, errorValid: true };
        } else {
          if(obj.type === 'EMAIL' && obj.errorValid){
            delete obj.errorValid;
          }
        }
      }
      if (obj.type === 'PHONE') {
        let phoneInput = obj.contact.replace(/\D/g, '');
        if (!/^[0-9]+$/.test(phoneInput)) {
          errorContacts = true;
          return { ...obj, errorValid: true };
        }
        if (obj.errorValid) {
          delete obj.errorValid;
        }
      }
      if(obj.empty){
        delete obj.empty;
      }
      return obj;
    });
    if(errorContacts){
      this.setState({ contacts: updatedArray });
    }
    if(headlineWithoutSpaces && isValidHeadline && !isOnlySpaces && !currencyError && !priceError && !errorContacts && updatedArray.length > 0) {
      this.setState(prevState => ({
        previewStatus: !prevState.previewStatus
      }));
      let obj = {
            "adType": this.state.selectType,
            "currency": this.state.currencyValue,
            "description": (typeof window !== "undefined" && typeof document !== 'undefined') ? stateToHTML(this.state.contentState.getCurrentContent()) : '',
            "price": this.state.inputPrice,
            "title": headlineWithoutSpaces,
            "status": 'ACTIVE',
            "contacts": this.state.contacts,
            "sendNotifications": this.state.sendNotifications
          };
          this.setState({
            objForSubmit: obj
          });
          if(this.state.formData){
            this.handleFileSelect();
          }
    } else {
      that.setState({
        errorFields: true
      });
    }
  }
  handlerSubmitAd (event) {
    event.preventDefault();
    let that = this;
    if(this.state.errorFields){
      this.setState({
        errorFields: false
      });
    }
    let currencyError = false;
    if(this.state.inputPrice !== '0' && !this.state.currencyValue){
      currencyError = true
    }
    if(this.state.inputHeadline && Object.keys(this.state.contentState).length !== 0 && !currencyError) {
      this.setState({
        isLoadedSubmitAddAd: true
      });
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/ads`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(that.state.objForSubmit)
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.message
            })
          });
        } else {
          res.json().then(function(data) {
            if(!that.state.formData){
              if(typeof window !== "undefined" && typeof document !== 'undefined'){
                localStorage.setItem("successAds", JSON.stringify({status: true, text: that.props.getTranslate('successStatus-Save')}));
              }
              that.setState({
                isLoadedSubmitAddAd: false,
                successSubmit: true
              });
              setTimeout(() => {
                that.setState({
                  successSubmit: false
                });
              }, 5000);
            }
              if(that.state.formData){
                const myHeaders = new Headers({
                  'Authorization': 'Bearer ' + idToken
                });
                let formData = new FormData();
                let i = that.state.formData.length;
                let countPercent = 100 / i;
                for (let file of that.state.formData) {
                  formData.append('file', file);
                  fetch(`${that.props.queryLink}/api/v1/ads/${data.adID}/photos`, {
                    method: 'POST',
                    headers: myHeaders,
                    body: formData
                  }).then(res => {
                    if(res.status !== 200) {
                      that.setState({
                        errors: data.message
                      })
                    } else {
                      that.setState(prevState => ({
                        percentValue: (Math.ceil(prevState.percentValue + countPercent))
                      }));
                      i--;
                      if(i < 1){
                        if(typeof window !== "undefined" && typeof document !== 'undefined'){
                          localStorage.setItem("successAds", JSON.stringify({status: true, text: that.props.getTranslate('successStatus-Save')}));
                        }
                        that.setState({
                          isLoadedSubmitAddAd: false,
                          successSubmit: true
                        });
                        setTimeout(() => {
                          that.setState({
                            successSubmit: false
                          });
                        }, 5000);
                      }
                    }
                  })
                  formData.delete('file');
                }
                Promise.all(that.state.formData).then(function() {
                  console.log (i);
                });
              };
          });
        }
      })
      })
    } else {
      that.setState({
        errorFields: true
      });
    }
  }

  handleBeforeInput = (chars, editorState) => {
    const contentState = editorState.getCurrentContent();
    const text = contentState.getPlainText();

    if (text.length >= 500) {
      this.setState({
        errorLengthDescription: true
      });
      return 'handled';
    }
    return 'not-handled';
  };
  onEditorStateChange = (contentState) => {
    let rowLength = contentState.getCurrentContent().getBlockMap().toArray();
    if(rowLength.length > 14){
      let infoDiv = document.querySelector('.editor-class');
      let height = infoDiv.clientHeight;
      this.setState(prevState => ({
        heightInfo: height
      }));
    } else {
      this.setState(prevState => ({
        heightInfo: null
      }));
    }
    if(this.state.errorLengthDescription){
      this.setState({
        errorLengthDescription: false
      });
    }
    if(this.state.errorDescriptionOnlySpace){
      let s = contentState.getCurrentContent();
      const blocks = s.getBlockMap().toArray();
      const isOnlySpaces = blocks.every(block => block.getText().trim() === '');
      if (!isOnlySpaces) {
        this.setState({
          errorDescriptionOnlySpace: false
        });
      }
    }
    this.setState({
      contentState: contentState
    });
  };


    handlePastedText = (text, html, editorState) => {
      const contentState = editorState.getCurrentContent();
      const pastedText = ContentState.createFromText(text);

      const totalLength = contentState.getPlainText().length + pastedText.getPlainText().length;

      if (totalLength > 500) {
        const truncatedText = pastedText.getPlainText();
        const newContentState = ContentState.createFromText(truncatedText);

        const newEditorState = EditorState.push(editorState, newContentState);

        this.onEditorStateChange(newEditorState);
        this.setState({
          errorLengthDescription: true
        });
        return 'handled';
      }

      const newEditorState = EditorState.push(editorState, pastedText);

      this.onEditorStateChange(newEditorState);
      return 'not-handled';
    };

  handlerAddPhotos(event){
    let files = event.target.files;
    let formData = this.state.formData;
    if(formData){
      const dt = new DataTransfer();
      for (let file of formData) {
        dt.items.add(file)
      }
      for (let file of files) {
        dt.items.add(file)
      }
      this.setState(prevState => ({
        formData: Array.from(dt.files)
      }));
    } else {
      this.setState(prevState => ({
        formData: Array.from(files)
      }));
    }
  }
  handlerDeleteImg(index, event){
    let formData = this.state.formData;
    formData.splice(index, 1);
    this.setState(prevState => ({
      formData: formData
    }));
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerAdStatus(event){
    let obj = this.state.objForSubmit;
    let status = obj.status;
    if(status === 'HIDDEN'){
      obj.status = 'ACTIVE';
    } else {
      obj.status = 'HIDDEN';
      obj.sendNotifications = false;
    }
    this.setState(prevState => ({
      objForSubmit: obj
    }));
  }
  handlerNotificationAd(event){
    let obj = this.state.objForSubmit;
    if(obj.status === 'ACTIVE'){
      obj.sendNotifications = !obj.sendNotifications;
    }
    this.setState(prevState => ({
      objForSubmit: obj
    }));
  }
  handlerRemoveContact(index, event){
    const newArray = this.state.contacts;
    newArray.splice(index, 1);
    this.setState({ contacts: newArray });
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('marketplace-CreateTitle') + ' | ' + this.props.companyName;
    }
    const contactsItems = [
      { value: 'PHONE', label: this.props.getTranslate('marketplace-contactItemPhone')},
      { value: 'EMAIL', label: this.props.getTranslate('marketplace-contactItemEmail')},
      { value: 'TELEGRAM', label: 'Telegram'},
      { value: 'WATS_APP ', label: 'WatsApp'}
    ];
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to={`/account/marketplace`} replace={true} />) : ''}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      <div className="account-content">
      {this.state.previewStatus ? <div className="account-content__inner account-preview">

              {/* Account header component */}
              <AccountContentHeader
                handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
                languageActive={this.props.chooseLanguage}
                preview={true}
                backBtn={true}
                backBtnType="btn"
                backBtnHandler={this.handlerNextPreview.bind(this)}
                backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
              />

              <h2 className="account-preview__headline">{this.props.getTranslate('ads-previewTitle')}</h2>
              <div className="account-preview__inner account-preview__inner_preview">
                <div className="account-preview__info">
                  <div className="account-preview__wgt">
                    <div className="account-preview__wgt_row">
                      <div className="account-preview__wgt_col-info">
                        <div className="account-preview__wgt-headline">
                          <div className="account-preview__wgt-headline-text">{this.state.objForSubmit.title}</div>
                        </div>
                        <div className="account-preview__info-row">
                          <div className="account-preview__info-wgt account-preview__info-wgt-description">
                            <div className="account-preview__info-wgt-label">{this.props.getTranslate('marketplace-Description')}:</div>
                            <div className="account-preview__info-wgt-value" dangerouslySetInnerHTML={{__html: this.state.objForSubmit.description}}></div>
                          </div>
                          {this.state.objForSubmit.price ? <div className="account-preview__info-wgt">
                            <div className="account-preview__info-wgt-label">{this.props.getTranslate('marketplace-Price')}:</div>
                            <div className="account-preview__info-wgt-value">{this.state.objForSubmit.price + ' ' + this.state.objForSubmit.currency}</div>
                          </div> : ''}
                          <div className="account-preview__info-wgt">
                            <div className="account-preview__info-wgt-label">{this.props.getTranslate('marketplace-Contacts')}:</div>
                            <div className="account-preview__info-wgt-contacts">
                              <ul>
                                {this.state.objForSubmit.contacts.map((item, index) => {
                                  let icon = '';
                                  if(item.type === 'EMAIL'){
                                    icon = contactEmail;
                                  } else if(item.type === 'TELEGRAM'){
                                    icon = contactTelegram;
                                  } else if(item.type === 'PHONE'){
                                    icon = contactPhone;
                                  } else if(item.type === 'WATS_APP') {
                                    icon = contactPhone;
                                  }
                                  return <li key={index}>
                                    <img src={icon} alt="" />
                                    {item.contact}
                                  </li>
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="account-preview__wgt_col-logo"></div>
                    </div>
                    <div className="account-preview__info-nav">
                      <button className="button-standart button-standart_theme_blue" type="button" onClick={this.handlerNextPreview.bind(this)}>{this.props.getTranslate('marketplace-PreviewBtnEdit')}</button>
                      <button className="button-standart button-standart_theme_red" type="button" onClick={this.handlerDeleteEventModal.bind(this)}>{this.props.getTranslate('marketplace-PreviewBtnDelete')}</button>
                    </div>
                  </div>
                  <div className="account-preview__wgt">
                    <div className="account-preview__wgt-headline-text">{this.props.getTranslate('marketplace-previewAdditionalTitle')}</div>
                    <div className="field-wrap field-wrap__tooltip">
                      <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-0" type="checkbox" checked={this.state.objForSubmit.status === 'HIDDEN' ? true : false}  onChange={this.handlerAdStatus.bind(this)}  /><label htmlFor="styled-checkbox-0"></label></div><span>{this.props.getTranslate('marketplace-PreviewHideCheckbox')}</span></div>
                    </div>
                    <div className="field-wrap">
                      <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" checked={this.state.objForSubmit.sendNotifications} onChange={this.handlerNotificationAd.bind(this)} /><label className={`checkbox-label ${this.state.objForSubmit.status === 'HIDDEN' ? 'checkbox-label__disabled' : ''}`} htmlFor="styled-checkbox-1"></label></div><span>{this.props.getTranslate('marketplace-PreviewNotifyCheckbox')}</span></div>
                    </div>
                  </div>
                  <button className="preview-submit-btn" type="button" disabled={this.state.isLoadedSubmitAddAd ? true : false} onClick={this.handlerSubmitAd.bind(this)}>{this.props.getTranslate('ads-SubmitPublish')}{this.state.isLoadedSubmitAddAd && <Loading />}</button>
                </div>
                <div className="account-preview__img">
                  <div className="account-preview__img-description">{this.props.getTranslate('marketplace-PreviewExample')}</div>
                  <img src={img_preview} alt="" />
                </div>
              </div>
            </div> : <div className="account-content__inner">

                {/* Account header component */}
                <AccountContentHeader
                  handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
                  languageActive={this.props.chooseLanguage}
                  backBtn={true}
                  backBtnType="link"
                  backBtnClass="button-back_theme_gray"
                  backBtnHandler={this.props.getPermalink("/account/marketplace")}
                  backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
                />
                <div className="account-content__header-title">
                  <h1 className="account-content__headline">{this.props.getTranslate('marketplace-CreateTitle')}</h1>
                </div>
                <div className="board-form-wrap">
                  <div className="board-form" onSubmit={this.handlerSubmitAd.bind(this)}>
                    <div className="board-form__inner">
                      <div className="field-wrap">
                        <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">{this.props.getTranslate('marketplace-Caption')}</label>
                        <input id="headline" className={`field-wrap__input field-wrap__input_width_75 ${this.state.errorFields && (!this.state.inputHeadline || (this.state.errorOnlySpecialHeadline && this.state.inputHeadline !== '')) ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.inputHeadline} onChange={this.handlerInputHeadline.bind(this)} placeholder={this.props.getTranslate('marketplace-CaptionFiledCaption')} autocomplete="off" />
                        {this.state.errorFields && this.state.errorOnlySpecialHeadline && this.state.inputHeadline !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpecialField')}</div> : ''}
                        {this.state.errorFields && !this.state.inputHeadline ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                      </div>
                      <div className="field-wrap">
                        <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">{this.props.getTranslate('marketplace-Description')}</label>
                        {(typeof window !== "undefined" && typeof document !== 'undefined') ? <Editor
                          toolbar={{
                            options: ['inline', 'blockType', 'list'],
                            inline: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                              options: ['bold', 'italic', 'underline']
                            },
                            blockType: {
                              inDropdown: true,
                              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                            },
                            list: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                              options: ['unordered', 'ordered']
                            }
                          }}
                          wrapperClassName={`wrapper-class editor-field ${(this.state.errorFields && Object.keys(this.state.contentState).length === 0) || this.state.errorDescriptionOnlySpace || this.state.errorLengthDescription ? 'field-wrap__input_error' : ''}`}
                          editorClassName="editor-class"
                          editorStyle={{height: this.state.heightInfo ? (this.state.heightInfo + 'px') : 'auto'}}
                          toolbarClassName="toolbar-class editor-toolbar"
                          handleBeforeInput={this.handleBeforeInput}
                          handlePastedText={this.handlePastedText}
                          editorState={this.state.contentState}
                          onEditorStateChange={this.onEditorStateChange}
                        /> : ''}
                        {this.state.errorFields && (Object.keys(this.state.contentState).length === 0 || this.state.errorDescriptionOnlySpace) ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                        {this.state.errorLengthDescription ? <div className="validation-error">Кількість символів не повина перевищувати 500</div> : ''}
                      </div>
                      <div className="board-form__row">
                        <div className="field-wrap">
                          <div className="field-wrap__label-wrap">
                            <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="price">{this.props.getTranslate('marketplace-Price')}</label>
                            <div className="tooltip-info">
                              <span className="tooltip-info__icon">?</span>
                              <div className="tooltip-info__content">
                                <div className="arrow"></div>
                                <p>{this.props.getTranslate('tooltipAds-PriceAd')}</p>
                              </div>
                            </div>
                          </div>
                          <input id="price" className={`field-wrap__input ${this.state.errorFields && (this.state.inputPrice === '0' || this.state.inputPrice === '' || this.state.inputPrice === 0) && this.state.currencyValue ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.inputPrice} onChange={this.handlerInputPrice.bind(this)} placeholder={this.props.getTranslate('marketplace-PriceFieldCaption')} />
                          {this.state.errorFields && (this.state.inputPrice === '0' || this.state.inputPrice === '' || this.state.inputPrice === 0) && this.state.currencyValue ? <div className="validation-error">Введіть ціну</div> : ''}
                        </div>
                        <div className="field-wrap">
                          <div className="field-wrap__label-wrap">
                            <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('marketplace-Currency')}</label>
                            <div className="tooltip-info">
                              <span className="tooltip-info__icon">?</span>
                              <div className="tooltip-info__content">
                                <div className="arrow"></div>
                                <p>{this.props.getTranslate('tooltipAds-CurrencyAd')}</p>
                              </div>
                            </div>
                          </div>
                          <CreatableSelect
                            styles={this.state.errorFields && this.state.inputPrice !== '0' && !this.state.currencyValue ? customStylesError : customStyles}
                            options={this.state.currencyItems}
                            value={this.state.currencyItems ? this.state.currencyItems[this.state.currencyItems.findIndex(el => el.value === this.state.currencyValue)] : null}
                            isClearable
                            onChange={this.handleChangeCurrency.bind(this)}
                            formatCreateLabel={userInput => `Додати валюту: ${userInput}`}
                            placeholder={this.props.getTranslate('marketplace-CurrencyCaption')}
                          />
                          {this.state.errorFields && this.state.inputPrice !== '0' && !this.state.currencyValue ? <div className="validation-error">Оберіть валюту</div> : ''}
                        </div>
                      </div>
                      <div className="field-wrap">
                        <div className="field-wrap__label-wrap">
                          <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('marketplace-Contacts')}</label>
                          <div className="tooltip-info">
                            <span className="tooltip-info__icon">?</span>
                            <div className="tooltip-info__content">
                              <div className="arrow"></div>
                              <p>{this.props.getTranslate('tooltipAds-ContactsAd')}</p>
                            </div>
                          </div>
                        </div>
                        {this.state.contacts.map((item, index) => {
                          return <div className="board-form__row" key={index}>
                                  <div className="field-wrap">
                                    <Select
                                      styles={this.state.errorContacts && item.type === '' && item.empty ? customStylesError : customStyles}
                                      options={contactsItems}
                                      value={item.type ? contactsItems[contactsItems.findIndex(el => el.value === item.type)] : null}
                                      placeholder={this.props.getTranslate('marketplace-ContactsCaption')}
                                      isSearchable={false}
                                      onChange={this.handleChangeContact.bind(this, index)}
                                    />
                                    {this.state.errorContacts && item.type === '' && item.empty ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                                  </div>
                                  <div className="field-wrap field-wrap__remove">
                                    <div className="field-wrap__remove-inner">
                                      <input className={`field-wrap__input ${(this.state.errorContacts && ((item.contact === '' && item.empty)) || (item.contact !== '' && item.errorValid)) ? 'field-wrap__input_error' : ''}`} type="text" value={item.contact} onChange={this.handlerInputContact.bind(this, index)} placeholder={this.props.getTranslate('marketplace-ContactsItemCaption')} />
                                      {this.state.errorContacts && item.contact === '' && item.empty ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                                      {item.type === 'EMAIL' && item.errorValid && item.contact !== '' ? <div className="validation-error">{this.props.getTranslate('validation-IncorrectEmail')}</div> : ''}
                                      {item.type === 'PHONE' && item.errorValid && item.contact !== '' ? <div className="validation-error">Некорктний номер телефону</div> : ''}
                                    </div>
                                      {this.state.contacts.length > 1 ? <button className="ads-delete-contact" type="button" onClick={this.handlerRemoveContact.bind(this, index)}>
                                      <svg>
                                        <use href={`${sprite}#delete-red`}></use>
                                      </svg>
                                    </button> : ''}
                                  </div>
                                </div>
                        })}
                        <button className="account-wgt__edit-btn" type="button" onClick={this.handlerAddContact.bind(this)}>{this.props.getTranslate('marketplace-ContactsAddItem')}</button>
                      </div>
                      <div className="edit-user-form__imgs">
                        <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('marketplace-Photo')}</label>
                        <div className="edit-user-form__imgs-inner">
                          <div className="edit-user-upload">
                            <input type="file" id="upload" multiple onChange={this.handlerAddPhotos.bind(this)} />
                            <label htmlFor="upload">
                              <svg className="upload__icon">
                                <use href={`${sprite}#img-upload`}></use>
                              </svg>
                              {this.props.getTranslate('marketplace-AddPhoto')} {this.state.formData && <span style={{marginTop: '5px'}}>{this.props.getTranslate('loadingImg-AmountTitle')} {this.state.formData.length}</span>}</label>
                          </div>
                          {this.state.formData && this.state.isLoadedSubmitEditAd ? <div className="edit-user-upload">
                            <div className="edit-user-upload__percent">
                              <span>{this.props.getTranslate('loadingImg-PercentTitle')} {this.state.percentValue}%</span>
                              <div className="edit-user-upload__bar">
                                <div className="edit-user-upload__bar-inner" style={{width: this.state.percentValue + '%'}}></div>
                              </div>
                            </div>
                          </div> : ''}
                          {this.state.formData ? <SortableList items={this.state.formData} onSortEnd={this.onSortEnd} axis={'xy'} handlerDelete={this.handlerDeleteImg.bind(this) }/> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="field-wrap__submit">
                      <button className="btn btn_size_lg" type="button" onClick={this.handlerNextPreview.bind(this)}>{this.props.getTranslate('addMarketplace-buttonGoPreview')}</button>
                    </div>
                  </div>
                </div>
              </div>}
      </div>
    </div>
    {this.state.modalDeleteEvents ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('modal-deleteAdsTitle')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
        <div className="delete-category-form__description">{this.props.getTranslate('modal-deleteAdsDescription')}</div>
        <div className="field-wrap__submit">
          <Link className="btn btn_size_lg btn_theme_red btn_width_100 user-info__nav-btn" to={this.props.getPermalink('/account/marketplace')}>{this.props.getTranslate('removeModal-ButtonSubmit')}</Link>
        </div>
        </div>
    </div> : ''}
    </>;
  }
}
export default AdsCreate;
