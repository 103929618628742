import React from "react";
import Loading from './components/Loading';
import { Link } from "react-router-dom";
import Select from 'react-select';
import AccountHeader from './components/AccountHeader';
import Navigation from './components/Navigation';
import Pagination from './components/Pagination';
import ResultErrors from './components/ResultErrors';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class ControlPanelAccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      modalEdit: false,
      password: null,
      isLoaded: false,
      roles: [],
      rolesUser: [],
      users: [],
      user: null,
      userID: null,
      indexRowEvent: null,
      removeIndex: null,
      totalPages: null,
      totalItems: null,
      totalItemsGroup: null,
      newUserEmailValue: '',
      newUserNameValue: '',
      newUserLastNameValue: '',
      newUserPositionValue: '',
      isLoadedSubmit: false,
      errors: null,
      error: false,
      controlRow: null,
      editIndex: null,
      token: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("token-admin") ? JSON.parse(localStorage.getItem("token-admin")) : JSON.parse('')) : ''
    }
  }
  handlerPaginationItems = (companiesItems, current) => {
    this.setState({
      companiesData: companiesItems,
      currentPage: current
    });
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
      userID: null,
      editIndex: null
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modal: false,
        modalEdit: false
      }));
      if(this.state.rolesUser){
        this.setState(prevState => ({
          rolesUser: []
        }));
      }
    }, 500);
  }
  componentDidMount() {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/users/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({"itemsPerPage": 20})
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            users: data.data,
            totalPages: data.totalPages,
            totalItems: data.totalItems
          });
        })

        fetch(`${that.props.queryLink}/bo/v1/users/roles/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({})
        }).then(res => {
          if (res.status === 200) {
            res.json().then(function(data) {
              that.setState({
                totalItemsGroup: data.totalItems
              });
            })
          }
        })

      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            errors: [data.message],
            isLoaded: true,
            error: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            isLoaded: true,
            error: true
          });
        })
      }
    })
  })
  }
  handlerGetModalEditUser(id, index, event) {
    let that = this;
    this.setState({
      userID: id,
      editIndex: index
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/users/roles`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
        if(that.state.roles && that.state.roles.length < 1) {
          data.map((item, index) => {
            switch (item) {
              case 'ROLE_SUPER_ADMIN':
                that.setState(prevState => ({
                  roles: [...prevState.roles, {value: item, label: 'Супер администратор'} ]
                }));
                break;
              case 'ROLE_ADMINISTRATOR':
                that.setState(prevState => ({
                  roles: [...prevState.roles, {value: item, label: 'Администратор'} ]
                }));
                break;
              case 'ROLE_EMPLOYEE':
                that.setState(prevState => ({
                  roles: [...prevState.roles, {value: item, label: 'Пользователь'} ]
                }));
                break;
              case 'ROLE_ADMIN_BO':
                that.setState(prevState => ({
                  roles: [...prevState.roles, {value: item, label: 'ROLE_ADMIN_BO'} ]
                }));
                break;
              case 'ROLE_MANAGER_BO':
                that.setState(prevState => ({
                  roles: [...prevState.roles, {value: item, label: 'Менеджер'} ]
                }));
                break;
              case 'ROLE_SUPPORT_BO':
                that.setState(prevState => ({
                  roles: [...prevState.roles, {value: item, label: 'Супорт'} ]
                }));
                break;
              case 'ROLE_DEVELOPER_BO':
                that.setState(prevState => ({
                  roles: [...prevState.roles, {value: item, label: 'Разработчик'} ]
                }));
                break;
              default:
                that.setState(prevState => ({
                  roles: [...prevState.roles, {value: item, label: 'ROLE_ACCOUNTANT_BO'} ]
                }));
            }
          });
        }


        fetch(`${that.props.queryLink}/bo/v1/users/${id}`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => {
          if (res.status === 200) {
            that.setState({
              modalEdit: true
            });
            res.json().then(function(data) {
              data.roles.map((item, index) => {
                switch (item) {
                  case 'ROLE_SUPER_ADMIN':
                    that.setState(prevState => ({
                      rolesUser: [...prevState.rolesUser, {value: item, label: 'Супер администратор'} ]
                    }));
                    break;
                  case 'ROLE_ADMINISTRATOR':
                    that.setState(prevState => ({
                      rolesUser: [...prevState.rolesUser, {value: item, label: 'Администратор'} ]
                    }));
                    break;
                  case 'ROLE_EMPLOYEE':
                    that.setState(prevState => ({
                      rolesUser: [...prevState.rolesUser, {value: item, label: 'Пользователь'} ]
                    }));
                    break;
                  case 'ROLE_ADMIN_BO':
                    that.setState(prevState => ({
                      rolesUser: [...prevState.rolesUser, {value: item, label: 'ROLE_ADMIN_BO'} ]
                    }));
                    break;
                  case 'ROLE_MANAGER_BO':
                    that.setState(prevState => ({
                      rolesUser: [...prevState.rolesUser, {value: item, label: 'Менеджер'} ]
                    }));
                    break;
                  case 'ROLE_SUPPORT_BO':
                    that.setState(prevState => ({
                      rolesUser: [...prevState.rolesUser, {value: item, label: 'Супорт'} ]
                    }));
                    break;
                  case 'ROLE_DEVELOPER_BO':
                    this.setState(prevState => ({
                      that: [...prevState.rolesUser, {value: item, label: 'Разработчик'} ]
                    }));
                    break;
                  default:
                    that.setState(prevState => ({
                      rolesUser: [...prevState.rolesUser, {value: item, label: 'ROLE_ACCOUNTANT_BO'} ]
                    }));
                }
              });
              that.setState({
                user: data
              });
            })
          } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
          }
        })
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
      }
    })
  });
  }
  handlerGetModalAddUser(event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    let chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let passwordLength = 12;
    let password = "";
    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber +1);
    }
    that.setState(prevState => ({
      modal: !prevState.modal,
      password: password
    }));
    fetch(`${that.props.queryLink}/bo/v1/users/roles`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          if(that.state.roles && that.state.roles.length < 1) {
            data.map((item, index) => {
              switch (item) {
                case 'ROLE_SUPER_ADMIN':
                  that.setState(prevState => ({
                    roles: [...prevState.roles, {value: item, label: 'Супер администратор'} ]
                  }));
                  break;
                case 'ROLE_ADMINISTRATOR':
                  that.setState(prevState => ({
                    roles: [...prevState.roles, {value: item, label: 'Администратор'} ]
                  }));
                  break;
                case 'ROLE_EMPLOYEE':
                  that.setState(prevState => ({
                    roles: [...prevState.roles, {value: item, label: 'Пользователь'} ]
                  }));
                  break;
                case 'ROLE_ADMIN_BO':
                  that.setState(prevState => ({
                    roles: [...prevState.roles, {value: item, label: 'ROLE_ADMIN_BO'} ]
                  }));
                  break;
                case 'ROLE_MANAGER_BO':
                  that.setState(prevState => ({
                    roles: [...prevState.roles, {value: item, label: 'Менеджер'} ]
                  }));
                  break;
                case 'ROLE_SUPPORT_BO':
                  that.setState(prevState => ({
                    roles: [...prevState.roles, {value: item, label: 'Супорт'} ]
                  }));
                  break;
                case 'ROLE_DEVELOPER_BO':
                  that.setState(prevState => ({
                    roles: [...prevState.roles, {value: item, label: 'Разработчик'} ]
                  }));
                  break;
                default:
                  that.setState(prevState => ({
                    roles: [...prevState.roles, {value: item, label: 'ROLE_ACCOUNTANT_BO'} ]
                  }));
              }
            });
          }
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
      }
    })
  })
  }
  handlerEditEmail(event){
    this.setState(prevState => ({
      user: {...prevState.user, email: event.target.value}
    }));
  }
  handlerEditName(event){
    this.setState(prevState => ({
      user: {...prevState.user, name: event.target.value}
    }));
  }
  handlerEditLastName(event){
    this.setState(prevState => ({
      user: {...prevState.user, lastName: event.target.value}
    }));
  }
  handlerEditPosition(event){
    this.setState(prevState => ({
      user: {...prevState.user, position: event.target.value}
    }));
  }
  handlerEditRoles(event){
    this.setState(prevState => ({
      rolesUser: event
    }));
  }
  handlerSubmitEdit(event){
    event.preventDefault();
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    let obj = {
      "email": that.state.user.email,
      "lastName": that.state.user.lastName,
      "name": that.state.user.name,
      "position": that.state.user.position,
      "roles": that.state.rolesUser.map(a => a.value),
      "userID": that.state.userID
    };
    fetch(`${that.props.queryLink}/bo/v1/users/${that.state.userID}`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(obj)
    }).then(res => {
      if (res.status === 200) {
        fetch(`${that.props.queryLink}/bo/v1/users/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20})
        }).then(res => {
          if (res.status === 200) {
            res.json().then(function(data) {
              that.setState({
                indexRowEvent: null,
                removeIndex: null,
                users: data.data,
                totalPages: data.totalPages,
                totalItems: data.totalItems
              });
            })
            that.handleModalDisabled();
          } else if(res.status === 401) {
            res.json().then(function(data) {
              that.setState({
                errors: [data.message],
                isLoaded: true,
                error: true
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                isLoaded: true,
                error: true
              });
            })
          }
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
      }
    })
  })
  }
  handlerRemoveUser(id, index, event){
    let that = this;
    this.setState({
      indexRowEvent: index,
      removeIndex: index
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/users/${id}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if (res.status === 200) {
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + that.props.token()
        });
        fetch(`${that.props.queryLink}/bo/v1/users/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20})
        }).then(res => {
          if (res.status === 200) {
            res.json().then(function(data) {
              that.setState({
                indexRowEvent: null,
                removeIndex: null,
                users: data.data,
                totalPages: data.totalPages,
                totalItems: data.totalItems
              });
            })
          } else if(res.status === 401) {
            res.json().then(function(data) {
              that.setState({
                errors: [data.message],
                isLoaded: true,
                error: true
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                isLoaded: true,
                error: true
              });
            })
          }
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
      }
    })
  })
  }
  handlerNewUserEmail(event){
    this.setState(prevState => ({
      newUserEmailValue: event.target.value
    }));
  }
  handlerNewUserName(event){
    this.setState(prevState => ({
      newUserNameValue: event.target.value
    }));
  }
  handlerNewUserLastName(event){
    this.setState(prevState => ({
      newUserLastNameValue: event.target.value
    }));
  }
  handlerNewUserPosition(event){
    this.setState(prevState => ({
      newUserPositionValue: event.target.value
    }));
  }
  handlerNewUserRoles(event){
    this.setState(prevState => ({
      newUserRolesValue: event.map(a => a.value)
    }));
  }
  handlerNewUserSubmit(event){
    event.preventDefault();
    let that = this;
    this.setState(prevState => ({
      isLoadedSubmit: true
    }));
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    let obj = {
      "email": that.state.newUserEmailValue,
      "lastName": that.state.newUserLastNameValue,
      "name": that.state.newUserNameValue,
      "password": that.state.password,
      "position": that.state.newUserPositionValue,
      "roles": that.state.newUserRolesValue
    };
    fetch(`${that.props.queryLink}/bo/v1/users`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(obj)
    }).then(res => {
      if (res.status === 200) {
          that.handleModalDisabled();
          fetch(`${that.props.queryLink}/bo/v1/users/search`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({"itemsPerPage": 20})
          }).then(res => {
          if (res.status === 200) {
            res.json().then(function(data) {
              that.setState({
                users: data.data,
                totalPages: data.totalPages,
                totalItems: data.totalItems,
                isLoadedSubmit: false
              });
            })
          } else if(res.status === 401) {
            res.json().then(function(data) {
              that.setState({
                errors: [data.message],
                isLoaded: true,
                error: true
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                isLoaded: true,
                error: true
              });
            })
          }
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
      }
    })
  })
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerCloseElements(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const div = document.querySelector( '.control-wgt');
      document.addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(div);
        if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
          if ( ! withinBoundaries ) {
            this.setState( {
              controlRow: null
            } );
          }
        }
      });
    }
  }
  render() {
    let resultUsers = '';
    if(this.state.error) {
      resultUsers = <div>Помилка...</div>;
    } else if(!this.state.isLoaded) {
      resultUsers = <Loading />;
    } else {
      resultUsers = <>
      <table className="data-table">
        <thead>
          <tr>
            <th>Юзери</th>
            <th>Посада</th>
            <th>Доступи</th>
            <th></th>
          </tr>
        </thead>
      <tbody>
      {this.state.users.map((item, index) => {
        let classRow = '';
        if(this.state.removeIndex === index){
          classRow = ' row-disabled';
        } else {
          classRow = '';
        }
        return <tr className={`standart-table__row ${classRow}`} key={index}>
          <td>{item.fullName}</td>
          <td>{item.position}</td>
          <td>
            {item.roles.map((role, roleIndex) => {
              let roleName = '';
              switch (role) {
                case 'ROLE_SUPER_ADMIN':
                  roleName = 'Супер адміністратор';
                  break;
                case 'ROLE_ADMINISTRATOR':
                  roleName = 'Адміністратор';
                  break;
                case 'ROLE_EMPLOYEE':
                  roleName = 'Користувач';
                  break;
                case 'ROLE_ADMIN_BO':
                  roleName = 'ROLE_ADMIN_BO';
                  break;
                case 'ROLE_MANAGER_BO':
                  roleName = 'Менеджер';
                  break;
                case 'ROLE_SUPPORT_BO':
                  roleName = 'Супорт';
                  break;
                case 'ROLE_DEVELOPER_BO':
                  roleName = 'Розробник';
                  break;
                default:
                  roleName = 'ROLE_ACCOUNTANT_BO';
              }
              return <span className="access-label" key={roleIndex}>{roleName}</span>
            })}
          </td>
          <td>
          <div className="team-table__control">
            <div className="control-wgt">
              <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                <svg className="control-btn__icon">
                  <use href={`${sprite}#more-icon`}></use>
                </svg>
                </button>
                <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                  <ul className="control-content__list">
                    <li>
                      <button className="control-content__btn" type="button">
                        <svg className="control-content__icon">
                          <use href={`${sprite}#view-icon-stroke`}></use>
                        </svg>Блокувати</button>
                    </li>
                    <li>
                      <button className="control-content__btn" type="button">
                        <svg className="control-content__icon">
                          <use href={`${sprite}#access-edit-icon`}></use>
                        </svg>Доступи</button>
                    </li>
                    <li>
                      <button className="control-content__btn" type="button" onClick={this.handlerGetModalEditUser.bind(this, item.userID, index)}>
                        <svg className="control-content__icon">
                          <use href={`${sprite}#edit-icon`}></use>
                        </svg>Редагувати</button>
                    </li>
                    <li>
                      <button className="control-content__btn control-content__btn_delete" onClick={this.handlerRemoveUser.bind(this, item.userID, index)}>
                        <svg className="control-content__icon">
                          <use href={`${sprite}#delete-red`}></use>
                        </svg>Видалити</button>
                    </li>
                  </ul>
                </div>
                </div>
            </div>
          </td>
        </tr>
      })}
      </tbody></table>
      </>
    }
    return <>
      <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
      <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-wrap account-wrap__admin" onClick={this.handlerCloseElements.bind(this)}>
        <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
        <div className="account-content">
          <div className="account-content__inner">
            <div className="account-content__header">
              <div className="account-content__header-inner">
                <h1 className="account-content__headline">Доступи</h1>
              </div>
              <a className="header__language header__language-content" href="#">RU</a>
            </div>
            <div className="team-nav">
              <button className="btn team-nav__btn" type="button" onClick={this.handlerGetModalAddUser.bind(this)}>+ Додати співробітника</button>
            </div>
            <div className="users-tab">
              <div className="users-tab__inner">
                <Link className="users-tab__link active" to="/controlpanel/access">Користувачі<span className="users-tab__link-amount">{this.state.totalItems}</span></Link>
                <Link className="users-tab__link" to="/controlpanel/group">Група<span className="users-tab__link-amount">{this.state.totalItemsGroup}</span></Link>
              </div>
            </div>
            <div className="account-content__content">
              <div className="account-wgt">
                {resultUsers}
              </div>
            </div>
            <div className="account-content__footer">
              <Pagination total={this.state.totalPages} perPage="20" path={`${this.props.queryLink}/bo/v1/users/roles/search`} currentPage={this.state.currentPage} items={this.handlerPaginationItems} />
            </div>
          </div>
        </div>
      </div>
      {this.state.modal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">Додати співробітника</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <form onSubmit={this.handlerNewUserSubmit.bind(this)}>
            <div className="field-wrap">
              <input className="field-wrap__input" type="text" value={this.state.newUserEmailValue} onChange={this.handlerNewUserEmail.bind(this)} placeholder="E-mail" />
            </div>
            <div className="add-user-form__info">
              <div className="field-wrap">
               {this.state.roles ? <Select
                 className="field-wrap__select"
                 styles={customStyles}
                 options={this.state.roles}
                 isMulti={true}
                 placeholder='Выберите роль пользователя'
                 onChange={this.handlerNewUserRoles.bind(this)}
                 isSearchable={false}
               /> : ''}
               </div>
            </div>
            <div className="field-wrap">
              <input className="field-wrap__input" type="text" value={this.state.newUserNameValue} onChange={this.handlerNewUserName.bind(this)} placeholder="Имя" />
            </div>
            <div className="field-wrap">
              <input className="field-wrap__input" type="text" value={this.state.newUserLastNameValue} onChange={this.handlerNewUserLastName.bind(this)} placeholder="Фамилия" />
            </div>
            <div className="field-wrap">
              <input className="field-wrap__input" type="text" value={this.state.newUserPositionValue} onChange={this.handlerNewUserPosition.bind(this)} placeholder="Должность" />
            </div>
            <div className="add-user-form__info add-user-form__info_top">
              <div className="field-wrap">
                <label className="field-wrap__label" htmlFor="pass">Временный пароль</label>
                <input id="pass" className="field-wrap__input" type="text" value={this.state.password} placeholder="Пароль" />
              </div>
            </div>
            <div className="field-wrap__submit">
              <button className="btn btn_width_100 btn_size_lg" type="submit" disabled={this.state.isLoadedSubmit ? true : false}>Добавить{this.state.isLoadedSubmit && <Loading />}</button>
            </div>
          </form>
        </div>
      </div> : ''}
      {this.state.modalEdit ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">Редактировать пользователя</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          {this.state.user ? <form onSubmit={this.handlerSubmitEdit.bind(this)}>
            <div className="field-wrap">
              <input className="field-wrap__input" type="text" value={this.state.user.email ? this.state.user.email : ''} onChange={this.handlerEditEmail.bind(this)} placeholder="E-mail" />
            </div>
            <div className="add-user-form__info">
              <div className="field-wrap">
               {this.state.rolesUser && <Select
                 className="field-wrap__select"
                 styles={customStyles}
                 options={this.state.roles}
                 isMulti={true}
                 placeholder='Выберите роль пользователя'
                 value={this.state.rolesUser}
                 onChange={this.handlerEditRoles.bind(this)}
                 isSearchable={false}
               />}
               </div>
            </div>
            <div className="field-wrap">
              <input className="field-wrap__input" type="text" value={this.state.user.name ? this.state.user.name : ''} onChange={this.handlerEditName.bind(this)} placeholder="Имя" />
            </div>
            <div className="field-wrap">
              <input className="field-wrap__input" type="text" value={this.state.user.lastName ? this.state.user.lastName : ''} onChange={this.handlerEditLastName.bind(this)} placeholder="Фамилия" />
            </div>
            <div className="field-wrap">
              <input className="field-wrap__input" type="text" value={this.state.user.position ? this.state.user.position : ''} onChange={this.handlerEditPosition.bind(this)} placeholder="Должность" />
            </div>
            <div className="field-wrap__submit">
              <button className="btn btn_width_100 btn_size_lg" type="submit">Добавить</button>
            </div>
          </form> : ''}
        </div>
      </div> : ''}
    </>
  }
}
export default ControlPanelAccess;
