import React from "react";
import Loading from './components/Loading';
import { Link, Navigate } from "react-router-dom";
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import sprite from './media/icons.svg';
import img_preview from './media/item-preview-rewards.png';
import { getAuth } from "firebase/auth";
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class RewardsCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      previewStatus: false,
      inputHeadline: '',
      inputDescription: '',
      inputPrice: '',
      checkboxAuto: false,
      selectType: null,
      selectSubType: null,
      rewardTypes: [],
      objForSubmit: {},
      mainPhotoModal: false,
      modalEditTab: false,
      mainImgName: '',
      imageSrc: null,
      errorFormatImgs: false,
      errorSizeImgs: false,
      errorFormatImgs: false,
      errorSizeImgs: false,
      filesSubmit: null,
      formData: null,
      successSubmit: false,
      iconImgs: [],
      iconDefault: null,
      setImage: false,
      modalDeleteReward: false,
      errorNameOnlySpace: false,
      errorImageEmpty: false
    }
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
            fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/types`, {
              method: 'GET',
              headers: myHeaders
            }).then(res => {
              if(res.status !== 200) {

              } else {
                res.json().then(function(data) {
                  if(data && data.length > 0){
                    const transformedData = data.map(item => ({
                      label: item.name,
                      value: item.rewardType
                    }));
                    const transformedDataSub = data.subTypes.map(item => ({
                      label: item.name,
                      value: item.rewardSubType
                    }));
                    that.setState({
                      rewardTypes: transformedData,
                      rewardTypesSub: transformedDataSub
                    });
                  } else {
                    const transformed = [
                      {
                        "name": "Час роботи в компанії",
                        "rewardType": "COMPANY_EMPLOYEE_ANNIVERSARY",
                        "subTypes": [
                          {
                            "name": "1 рік",
                            "rewardSubType": "ONE_YEAR"
                          }
                        ]
                      }
                    ];
                    const transformedData = transformed.map(item => ({
                      label: item.name,
                      value: item.rewardType
                    }));
                    const transformedDataSub = transformed[0].subTypes.map(item => ({
                      label: item.name,
                      value: item.rewardSubType
                    }));
                    that.setState({
                      rewardTypes: transformedData,
                      rewardTypesSub: transformedDataSub
                    });
                  }
                })
              }
            })
      });
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        modalData: {},
        mainPhotoModal: false,
        modalEditTab: false,
        errorFormatImgs: false,
        errorSizeImgs: false,
        errorFormatImgs: false,
        errorSizeImgs: false,
        filesSubmit: null,
        previewImg: null,
        indexImg: null,
        imageSrc: null,
        formData: null,
        modalDeleteReward: false
      }));
    }, 500);
  }

  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  validItemEmpty = (item) => {
    let itemValue = this.state[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let onlySpace = /^\s*$/.test(itemValue);
      let itemCorrect;
      if(onlySpace){
        itemCorrect = itemValue;
      } else {
        itemCorrect = itemValue.trim();
      }
      this.setState({ [item]: itemCorrect });
      return itemCorrect;
    }

    return itemValue;
  }
  handlerNextPreview(event){
    document.body.classList.add('preview');
    let that = this;
    let error = false;
    this.setState(prevState => ({
      errorFields: false,
      errorNameOnlySpace: false,
      errorDescriptionOnlySpace: false,
      errorImageEmpty: false
    }));
    let nameValue = this.validItemEmpty('inputHeadline');
    if(/^\s*$/.test(nameValue) && nameValue !== ''){
      error = true;
      this.setState({errorNameOnlySpace: true });
    }
    let descriptionValue = this.validItemEmpty('inputDescription');
    if(/^\s*$/.test(descriptionValue) && descriptionValue !== ''){
      error = true;
      this.setState({errorDescriptionOnlySpace: true });
    }
    if(!this.state.imageSrc){
      error = true;
      this.setState({errorImageEmpty: true });
    }
    if(!error && nameValue){
    this.setState(prevState => ({
      previewStatus: !prevState.previewStatus
    }));
    let obj = {
      "auto": that.state.checkboxAuto,
      "bonus": that.state.inputBonuses,
      "description": descriptionValue,
      "name": nameValue
    };
    if(that.state.imageSrc){
      obj.photo = {
        url: that.state.imageSrc
      }
    }
    if(that.state.checkboxAuto){
      if(that.state.selectSubType.value){
        obj.rewardSubType = that.state.selectSubType.value;
      }
      if(that.state.selectType.value){
        obj.rewardType = that.state.selectType.value;
      }
    }
    this.setState({
      objForSubmit: obj
    });
    } else {
      this.setState({
        errorFields: true
      });
    }
  }

  handlerSubmit(event){
    event.preventDefault();
    let that = this;
    let error = false;
    if(this.state.errorFields){
      this.setState({
        errorFields: false
      });
    }
    this.setState({
      isLoadedSubmit: true
    });
    if(!error) {
      let objForSubmit = that.state.objForSubmit;
      if(that.state.formData){
        let formData = new FormData();
        for (let file of that.state.formData) {
          formData.append('file', file);
          getAuth().currentUser.getIdToken().then(function(idToken){
            const myHeaders = new Headers({
                'Authorization': 'Bearer ' + idToken
            });
            fetch(`${that.props.queryLink}/api/v1/encouragement/photos`, {
              method: 'POST',
              headers: myHeaders,
              body: formData
            }).then(res => {
                if (res.status !== 200) {
                  that.setState({
                    isLoadedSubmit: false
                  });
                  res.json().then(function(data) {
                    that.setState({
                      errors: data.errors
                    })
                  });
                } else {
                  res.json().then(function(data) {
                    objForSubmit.photo = data;
                    const myHeaders = new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + idToken
                    });
                    fetch(`${that.props.queryLink}/api/v1/encouragement/rewards`, {
                      method: 'POST',
                      headers: myHeaders,
                      body: JSON.stringify(objForSubmit)
                    }).then(res => {
                        if (res.status !== 200) {
                          that.setState({
                            isLoadedSubmit: false
                          });
                          res.json().then(function(data) {
                            that.setState({
                              errors: data.errors
                            })
                          });
                        } else {
                          if(typeof window !== "undefined" && typeof document !== 'undefined'){
                            localStorage.setItem("successReward", JSON.stringify({status: true, text: that.props.getTranslate('successStatus-Save')}));
                          }
                          that.setState({
                            isLoadedSubmit: false,
                            successSubmit: true
                          });
                        }
                      })
                  });
                }
              })
            })
        }
      } else {
        getAuth().currentUser.getIdToken().then(function(idToken){
          const myHeaders = new Headers({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + idToken
          });
          fetch(`${that.props.queryLink}/api/v1/encouragement/rewards`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(objForSubmit)
          }).then(res => {
              if (res.status !== 200) {
                that.setState({
                  isLoadedSubmit: false
                });
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors
                  })
                });
              } else {
                if(typeof window !== "undefined" && typeof document !== 'undefined'){
                  localStorage.setItem("successReward", JSON.stringify({status: true, text: that.props.getTranslate('successStatus-Save')}));
                }
                that.setState({
                  isLoadedSubmit: false,
                  successSubmit: true
                });
              }
            })
          })
      }

    } else {
      this.setState({
        errorFields: true,
        isLoadedSubmit: false
      });
    }
  }

  handlerHeadline(event){
    if(event.target.value.length <= 100){
      this.setState({
        inputHeadline: event.target.value,
        inputHeadlineLength: false
      });
    } else {
      this.setState({
        inputHeadlineLength: true
      });
    }
  }

  handlerDescription(event){
    if(event.target.value.length <= 300){
      this.setState({
        inputDescription: event.target.value,
        inputDescriptionLength: false
      });
    } else {
      this.setState({
        inputDescriptionLength: true
      });
    }
  }

  handlerBonuses(event){
    this.setState({
      inputBonuses: event.target.value
    });
  }

  handlerCheckboxAuto(event){
    this.setState(prevState => ({
      checkboxAuto: !prevState.checkboxAuto
    }));
  }

  handlerType(event){
    this.setState({
      selectType: event
    });
  }

  handlerSubType(event){
    this.setState({
      selectSubType: event
    });
  }

  handlerUploadImageModal(event){
    let that = this;
    that.setState(prevState => ({
      mainPhotoModal: !prevState.mainPhotoModal
    }));
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/photos`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              iconImgs: data
            });
          });
          that.setState(prevState => ({
            iconPhotoModal: !prevState.iconPhotoModal
          }));
        }
      })
    })

  }

  handlerModalEditTab(status, event){
    event.preventDefault();
    this.setState({
      modalEditTab: status
    })
  }

  handlerPreviewImg(event){
    let error = false;
    this.setState(prevState => ({
      errorFormatImgs: false,
      errorSizeImgs: false
    }));
    let files = event.target.files;
    if(files){
      for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileName = file.name.toLowerCase();
          const extension = fileName.split('.').pop();
          const fileSizeInMB = file.size / (1024 * 1024);
          if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png' && extension !== 'webp') {
              error = true;
              this.setState(prevState => ({
                errorFormatImgs: true
              }));
          }
          if (fileSizeInMB > 25) {
              error = true;
              this.setState(prevState => ({
                errorSizeImgs: true
              }));
          }
      }
    }
    if(!error){
      let nameFile = files[0].name;
      let name = nameFile.split('.')[0].substring(0, 10);
      let type = nameFile.split('.')[1];
      let fullName = name + '.' + type;
      this.setState({
        imageSrc: URL.createObjectURL(files[0]),
        previewImg: URL.createObjectURL(files[0]),
        mainImgName: fullName
      });
      let formData = this.state.formData;
      if(formData){
        const dt = new DataTransfer();
        for (let file of formData) {
          dt.items.add(file)
        }
        for (let file of files) {
          dt.items.add(file)
        }
        this.setState(prevState => ({
          formData: Array.from(dt.files)
        }));
      } else {
        this.setState(prevState => ({
          formData: Array.from(files)
        }));
      }

    }
  }

  handlerDeleteRewardsModal(event){
    this.setState({
      modalDeleteReward: true
    });
  }

  handlerDeletePreview(){
    this.setState({
      imageSrc: null,
      mainImgName: '',
      previewImg: null
    })
  }

  handlerSetDefaultImg(url, index, event){
    this.setState({
      previewImg: url,
      indexImg: index,
      imageSrc: url
    });
  }

  handlerSetImage(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
      setImage: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        mainPhotoModal: false
      }));
    }, 500);
  }
  handlerDeleteImg(event){
    this.setState(prevState => ({
      setImage: false,
      previewImg: null,
      indexImg: null,
      imageSrc: null,
      formData: null,
      mainImgName: ''
    }));
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = "Створення нагороди" + ' | ' + this.props.companyName;
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to={this.props.getPermalink("/account/rewards")} replace={true} />) : ''}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      <div className="account-content">
        {this.state.previewStatus ?
            <div className="account-content__inner account-preview">

              {/* Account header component */}
              <AccountContentHeader
                handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
                languageActive={this.props.chooseLanguage}
                preview={true}
                backBtn={true}
                backBtnType="btn"
                backBtnClass="button-back_theme_gray"
                backBtnHandler={this.handlerNextPreview.bind(this)}
                backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
              />

              <h2 className="account-preview__headline">Створення нагороди</h2>
              <div className="account-preview__inner account-preview__inner_preview">
                <div className="account-preview__info">
                  <div className="account-preview__wgt">
                    <div className="account-preview__wgt_row">
                      <div className="account-preview__wgt_col-info">
                        <div className="account-preview__wgt-headline">
                          <div className="account-preview__wgt-headline-text">{this.state.inputHeadline}</div>
                        </div>
                        <div className="account-preview__info-row">
                          <div className="account-preview__info-wgt">
                            <div className="account-preview__info-wgt-label">Опис:</div>
                            <div className="account-preview__info-wgt-value">{this.state.inputDescription}</div>
                          </div>
                          {this.state.inputBonuses ? <div className="account-preview__info-wgt">
                            <div className="account-preview__info-wgt-label">Бонус за нагороду:</div>
                            <div className="account-preview__info-wgt-value">{this.state.inputBonuses}{this.props.currency ? ' ' + this.props.currency : ''}</div>
                          </div> : ''}
                          {this.state.selectType ? <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                            <div className="account-preview__info-wgt-label">Умова виконання:</div>
                            <div className="account-preview__info-wgt-value">{this.state.selectType.label}</div>
                          </div> : ''}
                          {this.state.selectSubType ? <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                            <div className="account-preview__info-wgt-label">Значення:</div>
                            <div className="account-preview__info-wgt-value">{this.state.selectSubType.label}</div>
                          </div> : ''}
                        </div>
                      </div>
                      <div className="account-preview__wgt_col-logo">{this.state.previewImg ? <img src={this.state.previewImg} alt="" /> : ''}</div>
                    </div>
                    <div className="account-preview__info-nav">
                      <button className="button-standart button-standart_theme_blue" type="button" onClick={this.handlerNextPreview.bind(this, 'back')}>{this.props.getTranslate('event-EventPreviewBtnEdit')}</button>
                      <button className="button-standart button-standart_theme_red" type="button" onClick={this.handlerDeleteRewardsModal.bind(this)}>{this.props.getTranslate('event-EventPreviewBtnDelete')}</button>
                    </div>
                  </div>
                  <button className="preview-submit-btn" type="button" disabled={this.state.isLoadedSubmit ? true : false} onClick={this.handlerSubmit.bind(this)}>{this.props.getTranslate('events-SubmitPublish')}{this.state.isLoadedSubmit && <Loading />}</button>
                </div>
                <div className="account-preview__img">
                  <div className="account-preview__img-description">{this.props.getTranslate('bonuses-PreviewExample')}</div>
                  <img src={img_preview} alt="" />
                </div>
              </div>
            </div> : <div className="account-content__inner">

            {/* Account header component */}
            <AccountContentHeader
              handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
              languageActive={this.props.chooseLanguage}
              backBtn={true}
              backBtnType="link"
              backBtnClass="button-back_theme_gray"
              backBtnHandler={this.props.getPermalink("/account/rewards")}
              backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
            />

          <div className="account-content__header-title">
            <h1 className="account-content__headline">Створення нагороди</h1>
          </div>
          <div className="rewards-form-wrap">
            <div className="rewards-form">
              <div className="rewards-form__inner rewards-form__inner_border_top">
                <h3 className="form__headline">Інформація про нагороду</h3>
                <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">Назва нагороди</label>
                  <input id="headline" className={`field-wrap__input field-wrap__input_width_66 ${(this.state.errorFields && !this.state.inputHeadline) || (this.state.errorFields && this.state.errorNameOnlySpace && this.state.inputHeadline !== '') || this.state.inputHeadlineLength ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.inputHeadline} onChange={this.handlerHeadline.bind(this)} placeholder="Введіть назву нагороди" autocomplete="off" />
                  {this.state.errorFields && !this.state.inputHeadline ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  {this.state.errorFields && this.state.errorNameOnlySpace && this.state.inputHeadline !== '' ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                  {this.state.inputHeadlineLength ? <div className="validation-error">Максимум 100 символів</div> : ''}
                </div>
                <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">Опис</label>
                  <textarea id="description" className={`field-wrap__input field-wrap__input_textarea field-wrap__input_width_66 ${(this.state.errorFields && !this.state.inputDescription) || (this.state.errorFields && this.state.errorDescriptionOnlySpace && this.state.inputDescription !== '') || this.state.inputDescriptionLength ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.inputDescription} onChange={this.handlerDescription.bind(this)} placeholder="Введіть додатковий текст" />
                  {this.state.errorFields && !this.state.inputDescription ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  {this.state.errorFields && this.state.errorDescriptionOnlySpace && this.state.inputDescription !== '' ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                  {this.state.inputDescriptionLength ? <div className="validation-error">Максимум 300 символів</div> : ''}
                </div>
                <div className="edit-user-form__row">
                  <div className="edit-user-form__col edit-user-form__col_size_3">
                    <div className="field-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="price">Кількість бонусів за нагороду</label>
                      <div className="field-wrap__currency-name">
                        <input id="price" className={`field-wrap__input`} value={this.state.inputBonuses} onChange={this.handlerBonuses.bind(this)} placeholder="Введіть число" />
                        {this.props.currency && <label className="field-wrap__currency-name-item" htmlFor="price">{this.props.currency}</label>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rewards-form__inner">
                <div className="edit-user-form__row">
                  <div className="edit-user-form__col edit-user-form__col_size_3">
                    <div className="field-wrap field-wrap__tooltip field-wrap__tooltip-rewards">
                      <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox" type="checkbox" checked={this.state.checkboxAuto} onChange={this.handlerCheckboxAuto.bind(this)} /><label htmlFor="styled-checkbox"></label></div><span>Додати автоматичний сценарій</span></div>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('events-ConfirmEventTooltip')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.checkboxAuto ? <div className="edit-user-form__row">
                  <div className="edit-user-form__col edit-user-form__col_size_3">
                    <div className="field-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray">Умова виконання</label>
                      {this.state.rewardTypes ? <Select
                        styles={customStyles}
                        options={this.state.rewardTypes}
                        placeholder={this.props.getTranslate('events-ReccuringPlaceholder')}
                        onChange={this.handlerType.bind(this)}
                      /> : ''}
                    </div>
                  </div>
                  <div className="edit-user-form__col edit-user-form__col_size_3">
                    <div className="field-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray">Значення</label>
                      {this.state.rewardTypesSub ? <Select
                        styles={customStyles}
                        options={this.state.rewardTypesSub}
                        placeholder={this.props.getTranslate('events-ReccuringPlaceholder')}
                        onChange={this.handlerSubType.bind(this)}
                      /> : ''}
                    </div>
                  </div>
                </div> : ''}
              </div>
              <div className="rewards-form__inner rewards-form__inner_border_none">
                <h3 className="form__headline">Зображення (Іконка)</h3>
                <div className="edit-user-form__imgs">
                  <div className="edit-user-form__imgs-inner">
                    <div className={`edit-user-upload${this.state.errorImageEmpty && !this.state.setImage ? ' edit-user-upload-error' : ''}`}>
                      <label onClick={this.handlerUploadImageModal.bind(this)}>
                        <svg className="upload__icon">
                          <use href={`${sprite}#img-upload`}></use>
                        </svg>Вибрати або завантажити <span style={{marginTop: '5px'}}></span></label>
                    </div>
                    {this.state.formData && this.state.isLoadedSubmit ? <div className="edit-user-upload">
                      <div className="edit-user-upload__percent">
                        <span>{this.props.getTranslate('loadingImg-PercentTitle')} {this.state.percentValue}%</span>
                        <div className="edit-user-upload__bar">
                          <div className="edit-user-upload__bar-inner" style={{width: this.state.percentValue + '%'}}></div>
                        </div>
                      </div>
                    </div> : ''}
                    {this.state.setImage ?
                      <div className="edit-product-img">
                        <div><button className="edit-product-img__remove" type="button" onClick={this.handlerDeleteImg.bind(this)}><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><img src={this.state.previewImg} alt="" /></div>
                      </div>
                      : ''}
                  </div>
                  {this.state.errorImageEmpty && !this.state.setImage ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                </div>
              </div>
              <div className="rewards-form__inner rewards-form__inner_border_none rewards-form__inner_border_top">
                <div className="field-wrap__submit event-form__nav">
                  <button className="btn btn_size_lg" type="button" onClick={this.handlerNextPreview.bind(this)}>Продовжити та переглянути</button>
                </div>
              </div>
            </div>
          </div>

        </div>}
      </div>
    </div>


    {this.state.mainPhotoModal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal modal-reward-icons">
      <div className="modal-header">
        <h2 className="modal-headline">Додати зображення</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <div className="modal-image-edit">
        {this.state.previewImg ? <img className="modal-image-edit__preview" src={this.state.previewImg} alt="" /> : (this.state.iconImgs ? <img className="modal-image-edit__preview" src={this.state.iconImgs[0]} alt="" /> : <img className="modal-image-edit__preview" src={this.state.iconImgs[0]} alt="" />)}
        <div className="modal-image-edit__nav">
          <div className="modal-image-edit__nav-btn-wrap">
            <button className={`modal-image-edit__nav-btn ${this.state.modalEditTab ? '' : 'active'}`} type="button" onClick={this.handlerModalEditTab.bind(this, false)}>Вибрати</button>
          </div>
          <div className="modal-image-edit__nav-btn-wrap">
            <button className={`modal-image-edit__nav-btn ${this.state.modalEditTab ? 'active' : ''}`} type="button" onClick={this.handlerModalEditTab.bind(this, true)}>Завантажити</button>
          </div>
        </div>
        {this.state.modalEditTab ? <div className="modal-image-edit__content">
          <div className="modal-image-edit__upload">
          {this.state.mainImgName === '' ? <>
              <label htmlFor="upload-image-edit" className="modal-image-edit__upload-label">{this.props.getTranslate('companyinfo-modalCardTabLoadImg')}</label>
              <input id="upload-image-edit" type="file" onChange={this.handlerPreviewImg.bind(this)} />
          </> : <div className="upload-image-info">{this.state.mainImgName}
            <button className="upload-image-info__btn" type="button" onClick={this.handlerDeletePreview.bind(this)}>
              <svg className="upload-image-info__delete">
                <use href={`${sprite}#delete`}></use>
              </svg>
            </button>
          </div>}
          </div>
          <div className="modal-image-edit__nav register-form__submit form-nav">
              <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="submit" disabled={this.state.isLoadedSubmitImage ? true : false} onClick={this.handlerSetImage.bind(this)}>Зберегти{this.state.isLoadedSubmitImage && <Loading />}</button>
          </div>
        </div> : <div className="modal-image-edit__content">
        <div className="modal-image-edit__imgs">

        {this.state.iconImgs.map((item, index) => {
          return <div className={`modal-image-edit__img ${(index === 0 && !this.state.previewImg) || item === this.state.previewImg ? 'active' : ''}`} key={index} onClick={this.handlerSetDefaultImg.bind(this, item, index)}>
                  <img src={item.replace( /https:/g, "http:" )} alt="" />
                </div>
        })}

        </div>
        <div className="modal-image-edit__nav register-form__submit form-nav">
            <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="button" disabled={this.state.isLoadedSubmitImage ? true : false} onClick={this.handlerSetImage.bind(this)}>Зберегти{this.state.isLoadedSubmitImage && <Loading />}</button>
        </div>
        </div>}
      </div>
    </div>
    </div> : ''}

    {this.state.modalDeleteReward ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('modal-deleteEventTitle')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
        <div className="delete-category-form__description">{this.props.getTranslate('modal-deleteEventDescription')}</div>
        <div className="field-wrap__submit">
          <Link className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" to={this.props.getPermalink("/account/rewards")}>{this.props.getTranslate('removeModal-ButtonSubmit')}</Link>
        </div>
        </div>
    </div> : ''}


    </>;
  }
}
export default RewardsCreate;
