import React from "react";
import { Link, useParams, Navigate } from "react-router-dom";
import Loading from './components/Loading';
import Select from 'react-select';
import AccountHeader from './components/AccountHeader';
import Navigation from './components/Navigation';
import Pagination from './components/Pagination';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
let Editor;
let EditorState;
let convertToRaw;
let ContentState;
let convertFromHTML;
let draftToHtml;
let stateToHTML;
let htmlToDraft;

if (typeof window !== 'undefined') {
  Editor = require('react-draft-wysiwyg').Editor;
  EditorState = require('draft-js').EditorState;
  convertToRaw = require('draft-js').convertToRaw;
  ContentState = require('draft-js').ContentState;
  convertFromHTML = require('draft-js').convertFromHTML;
  draftToHtml = require('draftjs-to-html');
  stateToHTML = require('draft-js-export-html').stateToHTML;
  htmlToDraft = require('html-to-draftjs').default;

  require('../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const customStylesError = {
  control: () => ({
    height: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class ControlPanelBlogEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      successSubmit: false,
      isLoadedSubmit: false,
      formDataImgUA: null,
      formDataImgNetworkUA: null,
      formDataImgEN: null,
      formDataImgNetworkEN: null,
      nameValue: {
        'UA': '',
        'EN': ''
      },
      networkNameValue: {
        'UA': '',
        'EN': ''
      },
      networkDescriptionValue: {
        'UA': '',
        'EN': ''
      },
      categoryValue: '',
      contentState: {
        'UA': (typeof window !== "undefined" && typeof document !== 'undefined') ? EditorState.createEmpty() : '',
        'EN': (typeof window !== "undefined" && typeof document !== 'undefined') ? EditorState.createEmpty() : ''
      },
      imgsData: []
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modal: false
      }));
    }, 500);
  }
  handlerDeleteImg(type, event){
    if(type === 'mainUA'){
      this.setState({
        formDataImgUA: null
      });
    } else if(type === 'mainEN') {
      this.setState({
        formDataImgEN: null
      });
    } else if(type === 'networkUA') {
      this.setState({
        formDataImgNetworkUA: null
      });
    } else {
      this.setState({
        formDataImgNetworkEN: null
      });
    }
  }
  componentDidMount() {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/blogs/${that.props.id}`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          console.log(data);
          const contentBlockUA = (typeof window !== "undefined" && typeof document !== 'undefined') ? htmlToDraft(data.contentUA) : '';
          const contentStateUA = (typeof window !== "undefined" && typeof document !== 'undefined') ? ContentState.createFromBlockArray(contentBlockUA.contentBlocks) : '';
          const editorStateUA = (typeof window !== "undefined" && typeof document !== 'undefined') ? EditorState.createWithContent(contentStateUA) : '';
          const contentBlockEN = (typeof window !== "undefined" && typeof document !== 'undefined') ? htmlToDraft(data.contentEng) : '';
          const contentStateEN = (typeof window !== "undefined" && typeof document !== 'undefined') ? ContentState.createFromBlockArray(contentBlockEN.contentBlocks) : '';
          const editorStateEN = (typeof window !== "undefined" && typeof document !== 'undefined') ? EditorState.createWithContent(contentStateEN) : '';
          that.setState({
            "categoryValue": data.category,
            "contentState": {
              'UA': editorStateUA,
              'EN': editorStateEN
            },
            "hidden": true,
            "imgsData": [data.mainPhotoUA, data.mainPhotoEng, data.socialNetworkPhotoUA, data.socialNetworkPhotoEng],
            "nameValue": {
              "UA": data.nameUA,
              "EN": data.nameEng
            },
            "networkDescriptionValue": {
              "UA": data.socialNetworkDescriptionUA,
              "EN": data.socialNetworkDescriptionEng
            },
            "networkNameValue": {
              "UA": data.socialNetworkNameUA,
              "EN": data.socialNetworkNameEng
            },
          })
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            isLoaded: true,
            error: true
          });
        })
      }
    })

    fetch(`${that.props.queryLink}/bo/v1/configs/blogs/categories`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        });
      } else {
        res.json().then(function(data) {
          let resultData = data;
          let newObj = resultData.map(function(obj) {
            return {label: obj, value: obj}
          });
          that.setState( {categoriesResult: newObj} )
        });
      }
    })

  })
  }
  handlerNameValue(lang, event) {
    const updatedNameValue = { ...this.state.nameValue };
    updatedNameValue[lang] = event.target.value;
    this.setState({
      nameValue: updatedNameValue
    });
  }
  handlerSetGategory(event){
    this.setState({
      categoryValue: event
    });
  }
  onEditorStateChange(lang, contentState){
    const contentStateValue = { ...this.state.contentState };
    contentStateValue[lang] = contentState;
    this.setState({
      contentState: contentStateValue
    });
  };
  handlerNetworkName(lang, event) {
    const updatedNameValue = { ...this.state.networkNameValue };
    updatedNameValue[lang] = event.target.value;
    this.setState({
      networkNameValue: updatedNameValue
    });
  }
  handlerNetworkDescription(lang, event){
    const updatedNameValue = { ...this.state.networkDescriptionValue };
    updatedNameValue[lang] = event.target.value;
    this.setState({
      networkDescriptionValue: updatedNameValue
    });
  }
  handlerAddImg(lang, event){
    let files = event.target.files;
    if(lang === 'ua'){
      this.setState(prevState => ({
        formDataImgUA: Array.from(files)
      }));
    } else {
      this.setState(prevState => ({
        formDataImgEN: Array.from(files)
      }));
    }
  }
  handlerAddImgNetwork(lang, event){
    let files = event.target.files;
    if(lang === 'ua'){
      this.setState(prevState => ({
        formDataImgNetworkUA: Array.from(files)
      }));
    } else {
      this.setState(prevState => ({
        formDataImgNetworkEN: Array.from(files)
      }));
    }
  }
  handlerSubmit(event){
    event.preventDefault();
    this.setState({
      isLoadedSubmit: true
    });
    let that = this;
    const getAuthToken = () => getAuth().currentUser.getIdToken();
    const uploadFiles = async (formData) => {
      const idToken = await getAuthToken();
      const myHeaders = new Headers({
        'Authorization': 'Bearer ' + idToken
      });

      try {
        const res = await fetch(`${that.props.queryLink}/bo/v1/configs/photos`, {
          method: 'POST',
          headers: myHeaders,
          body: formData
        });

        if (res.status !== 200) {
          const data = await res.json();
          that.setState({
            errors: data.errors
          });
        } else {
          const data = await res.json();
          let imgsData = that.state.imgsData;
          imgsData.push(data);
          that.setState({
            imgsData: imgsData
          });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const uploadBlog = async () => {
      const idToken = await getAuthToken();
      const myHeaders = new Headers({
        'Authorization': 'Bearer ' + idToken,
        'Content-Type': 'application/json'
      });
      const editorStateEN = (typeof window !== "undefined" && typeof document !== 'undefined') ? that.state.contentState.EN : '';
      const editorStateUA = (typeof window !== "undefined" && typeof document !== 'undefined') ? that.state.contentState.UA : '';
      let objSubmit = {
        "blogID": that.props.id,
        "blogType": "BLOG",
        "category": that.state.categoryValue,
        "contentEng": (typeof window !== "undefined" && typeof document !== 'undefined') ? draftToHtml(convertToRaw(editorStateEN.getCurrentContent())) : '',
        "contentUA": (typeof window !== "undefined" && typeof document !== 'undefined') ? draftToHtml(convertToRaw(editorStateUA.getCurrentContent())) : '',
        "hidden": true,
        "mainPhotoUA": that.state.imgsData[0],
        "mainPhotoEng": that.state.imgsData[1],
        "nameEng": that.state.nameValue.EN,
        "nameUA": that.state.nameValue.UA,
        "socialNetworkDescriptionEng": that.state.networkDescriptionValue.EN,
        "socialNetworkDescriptionUA": that.state.networkDescriptionValue.UA,
        "socialNetworkNameEng": that.state.networkNameValue.EN,
        "socialNetworkNameUA": that.state.networkNameValue.UA,
        "socialNetworkPhotoEng": that.state.imgsData[3],
        "socialNetworkPhotoUA": that.state.imgsData[2]
      }
      try {
        const res = await fetch(`${that.props.queryLink}/bo/v1/configs/blogs/${that.props.id}`, {
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify(objSubmit)
              }).then(res => {
                if (res.status === 200) {
                  that.setState({
                    isLoadedSubmit: false,
                    successSubmit: true
                  });
                } else {
                  that.setState({
                    isLoadedSubmit: false
                  });
                }
              })
      } catch (error) {
        console.error('Error:', error);
      }
    };


        const uploadFirstSet = async () => {
          const formData = new FormData();
          if(that.state.formDataImgUA){
            for (let file of that.state.formDataImgUA) {
              formData.append('file', file);
            }
            await uploadFiles(formData);
          }
        };

        const uploadSecondSet = async () => {
          const formData = new FormData();
          if(that.state.formDataImgEN){
            for (let file of that.state.formDataImgEN) {
              formData.append('file', file);
            }
            await uploadFiles(formData);
          }
        };

        const uploadThirdSet = async () => {
          const formData = new FormData();
          if(that.state.formDataImgNetworkUA){
            for (let file of that.state.formDataImgNetworkUA) {
              formData.append('file', file);
            }
            await uploadFiles(formData);
          }
        };

        const uploadFourSet = async () => {
          const formData = new FormData();
          if(that.state.formDataImgNetworkEN){
            for (let file of that.state.formDataImgNetworkEN) {
              formData.append('file', file);
            }
            await uploadFiles(formData);
          }
        };

        const uploadFiveSet = async () => {
          await uploadBlog();
        };

        uploadFirstSet()
          .then(() => {
            return uploadSecondSet();
          }).then(() => {
            return uploadThirdSet();
          }).then(() => {
            return uploadFourSet();
          }).then(() => {
            return uploadFiveSet();
          })
          .catch((error) => {
            console.error('Error:', error);
          });

  }
  render() {
    return <>
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to="/controlpanel/blog" replace={true} />) : ''}
    <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
    <div className="account-wrap account-wrap__admin">
      <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-content">
        <div className="account-content__inner">
          <div className="account-content__header account-content__header-wrap">
            <div className="account-content__header-inner">
              <Link className="button-back button-back_theme_gray" type="button" to="/controlpanel/blog">
                <svg>
                  <use href={`${sprite}#arrow-back`}></use>
                </svg>Повернутися назад
              </Link>
            </div>
            <a className="header__language header__language-content" href="#">RU</a>
          </div>
          <div className="account-content__content">
            <div className="account-wgt account-wgt__blog-form">
              <h1 className="account-content__headline">Редагування статті</h1>
              <div className="field-wrap field-wrap__input_width_75">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">Назва (UA)</label>
                <input id="headline" className="field-wrap__input" type="text" placeholder="Введіть назву сторінки" value={this.state.nameValue.UA} onChange={this.handlerNameValue.bind(this, 'UA')} />
              </div>
              <div className="field-wrap field-wrap__input_width_75">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">Назва (EN)</label>
                <input id="headline" className="field-wrap__input" type="text" placeholder="Введіть назву сторінки" value={this.state.nameValue.EN} onChange={this.handlerNameValue.bind(this, 'EN')} />
              </div>
              <div className="field-wrap field-wrap__blog-category">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="category">Категорія</label>
              {this.state.categoriesResult ? <Select
                  styles={customStyles}
                  options={this.state.categoriesResult}
                  value={this.state.categoriesResult[this.state.categoriesResult.findIndex(el => el.value === this.state.categoryValue)]}
                  isSearchable={false}
                  placeholder="Ввиберіть категорію"
                /> : ''}
              </div>
              <div className="field-wrap">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="upload-logo">Зображення (сайт) UA – 750x390</label>
                <div className="edit-user-form__imgs-inner">
                <div className="edit-user-upload">
                  <input type="file" id="upload-logo-ua" onChange={this.handlerAddImg.bind(this, 'ua')} />
                  <label htmlFor="upload-logo-ua">
                    <svg className="upload__icon">
                      <use href={`${sprite}#img-upload`}></use>
                    </svg>Завантажити зображення</label>
                  </div>
                  {this.state.imgsData[0] && this.state.imgsData[0].url && !this.state.formDataImgUA ?
                      <div className="edit-blog-img"><div><button className="edit-product-img__remove" type="button"><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><img className="sort-img" src={this.state.imgsData[0].url} alt="" /></div></div> : ''}
                      {this.state.formDataImgUA ?
                        <div className="edit-blog-img"><div><button className="edit-product-img__remove" type="button" onClick={this.handlerDeleteImg.bind(this, 'mainUA')}><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><img className="sort-img" src={URL.createObjectURL(this.state.formDataImgUA[0])} alt="" /></div></div> : ''}
              </div>
                </div>
                <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="upload-logo">Зображення (сайт) EN – 750x390</label>
                  <div className="edit-user-form__imgs-inner">
                  <div className="edit-user-upload">
                    <input type="file" id="upload-logo-en" />
                    <label htmlFor="upload-logo-en">
                      <svg className="upload__icon">
                        <use href={`${sprite}#img-upload`}></use>
                      </svg>Завантажити зображення</label>
                    </div>
                    {this.state.imgsData[1] && this.state.imgsData[1].url && !this.state.formDataImgEN ?
                      <div className="edit-blog-img"><div><button className="edit-product-img__remove" type="button"><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><img className="sort-img" src={this.state.imgsData[1].url} alt="" /></div></div> : ''}

                </div>
                  </div>
            </div>
            <div className="account-wgt account-wgt__blog-form">
              <h2 className="account-content__headline">Контент</h2>
              <div className="field-wrap">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">Контент (UA)</label>
                {(typeof window !== "undefined" && typeof document !== 'undefined') ? <Editor
                  wrapperClassName="wrapper-class editor-field"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class editor-toolbar"
                  editorState={this.state.contentState.UA}
                  onEditorStateChange={this.onEditorStateChange.bind(this, 'UA')}
                  placeholder="Текст сторінки"
                /> : ''}
              </div>
              <div className="field-wrap">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">Контент (EN)</label>
                {(typeof window !== "undefined" && typeof document !== 'undefined') ? <Editor
                  wrapperClassName="wrapper-class editor-field"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class editor-toolbar"
                  editorState={this.state.contentState.EN}
                  onEditorStateChange={this.onEditorStateChange.bind(this, 'EN')}
                  placeholder="Текст сторінки"
                /> : ''}
              </div>
            </div>
            <div className="account-wgt account-wgt__blog-form">
              <h2 className="account-content__headline">Соціальні мережі</h2>
              <div className="field-wrap field-wrap__input_width_50">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="network-headline-ua">Назва UA</label>
                <input id="network-headline-ua" className="field-wrap__input" type="text" placeholder="Введіть назву" value={this.state.networkNameValue.UA} onChange={this.handlerNetworkName.bind(this, 'UA')} />
              </div>
              <div className="field-wrap field-wrap__input_width_50">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="network-headline-en">Назва EN</label>
                <input id="network-headline-en" className="field-wrap__input" type="text" placeholder="Введіть назву" value={this.state.networkNameValue.EN} onChange={this.handlerNetworkName.bind(this, 'EN')} />
              </div>
              <div className="field-wrap field-wrap__input_width_50">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="network-description-ua">Короткий опис UA</label>
                <input id="network-description-ua" className="field-wrap__input" type="text" placeholder="Введіть короткий опис" value={this.state.networkDescriptionValue.UA} onChange={this.handlerNetworkDescription.bind(this, 'UA')} />
              </div>
              <div className="field-wrap field-wrap__input_width_50">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="network-description-en">Короткий опис EN</label>
                <input id="network-description-en" className="field-wrap__input" type="text" placeholder="Введіть короткий опис" value={this.state.networkDescriptionValue.EN} onChange={this.handlerNetworkDescription.bind(this, 'EN')} />
              </div>
              <div className="field-wrap">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="upload-logo2">Зображення  для соціальної мережі UA – 1200x630</label>
                <div className="edit-user-form__imgs-inner">
                <div className="edit-user-upload">
                  <input type="file" id="upload-logo2-ua" />
                  <label htmlFor="upload-logo2-ua">
                    <svg className="upload__icon">
                      <use href={`${sprite}#img-upload`}></use>
                    </svg>Завантажити зображення</label>
                  </div>
                  {this.state.imgsData[2] && this.state.imgsData[2].url ?
                    <div className="edit-blog-img"><div><button className="edit-product-img__remove" type="button"><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><img className="sort-img" src={this.state.imgsData[2].url} alt="" /></div></div> : ''}
                  </div>
                </div>
                <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="upload-logo2">Зображення  для соціальної мережі EN – 1200x630</label>
                  <div className="edit-user-form__imgs-inner">
                  <div className="edit-user-upload">
                    <input type="file" id="upload-logo2-en" />
                    <label htmlFor="upload-logo2-en">
                      <svg className="upload__icon">
                        <use href={`${sprite}#img-upload`}></use>
                      </svg>Завантажити зображення</label>
                    </div>
                    {this.state.imgsData[3] && this.state.imgsData[3].url ?
                      <div className="edit-blog-img"><div><button className="edit-product-img__remove" type="button"><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><img className="sort-img" src={this.state.imgsData[3].url} alt="" /></div></div> : ''}

                    </div>
                  </div>
                <div className="field-wrap__submit">
                  <button className="btn btn_size_lg product-info__nav-btn" type="button" onClick={this.handlerSubmit.bind(this)} disabled={this.state.isLoadedSubmit ? true : false}>Редагувати{this.state.isLoadedSubmit && <Loading />}</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  }
}
export default (props) => <ControlPanelBlogEdit {...useParams()} {...props} />
