import React from "react";
import Loading from './components/Loading';
import { Link, Navigate } from "react-router-dom";
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import Header from './components/Header';
import Footer from './components/Footer';
import sprite from './media/icons.svg';
import ResultErrors from './components/ResultErrors';
const firebaseConfig = {
  apiKey: "AIzaSyCiErL0xg1EQ-FODI8KjJZbn0j73t6WcxU"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
function validateEmail(email) {
  var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(email).toLowerCase());
}
class Login extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        loading: false,
        login: false,
        loginEmail: '',
        loginPass: '',
        passwordHide: true,
        remember: false,
        errorFields: false,
        errorEmail: false,
        errorRoles: false,
        token: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("token") ? localStorage.getItem("token") : '') : '',
        errors: null,
        errorsCode: ''
      };
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      window.scrollTo(0, 0);
    }
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      this.setState({
        loginEmail: userData.loginEmail,
        loginPass: userData.loginPass,
        remember: true,
      });
    }
  }
  handlerLoginEmail (event) {
    this.setState({
      loginEmail: event.target.value
    });
  }
  handlerPasswordEmail (event) {
    this.setState({
      loginPass: event.target.value
    });
  }
  handlerPassword (event) {
    this.setState(prevState => ({
      passwordHide: !prevState.passwordHide
    }));
  };
  handlerRemember(event) {
    this.setState(prevState => ({
      remember: !prevState.remember
    }));
  }
  handlerSignIn (event) {
    event.preventDefault();
    let that = this;
    this.setState({
      errorFields: false,
      errorEmail: false,
      errorRoles: false,
      errorCode: '',
      errors: null
    });
    if(this.state.loginEmail && this.state.loginPass && validateEmail(this.state.loginEmail)) {
        this.setState({
          loading: true
        });
        const auth = getAuth();
        signInWithEmailAndPassword(auth, this.state.loginEmail, this.state.loginPass)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            localStorage.setItem("token", JSON.stringify(user.accessToken))
            const myHeaders = new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.accessToken
            });
            fetch(`${that.props.queryLink}/api/v1/companies/auth/after-login`, {
              method: 'GET',
              headers: myHeaders
            }).then(res => {
              if(res.status === 200){
                    that.setState({
                      login: true
                    });
                    if (this.state.remember) {
                    const userData = {
                        loginEmail: this.state.loginEmail,
                        loginPass: this.state.loginPass,
                      };
                      localStorage.setItem('userData', JSON.stringify(userData));
                      localStorage.setItem("token", JSON.stringify(user.accessToken))
                    } else {
                      localStorage.removeItem('userData');
                    }
              } else {
                    auth.signOut().then(() => {
                      that.setState({
                        loading: false
                      });
                    });
                    that.setState({
                      errorRoles: true
                    });
              }
            })

          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            this.setState({
              errorCode: errorCode,
              errors: [errorMessage],
              loading: false
            });
          });
    } else {
      this.setState({
        errorFields: true
      });
    }
    if(this.state.loginEmail && !validateEmail(this.state.loginEmail)) {
      this.setState({
        errorEmail: true
      });
    }
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null,
      errorRoles: false,
      errorCode: ''
    }));
  }
  render() {
    let title = this.props.getTranslate('loginPage-Title');
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = title ? title : 'MateSpace.io';
    }
    return <>
    {this.state.errorRoles ? <ResultErrors errors={['У вас немає прав доступу, авторизуйтесь через додаток']} closeHandler={this.handlerCloseError.bind(this)} /> : ''}
    {this.state.errorCode === 'auth/user-disabled' ? <ResultErrors errors={['Користувач не активний']} closeHandler={this.handlerCloseError.bind(this)} /> : ''}
    {this.props.PageWithCanonical}
    <Header getPermalink={this.props.getPermalink} logged={this.props.logged} userPhoto={this.props.userPhoto} userName={this.props.userName} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate} />
    {this.state.login || this.props.logged ? <Navigate to={this.props.getPermalink("/account")} replace={true} /> : ''}
    <main>
      <section className="login">
        <div className="wrapper">
          <div className="auth-form-wgt">
            <h2 className="auth-form-wgt__headline">{this.props.getTranslate('login-Title')}</h2>
            <form className="login-form auth-form" onSubmit={this.handlerSignIn.bind(this)}>
              <div className="field-wrap">
                <label className="field-wrap__label" htmlFor="email">{this.props.getTranslate('login-Email')}:</label>
                <input id="email" className={`field-wrap__input ${(this.state.errorFields && !this.state.loginEmail) || (this.state.loginEmail && this.state.errorEmail) ? 'field-wrap__input_error' : ''}`} type="text" placeholder={this.props.getTranslate('login-EmailFieldCaption')} value={this.state.loginEmail} onChange={this.handlerLoginEmail.bind(this)} />
                {this.state.errorFields && !this.state.loginEmail ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                {this.state.errorEmail && this.state.loginEmail ? <div className="validation-error">{this.props.getTranslate('validation-IncorrectEmail')}</div> : ''}
              </div>
              <div className="field-wrap">
                <label className="field-wrap__label" htmlFor="register-pass">{this.props.getTranslate('login-Password')}:</label>
                <div className="field-wrap__inner">
                  <input id="register-pass" className={`field-wrap__input ${this.state.errorFields && !this.state.loginPass ? 'field-wrap__input_error' : ''}`} type={this.state.passwordHide ? 'password' : 'text'} placeholder={this.props.getTranslate('login-PasswordFeildCaption')} value={this.state.loginPass} onChange={this.handlerPasswordEmail.bind(this)} />
                  <button className="field-wrap__hide-btn" type="button" onClick={this.handlerPassword.bind(this)}>
                    <svg className="field-wrap__hide-icon">
                      <use href={this.state.passwordHide ? `${sprite}#hide-pass` : `${sprite}#view-pass`}></use>
                    </svg>
                  </button>
                  {this.state.errors && (this.state.errorCode === 'auth/user-not-found' || this.state.errorCode === 'auth/wrong-password') ? <div className="validation-error">{this.props.getTranslate('error-loginUserNotFound')}</div> : ''}
                  {this.state.errorFields && !this.state.loginPass ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                </div>
              </div>
              <div className="login-form__nav">
                <div className="login-form__forgot-wrapper">
                  <Link className="login-form__forgot" to={this.props.getPermalink("/forgot")}>{this.props.getTranslate('login-ForgotPassword')}</Link>
                </div>
                <div className="login-form__remember">
                  <span className="login-form__remember-label">{this.props.getTranslate('login-RememberCheckBox')}</span>
                  <input className="styled-checkbox styled-checkbox__remember" id="styled-checkbox-0" type="checkbox" checked={this.state.remember} onChange={this.handlerRemember.bind(this)} />
                  <label htmlFor="styled-checkbox-0"></label>
                </div>
              </div>
              <div className="login-form__submit">
                <button className="btn btn_size_lg btn_width_100" type="submit" disabled={this.state.loading ? true : false}>{this.props.getTranslate('login-Button')}{this.state.loading && <Loading />}</button>
              </div>
            </form>
            <div className="register-form__login">
              <span className="register-form__login-label">{this.props.getTranslate('login-RegistrationText')}</span>
              <Link className="register-form__login-link" to={this.props.getPermalink("/registration")}>{this.props.getTranslate('login-RegistrationLink')}</Link>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer getPermalink={this.props.getPermalink} getTranslate={this.props.getTranslate} />
    </>;
  }
}
export default Login;
