import React from "react";
import { useParams } from "react-router-dom";
import Loading from './components/Loading';
import Header from './components/Header';
import Footer from './components/Footer';
import sprite from './media/icons.svg';
import forgot from './media/forgot-pass.svg';
function validatePassword(password) {
    let onlylatin = /[^A-Za-z0-9]/.test(password);
    return onlylatin;
}
class ForgotCode extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        loading: false,
        modalDisabled: false,
        passwordHide: true,
        repasswordHide: true,
        sumbitSuccess: false,
        passwordValue: '',
        passwordRepeatValue: '',
        submitLoading: false,
        errorFields: false,
        errorPasswordConfirm: false,
        errorPasswordLattin: false,
        errorPasswordLength: false
      };
    }
    handleModalDisabled = () => {
      document.querySelector('body').style.overflow = "";
      this.setState(prevState => ({
        modalDisabled: true
      }));
      setTimeout(() => {
        this.setState(prevState => ({
          modalSend: false,
          modalDisabled: false
        }));
      }, 500);
    }
    componentDidMount() {
      window.scrollTo(0, 0);
    }
  handlerPassword () {
    this.setState({ passwordHide: !this.state.passwordHide });
  };
  handlerRepassword () {
    this.setState({ repasswordHide: !this.state.repasswordHide});
  };
  handlerPasswordValue(event){
    this.setState({ passwordValue: event.target.value});
  }
  handlerPasswordRepeatValue(event){
    this.setState({ passwordRepeatValue: event.target.value});
  }
  handlerSubmit(event) {
  	event.preventDefault();
    let that = this;
              that.setState({
            errorFields: false,
                    errorPasswordConfirm: false,
        errorPasswordLattin: false,
        errorPasswordLength: false
          })
        if((this.state.passwordValue && this.state.passwordRepeatValue) && (this.state.passwordValue === this.state.passwordRepeatValue) && !validatePassword(this.state.passwordValue) && this.state.passwordValue.length > 5) {
          that.setState({
            submitLoading: true
          })
          const myHeaders = new Headers({
          'Content-Type': 'application/json'
      });
    fetch(`${that.props.queryLink}/api/v1/users/password/token`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(
        {
          "password": that.state.passwordValue,
          "passwordConfirmation": that.state.passwordRepeatValue,
          "token": that.props.codeForgot
        }
      )
    }).then(res => {
      if (res.status === 200) {
          that.setState({
            submitLoading: false,
            submitSuccess: true
          })
      } else {
          that.setState({
            submitLoading: false
          })
      }
    })
    } else {
          that.setState({
            errorFields: true
          })
    }
    if((this.state.passwordValue && this.state.passwordRepeatValue) && (this.state.passwordValue !== this.state.passwordRepeatValue)) {
      this.setState({
        errorPasswordConfirm: true
      });
    }
        if(this.state.passwordValue && validatePassword(this.state.passwordValue)){
      this.setState({
        errorPasswordLattin: true
      });
    }
        if(this.state.passwordValue && this.state.passwordValue.length < 6){
      this.setState({
        errorPasswordLength: true
      });
    }
  }
  render() {
    let title = this.props.getTranslate('forgotPage-Title');
    document.title = title ? title : 'MateSpace.io';
    return <>
    <Header getPermalink={this.props.getPermalink} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate} />
    <main>
      <section className="forgot">
        <div className="wrapper">
          {this.state.submitSuccess ? <div className="forgot-submit-success">
          	<div className="forgot-submit-success__inner">
	      		<img className="forgot-submit-success__icon" src={forgot} alt="" />
	      		<div className="forgot-submit-success__headline">{this.props.getTranslate('forgotSuccess-Headline')}</div>
	      		<div className="forgot-submit-success__description">{this.props.getTranslate('forgotSuccess-UnHeadline')}</div>
	      	</div>
          </div> : <div className="auth-form-wgt">
            <h2 className="auth-form-wgt__headline">{this.props.getTranslate('forgotNewPass-Headline')}</h2>
            <form className="auth-form" onSubmit={this.handlerSubmit.bind(this)}>
            <div className="field-wrap">
              <label className="field-wrap__label" htmlFor="pass">{this.props.getTranslate('forgotNewPass-labelFieldPass')}</label>
              <div className="field-wrap__inner">
              	<input id="pass" className={`field-wrap__input ${this.state.errorFields && !this.state.passwordValue || (this.state.errorPasswordConfirm && (this.state.passwordValue && this.state.passwordRepeatValue) || (this.state.errorPasswordLattin && this.state.passwordValue)) ? 'field-wrap__input_error' : ''}`} type={this.state.passwordHide ? 'password' : 'text'} value={this.state.passwordValue} onChange={this.handlerPasswordValue.bind(this)} placeholder={this.props.getTranslate('forgotNewPass-placeholderFieldPass')} />
              	<div className="field-wrap__hide-btn" onClick={this.handlerPassword.bind(this)}>
                    <svg className="field-wrap__hide-icon">
                      <use href={this.state.passwordHide ? `${sprite}#hide-pass` : `${sprite}#view-pass`}></use>
                    </svg>
                </div>
              </div>
              {this.state.errorFields && !this.state.passwordValue ? <div className="validation-error">Обязательное поле</div> : ''}
              {this.state.errorPasswordConfirm && (this.state.passwordValue && this.state.passwordRepeatValue) ? <div className="validation-error">Пароли не совпадают</div> : ''}
              {this.state.errorPasswordLattin && this.state.passwordValue ? <div className="validation-error">Пароль должен содержать только латинские буквы</div> : ''}
              {this.state.errorPasswordLength && this.state.passwordValue ? <div className="validation-error">Пароль должен содержать не меньше 6 символов</div> : ''}
            </div>
            <div className="field-wrap">
              <label className="field-wrap__label" htmlFor="re-pass">{this.props.getTranslate('forgotNewPass-labelFieldRePass')}</label>
              	<div className="field-wrap__inner">
              		<input id="re-pass" className={`field-wrap__input ${this.state.errorFields && !this.state.passwordRepeatValue || (this.state.errorPasswordConfirm && (this.state.passwordValue && this.state.passwordRepeatValue)  || (this.state.errorPasswordLattin && this.state.passwordValue)) ? 'field-wrap__input_error' : ''}`} type={this.state.repasswordHide ? 'password' : 'text'} value={this.state.passwordRepeatValue} onChange={this.handlerPasswordRepeatValue.bind(this)} placeholder={this.props.getTranslate('forgotNewPass-placeholderFieldRePass')} />
              	<div className="field-wrap__hide-btn" onClick={this.handlerRepassword.bind(this)}>
                    <svg className="field-wrap__hide-icon">
                      <use href={this.state.repasswordHide ? `${sprite}#hide-pass` : `${sprite}#view-pass`}></use>
                    </svg>
                </div>
              </div>
              {this.state.errorFields && !this.state.passwordRepeatValue ? <div className="validation-error">Обязательное поле</div> : ''}
              {this.state.errorPasswordConfirm && (this.state.passwordValue && this.state.passwordRepeatValue) ? <div className="validation-error">Пароли не совпадают</div> : ''}
            </div>
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg btn_width_100" type="submit" disabled={this.state.submitLoading ? true : false}>{this.props.getTranslate('forgotNewPass-SubmitButtonPass')}{this.state.submitLoading && <Loading />}</button>
            </div>
            </form>
          </div>}
        </div>
      </section>
    </main>
    <Footer getPermalink={this.props.getPermalink} getTranslate={this.props.getTranslate} />
    </>;
  }
}
export default ForgotCode;
