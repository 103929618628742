import React from "react";
import Button from './Button';
import sprite from '../media/icons.svg';
class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOpen: false,
      clickedInside: false
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClick() {
    this.setState((prevState) => ({
      sortOpen: !prevState.sortOpen,
      clickedInside: true,
    }));
  }

  handlerOpen(event) {
    this.setState(prevState => ({
      sortOpen: !prevState.sortOpen
    }))
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && !this.state.clickedInside) {
      this.setState({
        sortOpen: false,
      });
    }
    this.setState({ clickedInside: false });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setWrapperRefButton(node){
    this.wrapperRefButton = node;
  }

  render() {
    return  <div className={`control-wgt ${this.props.className ? this.props.className : ''}`} ref={this.setWrapperRef}>

                <Button
                  type="button"
                  btnTheme="secondary"
                  icon={true}
                  iconItem="#filter-icon"
                  iconClass="control-btn-orders__icon"
                  text={this.props.labelDisabled ? '' : this.props.buttonName}
                  handler={this.handlerOpen.bind(this)}
                />

                <div className={`control-content control-content_align_right ${this.state.sortOpen ? 'active' : ''}`}>
                  <ul className="control-content__list">
                    {this.props.dataSort.map((item, index) => {
                      return <li key={index} onClick={this.handleClick}>
                              <button className="control-content__btn" type="button" onClick={this.props.handler.bind(this, item.value)}>{item.name}</button>
                            </li>;
                    })}
                  </ul>
                </div>
              </div>
  }
}
export default Filter;
