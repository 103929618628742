import React from "react";
import Header from './components/Header';
import Footer from './components/Footer';
import ResultErrors from './components/ResultErrors';
import { useParams } from "react-router-dom";
import company_img from './media/matespace-icon.svg';
import success_img from './media/success-reg.png';
import apple_img from './media/apple-store.png';
import google_img from './media/google-play.png';
import app_img from './media/app-gallery.png';
import sprite from './media/icons.svg';
function validateEmail(email) {
  var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(email).toLowerCase());
}
function validatePassword(password) {
    let onlylatin = /[^A-Za-z0-9]/.test(password);
    return onlylatin;
}
class RegistrationUser extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        registerTab: false,
        logo: null,
        companyName: '',
        companyActivity: '',
        registerLogin: '',
        registerPass: '',
        registerRePass: '',
        registerName: '',
        registerSurName: '',
        selectedEmployees: null,
        selectedActivity: null,
        passwordHide: true,
        repasswordHide: true,
        success: false,
        errors: null,
        errorFields: false,
        errorRePass: false,
        errorEmail: false,
        errorPassword: false,
        errorPasswordLength: false,
        errorRePasswordLength: false
      };
      this.handlerRegisterTab = this.handlerRegisterTab.bind(this, false);
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      window.scrollTo(0, 0);
    }
    let that = this;
    const myHeaders = new Headers({
        'Content-Type': 'application/json'
    });
          fetch(`${that.props.queryLink}/api/v1/users/invites/${this.props.code}`, {
            method: 'GET'
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              });
            } else {
              res.json().then(function(data) {
                that.setState( {
                  logo: data.companyLogo ? data.companyLogo : company_img,
                  registerLogin: data.email,
                  registerName: data.userName,
                  registerSurName: data.userLastName
                } )
              })
            }
          })
  }
  handlerPassword () {
    this.setState({ passwordHide: !this.state.passwordHide });
  };
  handlerRepassword () {
    this.setState({ repasswordHide: !this.state.repasswordHide});
  };
  handleEmployees = (employees) => {
    this.setState({ selectedEmployees: employees });
  };
  handleActivity = (activity) => {
    this.setState({ selectedActivity: activity});
  };
  handlerRegisterTab (event, stateTab) {
    this.setState({
      registerTab: stateTab
    });
  }
  handlerCompanyName (event) {
    this.setState({
      companyName: event.target.value
    });
  }
  handlerContinueRegister (event) {
    this.setState({
      registerTab: true
    });
  }
  handlerRegisterLogin (event) {
    this.setState({
      registerLogin: event.target.value
    });
  }
  handlerRegisterPass (event) {
    this.setState({
      registerPass: event.target.value
    });
  }
  handlerRegisterRePass (event) {
    this.setState({
      registerRePass: event.target.value
    });
  }
  handlerRegisterName (event) {
    this.setState({
      registerName: event.target.value
    });
  }
  handlerRegisterSurName (event) {
    this.setState({
      registerSurName: event.target.value
    });
  }
  handlerSubmit (event) {
    event.preventDefault();
    let that= this;
    let error = false;
    this.setState({
      errorFields: false,
      errorRePass: false,
      errorEmail: false,
      errorPassword: false,
      errorPasswordLength: false
    });
    if(!this.state.registerLogin || !this.state.registerSurName || !this.state.registerName || !this.state.registerPass || !this.state.registerRePass) {
      this.setState({
        errorFields: true
      });
      error = true;
    }
    if(this.state.registerPass && this.state.registerRePass && (this.state.registerPass !== this.state.registerRePass)){
      this.setState({
        errorRePass: true
      });
      error = true;
    }
    if(this.state.registerLogin && !validateEmail(this.state.registerLogin)){
      this.setState({
        errorEmail: true
      });
      error = true;
    }
    if(this.state.registerPass && validatePassword(this.state.registerPass)){
      this.setState({
        errorPassword: true
      });
      error = true;
    }
    if(this.state.registerPass && this.state.registerPass.length < 6){
      this.setState({
        errorPasswordLength: true
      });
      error = true;
    }
    if(this.state.registerRePass && this.state.registerRePass.length < 6){
      this.setState({
        errorRePasswordLength: true
      });
      error = true;
    }
    if(!error) {
      const myHeaders = new Headers({
          'Content-Type': 'application/json'
      });
      let obj = {
        "code": this.props.code,
        "email": this.state.registerLogin,
        "lastName": this.state.registerSurName,
        "name": this.state.registerName,
        "password": this.state.registerPass,
        "passwordConfirmation": this.state.registerRePass
      };
      fetch(`${that.props.queryLink}/api/v1/users/registration`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(obj)
      }).then(res => {
          if (res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors
                  });
                });
          } else {
            that.setState({
              success: true
            });
          }
        })

    }
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  render() {
    let title = this.props.getTranslate('registerPage-Title');
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = title ? title : 'MateSpace.io';
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    <Header getPermalink={this.props.getPermalink} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate} />
    <main>
    <section className="registration">
      <div className="wrapper">
        {this.state.success ? <div className="success-wrapper">
        <div className="success-wrapper__inner">
          <img className="success-wrapper__img" src={success_img} alt="" />
          <h1 className="success-wrapper__headline">{this.props.getTranslate('registrationUser-SuccessTitle')}</h1>
          <p className="success-wrapper__description">{this.props.getTranslate('registrationUser-SuccessDescription')}</p>
          </div>
          <ul className="success-app-links">
            <li className="success-app-links__item">
              <a className="success-app-links__link" href="https://apps.apple.com/ua/app/matespace-io/id1609868472" target="_blank">
                <img className="success-app-links__logo" src={apple_img} alt="" />
              </a>
            </li>
            <li className="success-app-links__item">
              <a className="success-app-links__link" href="https://play.google.com/store/apps/details?id=com.matespace&hl=en_US" target="_blank">
                <img className="success-app-links__logo" src={google_img} alt="" />
              </a>
            </li>
          </ul>
        </div> : <div className="register-form-wgt auth-form-wgt">
          <div className="register-form-wgt__header">
            <h2 className="auth-form-wgt__headline">{this.props.getTranslate('registrationUser-FormTitle')}</h2>
            <img src={this.state.logo} alt="" />
          </div>
          <form className="auth-employee-form" onSubmit={this.handlerSubmit.bind(this)}>
            <div className="auth-employee-form__content">
              <div className="field-wrap">
                <label className="field-wrap__label" htmlFor="register-login">{this.props.getTranslate('registration-Email')}:</label>
                <input id="register-login" className={`field-wrap__input ${(this.state.errorFields && !this.state.registerLogin) || this.state.errorEmail ? 'field-wrap__input_error' : ''}`} type="text" placeholder={this.props.getTranslate('registration-EmailFieldCaption')} value={this.state.registerLogin} onChange={this.handlerRegisterLogin.bind(this)} />
                {this.state.errorFields && !this.state.registerLogin ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                {this.state.errorEmail ? <div className="validation-error">{this.props.getTranslate('validation-IncorrectEmail')}</div> : ''}
              </div>
              <div className="field-wrap">
                <label className="field-wrap__label" htmlFor="register-pass">{this.props.getTranslate('registration-Password')}:</label>
                <div className="field-wrap__inner">
                  <input id="register-pass" className={`field-wrap__input ${(this.state.errorFields && !this.state.registerPass) || this.state.errorRePass || this.state.errorPasswordLength || this.state.errorPassword ? 'field-wrap__input_error' : ''}`} type={this.state.passwordHide ? 'password' : 'text'} placeholder={this.props.getTranslate('registration-PasswordFieldCaption')} value={this.state.registerPass} onChange={this.handlerRegisterPass.bind(this)} />
                  <div className="field-wrap__hide-btn" onClick={this.handlerPassword.bind(this)}>
                    <svg className="field-wrap__hide-icon">
                      <use href={this.state.passwordHide ? `${sprite}#hide-pass` : `${sprite}#view-pass`}></use>
                    </svg>
                  </div>
                </div>
                {this.state.errorFields && !this.state.registerPass ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                {this.state.errorRePass ? <div className="validation-error">{this.props.getTranslate('validation-RePass')}</div> : ''}
                {this.state.errorPasswordLength ? <div className="validation-error">{this.props.getTranslate('validation-LengthPass')}</div> : ''}
                {this.state.errorPassword ? <div className="validation-error">{this.props.getTranslate('validation-LatinPass')}</div> : ''}
              </div>
              <div className="field-wrap">
                <label className="field-wrap__label" htmlFor="register-repass">{this.props.getTranslate('registration-RepeatPassword')}:</label>
                <div className="field-wrap__inner">
                  <input id="register-repass" className={`field-wrap__input ${(this.state.errorFields && !this.state.registerRePass) || this.state.errorRePass || this.state.errorRePasswordLength ? 'field-wrap__input_error' : ''}`} type={this.state.repasswordHide ? 'password' : 'text'} placeholder={this.props.getTranslate('registration-RepeatPasswordFieldCaption')} value={this.state.registerRePass} onChange={this.handlerRegisterRePass.bind(this)} />
                  <div className="field-wrap__hide-btn" onClick={this.handlerRepassword.bind(this)}>
                    <svg className="field-wrap__hide-icon">
                      <use href={this.state.repasswordHide ? `${sprite}#hide-pass` : `${sprite}#view-pass`}></use>
                    </svg>
                  </div>
                </div>
                {this.state.errorFields && !this.state.registerRePass ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                {this.state.errorRePass ? <div className="validation-error">{this.props.getTranslate('validation-RePass')}</div> : ''}
                {this.state.errorRePasswordLength ? <div className="validation-error">{this.props.getTranslate('validation-LengthPass')}</div> : ''}
              </div>
              <div className="register-form__sep"></div>
              <div className="field-wrap">
                <label className="field-wrap__label" htmlFor="register-name">{this.props.getTranslate('registration-Name')}:</label>
                <input id="register-name" className={`field-wrap__input ${this.state.errorFields && !this.state.registerName  ? 'field-wrap__input_error' : ''}`} type="text" placeholder={this.props.getTranslate('registration-NameFieldCaption')} value={this.state.registerName} onChange={this.handlerRegisterName.bind(this)} />
                {this.state.errorFields && !this.state.registerName ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
              </div>
              <div className="field-wrap">
                <label className="field-wrap__label" htmlFor="register-surname">{this.props.getTranslate('registration-Surname')}:</label>
                <input id="register-surname" className={`field-wrap__input ${this.state.errorFields && !this.state.registerSurName  ? 'field-wrap__input_error' : ''}`} type="text" placeholder={this.props.getTranslate('registration-SurnameFieldCaption')} value={this.state.registerSurName} onChange={this.handlerRegisterSurName.bind(this)} />
                {this.state.errorFields && !this.state.registerSurName ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
              </div>
              <div className="field-wrap__submit">
                <button className="btn btn_size_lg btn_width_100" type="submit">{this.props.getTranslate('registration-RegistrationButton')}</button>
              </div>
            </div>
          </form>
        </div>}
      </div>
    </section>
    </main>
    <Footer getPermalink={this.props.getPermalink} getTranslate={this.props.getTranslate} />
    </>;
  }
}
export default (props) => <RegistrationUser {...useParams()} {...props} />
