import React from "react";
import { Link } from "react-router-dom";
import InfiniteScrollingList from './components/AboutPages/ScrollingList';
import Button from './components/Button';
import DemoModal from './components/DemoModal';
import AboutMobileAppOpportunities from './components/AboutPages/AboutMobileAppOpportunities';
import AboutIncludeCompany from './components/AboutPages/AboutIncludeCompany';
import img_about_card from './media/img-about-card.png';
import Header from './components/Header';
import Footer from './components/Footer';
import sprite from './media/icons.svg';
import main_bg from './media/main-bg.png';
import phone from './media/phone.png';
import phone2 from './media/phone2.png';
import phone3 from './media/phone3.png';
import phones_bg from './media/phones-bg.png';
import phone_blur from './media/phone_blur.png';
import face1 from './media/about-face1.png';
import face2 from './media/about-face2.png';
import face3 from './media/about-face3.png';
import face4 from './media/about-face4.png';
import face5 from './media/about-face5.png';
import face6 from './media/about-face6.png';
import apple_store from './media/apple-store.png';
import google_play from './media/google-play.png';
import app_gallery from './media/app-gallery.png';
import avatar_1 from './media/avatars/avatar-1.png';
import avatar_2 from './media/avatars/avatar-2.png';
import avatar_3 from './media/avatars/avatar-3.png';
import avatar_4 from './media/avatars/avatar-4.png';
import avatar_6 from './media/avatars/avatar-6.png';
import avatar_7 from './media/avatars/avatar-7.png';
import avatar_8 from './media/avatars/avatar-8.png';
import avatar_9 from './media/avatars/avatar-9.png';
import avatar_10 from './media/avatars/avatar-10.png';
import avatar_11 from './media/avatars/avatar-11.png';
import avatar_12 from './media/avatars/avatar-12.png';
import avatar_13 from './media/avatars/avatar-13.png';
import avatar_14 from './media/avatars/avatar-14.png';
import avatar_15 from './media/avatars/avatar-15.png';
import avatar_16 from './media/avatars/avatar-16.png';
import avatar_17 from './media/avatars/avatar-17.png';
import avatar_18 from './media/avatars/avatar-18.png';
import avatar_19 from './media/avatars/avatar-19.png';
import avatar_20 from './media/avatars/avatar-20.png';
import avatar_21 from './media/avatars/avatar-21.png';
import avatar_22 from './media/avatars/avatar-22.png';
import avatar_23 from './media/avatars/avatar-23.png';
import avatar_24 from './media/avatars/avatar-24.png';
import avatar_25 from './media/avatars/avatar-25.png';
import avatar_26 from './media/avatars/avatar-26.png';
import avatar_27 from './media/avatars/avatar-27.png';
import avatar_28 from './media/avatars/avatar-28.png';
import avatar_29 from './media/avatars/avatar-29.png';
import avatar_30 from './media/avatars/avatar-30.png';
import avatar_31 from './media/avatars/avatar-31.png';
import avatar_32 from './media/avatars/avatar-32.png';
import avatar_33 from './media/avatars/avatar-33.png';
import avatar_34 from './media/avatars/avatar-34.png';
import avatar_35 from './media/avatars/avatar-35.png';
import avatar_36 from './media/avatars/avatar-36.png';
import icon_1 from './media/icon-1.png';
import icon_2 from './media/icon-2.png';
import icon_3 from './media/icon-3.png';
import icon_4 from './media/icon-4.png';
import mobile_1 from './media/mobile-1.png';
import mobile_2 from './media/mobile-2.png';
import mobile_3 from './media/mobile-3.png';
import mobile_4 from './media/mobile-4.png';
import mobile_5 from './media/mobile-5.png';
import mobile_6 from './media/mobile-6.png';
import abstraction_left from './media/abstraction_left.png';
import abstraction_right from './media/abstraction_right.png';
import manage_img1 from './media/manage-img1.png';
import manage_img2 from './media/manage-img2.png';
import manage_img3 from './media/manage-img3.png';
import manage_img4 from './media/manage-img4.png';
import notification from './media/notification.png';
import share from './media/share.png';
import app_index_slider_1 from './media/app-index-slider-1.png';
import app_index_slider_2 from './media/app-index-slider-2.png';
import app_index_slider_3 from './media/app-index-slider-3.png';
import app_index_slider_4 from './media/app-index-slider-4.png';
import app_index_slider_5 from './media/app-index-slider-5.png';
import app_index_slider_6 from './media/app-index-slider-6.png';
import app_index_slider_7 from './media/app-index-slider-7.png';
import admin_1 from './media/admin-1.png';
import admin_2 from './media/admin-2.png';
import admin_3 from './media/admin-3.png';
import admin_4 from './media/admin-4.png';
import admin_5 from './media/admin-5.png';
import bonus_1 from './media/bonus-slider-1.png';
import bonus_2 from './media/bonus-slider-2.png';
import bonus_3 from './media/bonus-slider-3.png';
import bonus_4 from './media/bonus-slider-5.png';
import bonus_5 from './media/bonus-slider-6.png';
import bonus_6 from './media/bonus-slider-7.png';
import dashboard_include from './media/mobile-app-contacts.png';
let Swiper;
if (typeof window !== 'undefined') {
  Swiper = require('swiper').Swiper;
  require('swiper/css');
  require('swiper/css/navigation');
  require('swiper/css/pagination');
  require('swiper/css/scrollbar');
}
class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderDashboardManagerData: [
        {
          status: true,
          img: admin_1
        },
        {
          status: false,
          img: admin_2
        },
        {
          status: false,
          img: admin_3
        },
        {
          status: false,
          img: admin_4
        },
        {
          status: false,
          img: admin_5
        }
      ],
      sliderBonusesData: [
        {
          status: true,
          img: bonus_1
        },
        {
          status: false,
          img: bonus_2
        },
        {
          status: false,
          img: bonus_3
        },
        {
          status: false,
          img: bonus_4
        },
        {
          status: false,
          img: bonus_5
        },
        {
          status: false,
          img: bonus_6
        }
      ],
      dashboardManagerImg: 0,
      bonusesImg: 0,
      activeSlide: 0,
      activeSlideBonuses: 0,
      activeSlideBonusesMob: 0,
      dashboardManagerImgMob: 0
    }
  }
  handleSlideTo = (index) => {
    if (this.swiper) {
      this.swiper.slideTo(index);
      this.setState({
        dashboardManagerImgMob: index
      })
    }
  };
  handleSlideTo2 = (index) => {
    if (this.swiper2) {
      this.swiper2.slideTo(index);
      this.setState({
        activeSlideBonusesMob: index
      })
    }
  };
  handlerGetImgDashboard(index, event){
    this.setState({
      dashboardManagerImg: index
    })
  }
  handlerGetImgBonuses(index, event){
    this.setState({
      bonusesImg: index
    })
  }
  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
  // Initialize Swiper after mounting the component
  this.swiper = new Swiper(".swiper-dashboard-wgts", {
    // Configuration options here
    direction: "vertical",
    slidesPerView: 1,
    spaceBetween: 30,
    on: {
      slideChange: () => {
        this.setState({ activeSlide: this.swiper.activeIndex });
      },
    },
  });
  setInterval(() => {
    if(this.state.activeSlide === (this.state.sliderDashboardManagerData.length - 1)){
      this.swiper.slideTo(0);
      this.setState({ activeSlide: 0});
    } else {
      this.setState({ activeSlide: (this.swiper.activeIndex + 1) });
      this.swiper.slideTo(this.swiper.activeIndex + 1);
    }
  }, 2500);
  // Initialize Swiper after mounting the component
  this.swiper2 = new Swiper(".swiper-bonuses-wgts", {
    // Configuration options here
    direction: "vertical",
    slidesPerView: 1,
    spaceBetween: 30,
    on: {
      slideChange: () => {
        this.setState({ activeSlideBonuses: this.swiper2.activeIndex});
      },
    },
  });
  setInterval(() => {
    if(this.state.activeSlideBonuses === (this.state.sliderBonusesData.length - 1)){
      this.swiper2.slideTo(0);
      this.setState({ activeSlideBonuses: 0});
    } else {
      this.setState({ activeSlideBonuses: (this.swiper2.activeIndex + 1) });
      this.swiper2.slideTo(this.swiper2.activeIndex + 1);
    }
  }, 2500);
}
}
  render() {
    let title;
    if (typeof window !== 'undefined') {
      title = this.props.getTranslate('aboutAppPage-Title');
      document.title = title ? title : 'MateSpace.io';
      const metaDescription = document.querySelector('meta[name="description"]');
      let description = this.props.getTranslate('aboutAppPage-Description');
      if (metaDescription) {
        metaDescription.setAttribute('content', description ? description : 'MateSpace.io');
      }
    }
    let tagsBonuses = [
      this.props.getTranslate('about-AboutBonusesHeadlineFirst'),
      this.props.getTranslate('about-AboutBonusesHeadlineSecond'),
      this.props.getTranslate('about-AboutBonusesHeadlineThird'),
      this.props.getTranslate('about-AboutBonusesHeadlineFour'),
      this.props.getTranslate('about-AboutBonusesHeadlineFive'),
      this.props.getTranslate('about-AboutBonusesHeadlineSix')
    ];
    let tagsDashboard = [
      this.props.getTranslate('about-AboutDashboardHeadlineFirst'),
      this.props.getTranslate('about-AboutDashboardHeadlineSecond'),
      this.props.getTranslate('about-AboutDashboardHeadlineThird'),
      this.props.getTranslate('about-AboutDashboardHeadlineFour'),
      this.props.getTranslate('about-AboutDashboardHeadlineFive')
    ];
    return <>
    {this.props.PageWithCanonical}
    <Header getPermalink={this.props.getPermalink} logged={this.props.logged} userPhoto={this.props.userPhoto} userName={this.props.userName} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate} />
    <main className="about-service">
      <section className="about-page" style={{ backgroundImage: `url(${main_bg})` }}>
        <div className="wrapper">
          <div className="about-page__content">
            <h1 className="about-page__headline">{this.props.getTranslate('about-Title')}</h1>
            <p className="about-page__description">{this.props.getTranslate('about-SubTitle')}</p>
            <Link className="btn btn_size_lg home-wgt__add-company-btn home-wgt__add-company-btn-about btn_size_large" to={this.props.getPermalink('/registration')}>{this.props.getTranslate('about-RegCompanyButton')}</Link>
          </div>
        </div>
        <div className="about-page__phones">
          <img className="about-page__face" src={face1} alt="" />
          <img className="about-page__face" src={face2} alt="" />
          <img className="about-page__face" src={face3} alt="" />
          <img className="about-page__face" src={face4} alt="" />
          <img className="about-page__face" src={face5} alt="" />
          <img className="about-page__face" src={face6} alt="" />
          <img className="about-page__phones_center" src={phones_bg} alt={this.props.getTranslate('about-Title')} />
        </div>
      </section>
      <section className="about-apps">
        <div className="wrapper">
          <ul className="about-apps__list">
            <li>
              <a href="https://apps.apple.com/ua/app/matespace-io/id1609868472" target="_blank">
                <img src={apple_store} alt="" />
              </a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.matespace&hl=en_US" target="_blank">
                <img src={google_play} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </section>
      <section className="about-tools-motivation">
        <div className="wrapper">
          <div className="about-tools-motivation__header">
            <h2 className="about-page__headline">{this.props.getTranslate('about-UsersTitle')}</h2>
            <p className="about-page__description">{this.props.getTranslate('about-UsersSubTitle')}</p>
          </div>
        </div>
        <InfiniteScrollingList className="about-tools-motivation__people_top" items={[avatar_1, avatar_2, avatar_3, avatar_4, avatar_6, avatar_7, avatar_8, avatar_9, avatar_10, avatar_11, avatar_12, avatar_13, avatar_14, avatar_15, avatar_16, avatar_17, avatar_18]} />
        <InfiniteScrollingList className="about-tools-motivation__people_bottom" items={[avatar_19, avatar_20, avatar_21, avatar_22, avatar_23, avatar_24, avatar_25, avatar_26, avatar_27, avatar_28, avatar_29, avatar_30, avatar_31, avatar_32, avatar_33, avatar_34, avatar_35, avatar_36]} />
      </section>
      <section className="about-personal-card">
        <div className="wrapper">
          <div className="about-tools-motivation__header">
            <h2 className="about-page__headline">{this.props.getTranslate('about-BonusCardTitle')}</h2>
            <p className="about-page__description">{this.props.getTranslate('about-BonusCardSubTitle')}</p>
          </div>
          <div className="about-info-app__inner">
            <div className="about-info-app__col">
              <h2 className="about-info-app__headline">{this.props.getTranslate('about-BonusCardWgtHeadline')}</h2>
              <ul className="about-info-app__list">
              {this.state.sliderBonusesData.map((item, index) => {
                return <li key={index} className="about-info-app__list-item" onClick={() => this.handlerGetImgBonuses(index)}>
                  <button className={`about-info-app__list-btn about-info-app__list-btn-desctop${index === this.state.activeSlideBonuses ? ' active' : ''}`} type="button" onClick={() => this.handleSlideTo2(index)}>
                    <span>
                      <svg className="about-info-app__list-btn-icon">
                        <use href={`${sprite}#arrow-right`}></use>
                      </svg>
                    </span>{tagsBonuses[index]}
                  </button>
                  <button className={`about-info-app__list-btn about-info-app__list-btn-mob${index === this.state.activeSlideBonusesMob ? ' active' : ''}`} type="button" onClick={() => this.handleSlideTo2(index)}>
                    <span>
                      <svg className="about-info-app__list-btn-icon">
                        <use href={`${sprite}#arrow-right`}></use>
                      </svg>
                    </span>{tagsBonuses[index]}
                  </button>
                  <div className="about-info-app__list-item-thumbnail-wrap">
                    {index === this.state.bonusesImg ? <img className="about-info-app__list-item__thumbnail" src={item.img} alt={tagsBonuses[index]} /> : ''}
                  </div>
                </li>
              })}
              </ul>
              <div className="about-info-app__footer">
                <Button className="btn_size_large" type="link" text="Детальніше про функції додатку" handler="#" btnSize="large" />
              </div>
            </div>
            <div className="about-info-app__col">
              <div className="swiper-container swiper-bonuses-wgts">
                <div className="swiper-wrapper">
                {this.state.sliderBonusesData.map((item, index) => {
                return <div className="swiper-slide">
                  <img className="about-info-app__list-item__thumbnail" src={item.img} alt={tagsBonuses[index]} />
                </div>
                })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AboutMobileAppOpportunities
        headline={this.props.getTranslate('about-AppOpportunitiesHeadline')}
        description={this.props.getTranslate('about-AppOpportunitiesDescription')}
        getTranslate={this.props.getTranslate}
      />
      <AboutIncludeCompany
        headline={this.props.getTranslate('about-AboutIncludeCompanyHeadline')}
        description={this.props.getTranslate('about-AboutIncludeCompanyDescription')}
        navs={[
          <DemoModal
            openBtnClass="btn btn_width_100 btn_theme_white-transparent btn_size_lg btn_size_large"
            getTranslate={this.props.getTranslate}
          />,
          <Button className="btn_size_large" type="link" text={this.props.getTranslate('about-RegCompanyButton')} handler="/registration" btnWidth="full" btnSize="large" btnTheme="white" />
        ]}
        img={dashboard_include}
        relativeImg={true}
        reverse={true}
        imgScale={true}
      />
      <section>
        <div className="wrapper">
          <div className="about-info-app__inner about-info-app__inner_theme_dark">
            <div className="about-info-app__col">
              <h2 className="about-info-app__headline">{this.props.getTranslate('about-AboutDashboardHeadline')}</h2>
              <ul className="about-info-app__list">
              {this.state.sliderDashboardManagerData.map((item, index) => {
                return <li key={index} className="about-info-app__list-item" onClick={() => this.handlerGetImgDashboard(index)}>
                  <button className={`about-info-app__list-btn about-info-app__list-btn-desctop${index === this.state.activeSlide ? ' active' : ''}`} type="button" onClick={() => this.handleSlideTo(index)}>
                    <span>
                      <svg className="about-info-app__list-btn-icon">
                        <use href={`${sprite}#arrow-right`}></use>
                      </svg>
                    </span>{tagsDashboard[index]}
                  </button>
                  <button className={`about-info-app__list-btn about-info-app__list-btn-mob${index === this.state.dashboardManagerImgMob ? ' active' : ''}`} type="button" onClick={() => this.handleSlideTo(index)}>
                    <span>
                      <svg className="about-info-app__list-btn-icon">
                        <use href={`${sprite}#arrow-right`}></use>
                      </svg>
                    </span>{tagsDashboard[index]}
                  </button>
                  <div className="about-info-app__list-item-thumbnail-wrap">
                    {index === this.state.dashboardManagerImg ? <img className="about-info-app__list-item__thumbnail" src={item.img} alt={tagsDashboard[index]} /> : ''}
                  </div>
                </li>
              })}
              </ul>
              <div className="about-info-app__footer">
                <Button className="btn_size_large" type="link" text="Детальніше про функції додатку" handler={this.props.getPermalink('/about-panel')} btnSize="large" />
              </div>
            </div>
            <div className="about-info-app__col">
              <div className="about-info-app__col-thumbnail">
              <div className="swiper-container swiper-dashboard-wgts">
                <div className="swiper-wrapper">
                {this.state.sliderDashboardManagerData.map((item, index) => {
                return <div className="swiper-slide">
                  <img className="about-info-app__thumbnail about-info-app__thumbnail-about-app" src={item.img} alt={tagsDashboard[index]} />
                </div>
                })}
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer getPermalink={this.props.getPermalink} getTranslate={this.props.getTranslate} />
    </>;
  }
}
export default About;
