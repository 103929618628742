import React from "react";
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import AccountContentHeader from './components/AccountContentHeader';
import AccountContentEmpty from './components/AccountContentEmpty';
import Button from './components/Button';
import DeleteModal from './components/DeleteModal';
import Loading from './components/Loading';
import Table from './components/Table';
import Pagination from './components/Pagination';
import { Navigate } from "react-router-dom";
import ResultQuery from './components/ResultQuery';
import ResultErrors from './components/ResultErrors';
import bonuses_img from './media/bonuses.png';
import { getAuth } from "firebase/auth";
class AccountBonuses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      navPath: '',
      currentPage: 0,
      isLoaded: false,
      bonuses: [],
      indexRowEvent: null,
      removeIndex: null,
      totalPages: null,
      error: null,
      successBonusesSubmit: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("successBonusesSubmit") ? JSON.parse(localStorage.getItem("successBonusesSubmit")) : null) : '',
      successBonusesDelete: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("successBonusesDelete") ? JSON.parse(localStorage.getItem("successBonusesDelete")) : null) : '',
      errors: null,
      error: false,
      modalDeleteBonuses: false,
      idDeleteBonuses: null,
      controlRow: null
    }
  }

  /* Handler for get mobile menu */
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };

  /* Handler for dropdown */
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }

  /* Pagination handler */
  handlerPaginationItems = (bonusesItems, current) => {
    this.setState({
      bonuses: bonusesItems,
      currentPage: current
    });
  }

  /* Events disabled modal */
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDeleteBonuses: false,
        idDeleteBonuses: null
      }));
    }, 500);
  }

  /* Handler for close success labels */
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successBonusesSubmit: null
    }));
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      localStorage.removeItem('successBonusesSubmit');
      localStorage.removeItem('successDelete');
    }
  }

  /* Handler for close errors labels */
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }

  handlerPermalinkBonuses (id, index, event) {
    if(!event.target.closest('.control-wgt')) {
      this.setState(prevState => ({
        navPath: `/account/bonuses/view/${id}`
      }));
    }
  }

  /* Handler get data mount component */
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/loyalties/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(
          {
            "itemsPerPage": 20,
            "orderField": "creationDate"
          }
        )
      }).then(res => {
        if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoaded: true,
                errors: data.errors,
                error: true
              })
            });
        } else {
            res.json().then(function(data) {
              that.setState({
                isLoaded: true,
                bonuses: data.data,
                totalPages: data.totalPages
              })
            });
        }
      })
    })
  }

  handlerDeleteBonusesModal(id, index, event){
    this.setState({
      modalDeleteBonuses: true,
      removeIndex: index,
      idDeleteBonuses: id
    });
  }
  handlerDelete(event){
    let that = this;
    that.setState({
      loadingDeleteSumbit: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/loyalties/${that.state.idDeleteBonuses}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              loadingDeleteSumbit: false
            })
          });
      } else {
        that.setState({
          indexRowEvent: that.state.removeIndex
        });
        fetch(`${that.props.queryLink}/api/v1/loyalties/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20, "page": that.state.bonuses.length < 2 && that.state.bonuses.length > 1 ? (that.state.currentPage - 1) : that.state.currentPage})
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                error: true
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                indexRowEvent: null,
                removeIndex: null,
                bonuses: data.data,
                totalPages: data.totalPages,
                modalDeleteBonuses: false,
                loadingDeleteSumbit: false
              });
            })
          }
        })
      }
    })
  })
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerEnabled(id, index, event){
    let that = this;
    let arr = that.state.bonuses;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/loyalties/${id}/status`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({"loyaltyStatus": arr[index].loyaltyStatus === 'NOT_ACTIVE' ? 'ACTIVE' : 'NOT_ACTIVE'})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            arr[index] = data;
            that.setState({
              bonuses: arr
            })
          })
        }
      })
    });
  }

  /* Render component */
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('bunuses-Title') + ' | ' + this.props.companyName;
    }
    if(this.state.successBonusesSubmit){
      setTimeout(() => {
        this.handlerCloseSuccess();
      }, 5000);
    }
    let resultBonuses = '';
    if(this.state.error) {
      resultBonuses = <div>Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultBonuses = <Loading />;
    } else {
      resultBonuses = <div className="account-wgt account-wgt__bonuses">

      {/* Table component */}
        <Table
          nameWidth="70%"
          header={[
            this.props.getTranslate('bunuses-Caption'),
            this.props.getTranslate('bunuses-CountPeople'),
            this.props.getTranslate('bunuses-Status'),
            ''
          ]}
          handlerRow={this.handlerPermalinkBonuses.bind(this)}
          bodyData={this.state.bonuses}
          bodyDataNames={['title', 'userCount', 'loyaltyStatus']}
          controlItemsStatus={true}
          controlItems={{
            'enabled': {'status': true, 'handler': this.handlerEnabled.bind(this), 'label': {'hide': this.props.getTranslate('tableDropdown-StatusLabel'), 'show': this.props.getTranslate('tableDropdown-StatusLabelShow')}},
            'editButton': false,
            'editLink': {
              'status': true,
              'handler': this.props.getPermalink(`/account/bonuses/edit/`),
              'label': this.props.getTranslate('tableDropdown-EditLabel')
            },
            'delete': {
              'status': true,
              'handler': this.handlerDeleteBonusesModal.bind(this),
              'label': this.props.getTranslate('tableDropdown-EditRemove')
            }
          }}
          translationValueItems={{
              'ACTIVE': this.props.getTranslate('bunuses-StatusActive'),
              'NOT_ACTIVE': this.props.getTranslate('bunuses-StatusNotActive'),
            }}
          labelsStatus={{
            'loyaltyStatus': {
              'ACTIVE': 'blue',
              'NOT_ACTIVE': 'red',
            }
          }}
          itemIDName={'loyaltyID'}
          itemEnableNameForStatus={'NOT_ACTIVE'}
          itemEnableNameForStatusData={'loyaltyStatus'}
          enabledMobileContent={true}
          mobileContentType={'block'}
          mobileContentHeader={[
            this.props.getTranslate('bunuses-CountPeople'),
            this.props.getTranslate('bunuses-Status')
          ]}
          mobileContentData={['title', 'userCount', 'loyaltyStatus']}
          removeIndex={this.state.removeIndex}
        />

      </div>
    }
    return <>

    {/* Error handler */}
    <ResultErrors
      errors={this.state.errors}
      closeHandler={this.handlerCloseError.bind(this)}
    />

    {/* Query result handler */}
    {this.state.successBonusesSubmit || this.state.successBonusesDelete ? <ResultQuery
      success={this.state.successBonusesSubmit.status}
      resultText={this.state.successBonusesSubmit.text}
      handlerSuccess={this.handlerCloseSuccess}
      handlerError={this.handlerCloseError}
    /> : ''}

    {/* Header component */}
    <AccountHeader
      queryLink={this.props.queryLink}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      getTranslate={this.props.getTranslate.bind(this)}
      completed={this.props.completed}
    />

    <div className="account-wrap">

    {/* Navigation component */}
    <AccountNav
      getPermalink={this.props.getPermalink}
      queryLink={this.props.queryLink}
      configsApp={this.props.configsApp}
      newReview={this.props.newReview}
      companyName={this.props.companyName}
      token={this.props.token()}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      dropdownStatus={this.state.dropdownStatus}
      dropdownHandler={this.handlerDropdown}
      getTranslate={this.props.getTranslate}
      completed={this.props.completed}
    />

    {this.state.navPath && <Navigate
      to={this.props.getPermalink(this.state.navPath)}
      replace={true}
    />}

    <div className="account-content">
      <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          title={this.props.getTranslate('bunuses-Title')}
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
        />

        <div className="team-nav">

          {/* Button for add bonuses */}
          <Button
            type="link"
            icon={true}
            iconItem="#plus-icon"
            text={this.props.getTranslate('bunuses-AddButton')}
            addClass="header-content-nav__btn"
            handler={this.props.getPermalink("/account/bonuses/create")}
          />

        </div>

        {this.state.bonuses.length < 1 && this.state.isLoaded && !this.state.error ? <>
          <div className="account-content__content account-content__content_empty">
            <div className="empty-wrapper">

              {/* If the response is empty */}
              <AccountContentEmpty
                img={bonuses_img}
                title={this.props.getTranslate('bonuses-EmptyBonusesTitle')}
                description={this.props.getTranslate('bonuses-EmptyBonusesDescription')}
                buttonType="link"
                buttonText={this.props.getTranslate('bunuses-AddButton')}
                buttonIcon="#plus-icon"
                buttonHandler={this.props.getPermalink("/account/bonuses/create")}
              />

            </div>
            </div></> : <><div className="account-content__content">

            {/* Query result */}
            {resultBonuses}

          </div></>}

        <div className="account-content__footer">
          <Pagination
            total={this.state.totalPages}
            perPage="20"
            path={`${this.props.queryLink}/api/v1/loyalties/search`}
            currentPage={this.state.currentPage}
            items={this.handlerPaginationItems}
          />
        </div>

      </div>
    </div>
    </div>

    {/* Delete item modal */}
    <DeleteModal
      modalStatus={this.state.modalDeleteBonuses}
      modalDisabledStatus={this.state.modalDisabled}
      modalDisabledClass={this.state.addClass}
      handlerModalDisabled={this.handleModalDisabled}
      modalTitle={this.props.getTranslate('modal-deleteBonusesTitle')}
      modalDescription={this.props.getTranslate('modal-deleteBonusesDescription')}
      modalHandlerDelete={this.handlerDelete.bind(this)}
      modalHandlerBtnTxt={this.props.getTranslate('removeModal-ButtonSubmit')}
      modalHandlerBtnLoading={this.state.loadingDeleteSumbit}
    />

    </>;
  }
}
export default AccountBonuses;
