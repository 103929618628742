import React from "react";
import DateFormating from './components/DateFormating';
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import AccountContentHeader from './components/AccountContentHeader';
import Loading from './components/Loading';
import { Link, useParams, Navigate } from "react-router-dom";
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import { getAuth } from "firebase/auth";
import sprite from './media/icons.svg';
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
function getNumberOfDays(start, end) {
  const date1 = new Date(start);
  const date2 = new Date(end);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
}
class BonusesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      chooseDateFull: null,
      startDate: null,
      endDate:null,
      finishStartDate: null,
      finishEndDate: null,
      startTime: null,
      endTime: null,
      hoverDate: null,
      date: new Date(),
      fullYear: null,
      month: null,
      currentDate: new Date(),
      dates: [],
      countFirstElems: null,
      countEndedElems: null,
      modalAddUser: false,
      modalData: {},
      modalDisabled: false,
      modal: false,
      dateModal: false,
      bonuseData: null,
      isLoaded: false,
      usersSelect: [],
      addUserID: null,
      statusValue: 'ACTIVE',
      codeValue: '',
      descriptionValue: '',
      editIdUser: null,
      editIdLoyaltyID: null,
      loadingSubmit: false,
      indexRowEvent: null,
      removeIndex: null,
      errors: null,
      codePlaceholder: null,
      chooseDateType: 'start',
      iterStart: null,
      iterStartSave: null,
      iterHover: null,
      countMonth: 1,
      countMonthSave: null,
      iterFinish: null,
      countMonthFinish: null,
      finishStartNow: null,
      finishEndDateNow: null,
      controlRow: null,
      successDelete: false
    }
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    let nowDate = new Date();
    let nowDateDay = nowDate.getDate();
    let nowDateMonth = nowDate.getMonth() + 1;
    let nowDateYear = nowDate.getFullYear();
    that.setState({
      finishStartNow: (nowDateDay < 10 ? '0' + nowDateDay : nowDateDay) + '.' + (nowDateMonth < 10 ? '0' + nowDateMonth : nowDateMonth) + '.' + nowDateYear
    });
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token()
    });
    fetch(`${that.props.queryLink}/api/v1/loyalties/${this.props.id}`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            errors: data.errors,
            error: true
          });
        })
      } else {
        res.json().then(function(data) {
          let finishDate = new Date(data.endTime);
          let finishDateDay = finishDate.getDate();
          let finishDateMonth = finishDate.getMonth() + 1;
          let finishDateYear = finishDate.getFullYear();
            that.setState({
              codeValue: data.typeValue
            })
          that.setState({
            finishEndDateNow: (finishDateDay < 10 ? '0' + finishDateDay : finishDateDay) + '.' + (finishDateMonth < 10 ? '0' + finishDateMonth : finishDateMonth) + '.' + finishDateYear,
            isLoaded: true,
            bonuseData: data
          });
        })
      }
    })
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
      usersSelect: [],
      editIdUser: null,
      editIdLoyaltyID: null,
      descriptionValue: '',
      moneyValue: '',
      addUserID: null,
      statusValue: 'ACTIVE',
      chooseDateFull: null,
      startDate: null,
      endDate:null,
      finishStartDate: prevState.finishStartNow,
      finishEndDate: prevState.finishEndDateNow,
      startTime: null,
      endTime: null,
      hoverDate: null,
      dateModal: false,
      date: new Date(),
      fullYear: null,
      month: null,
      currentDate: new Date(),
      dates: [],
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalAddUser: false,
        modalDisabled: false,
        modalData: {}
      }));
    }, 500);
  }
  handlerAddUser (id, loyaltyID) {
    let that = this;
    this.setState(prevState => ({
      modal: !prevState.modal,
      modalClass: null,
      modalAddUser: !prevState.modalAddUser,
      editIdUser: id,
      editIdLoyaltyID: loyaltyID,
      finishStartDate: prevState.finishStartNow,
      finishEndDate: prevState.finishEndDateNow
    }));
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token()
    });
    if(id && loyaltyID){
      fetch(`${that.props.queryLink}/api/v1/loyalties/${this.props.id}/user-loyalties/${loyaltyID}`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          })
        } else {
          res.json().then(function(data) {
            that.setState({
              codeValue: data.code,
              descriptionValue: data.description,
              finishEndDate: data.endTime,
              moneyValue: data.money,
              finishStartDate: data.startTime,
              addUserID: data.userID,
              statusValue: data.userLoyaltyStatus
            });
          })
        }
      })
    } else {
      fetch(`${that.props.queryLink}/api/v1/users/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 9999})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          })
        } else {
          res.json().then(function(data) {
            let bonusesDataUsers = that.state.bonuseData.users;
            let ids = bonusesDataUsers.map(item => item.userID);
            data.data.map((item, index) => {
              if(ids.indexOf(item.userID) === -1){
                let name = (item.name ? item.name + ' ' : '') + (item.lastName ? item.lastName + ' ' : '') + (item.middleName ? item.middleName : '');
                that.setState(prevState => ({
                  usersSelect: [...prevState.usersSelect, {value: item.userID, label: name ? name : item.email} ]
                }));
              }
            });
          })
        }
      })
    }
  }
  handleraddUserID(event) {
    this.setState({
      addUserID: event.value
    });
  }
  handlerStatusValue(event) {
    this.setState({
      statusValue: event.value
    });
  }
  handlerCodeValue(event) {
    this.setState({
      codeValue: event.target.value
    });
  }
  handlerDescriptionValue(event) {
    this.setState({
      descriptionValue: event.target.value
    });
  }
  handlerAddUserSubmit (event) {
    let that = this;
    event.preventDefault();
    this.setState({
      loadingSubmit: true
    });
    let obj = {
      "code": this.state.codeValue,
      "description": this.state.descriptionValue,
      "endTime": (this.state.finishEndDate ? new Date(this.state.finishEndDate.split('.').reverse().join('-')).toISOString() : new Date(this.state.finishStartDate.split('.').reverse().join('-')).toISOString()),
      "money": Number(this.state.moneyValue) ? Number(this.state.moneyValue) : 0,
      "startTime": new Date(this.state.finishStartDate.split('.').reverse().join('-')).toISOString(),
      "userID": this.state.addUserID,
      "userLoyaltyStatus": this.state.statusValue
    };
    if(this.state.editIdLoyaltyID){
      obj.userLoyaltyIID = this.state.editIdLoyaltyID;
    }
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token()
    });
    fetch(this.state.editIdLoyaltyID ? `${that.props.queryLink}/api/v1/loyalties/${this.props.id}/user-loyalties/${this.state.editIdLoyaltyID}` : `${that.props.queryLink}/api/v1/loyalties/${this.props.id}/user-loyalties`, {
      method: this.state.editIdLoyaltyID ? 'PUT' : 'POST',
      headers: myHeaders,
      body: JSON.stringify(obj)
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            loadingSubmit: false,
            errors: data.errors
          });
        })
      } else {
        that.handleModalDisabled();
        that.setState({
          loadingSubmit: false
        });
        fetch(`${that.props.queryLink}/api/v1/loyalties/${that.props.id}`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                bonuseData: data
              });
            })
          }
        })
      }
    })
  }
  handlerDeleteUser(idUser, index, event){
    event.preventDefault();
    let that = this;
    if(event.target.closest('.bonuses-info__user-nav')) {
      this.setState({
        indexRowEvent: index,
        removeIndex: index
      });
    }
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token()
    });
    fetch(`${that.props.queryLink}/api/v1/loyalties/${this.props.id}/user-loyalties/${idUser}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
      } else {
        fetch(`${that.props.queryLink}/api/v1/loyalties/${that.props.id}`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                indexRowEvent: null,
                removeIndex: null,
                errors: data.errors
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                indexRowEvent: null,
                removeIndex: null,
                bonuseData: data
              });
            })
          }
        })
      }
    })
  }
  handlerUserStatus(index, idUser, idLoyalties, event){
    let that = this;
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token()
    });
    let arrUsers = this.state.bonuseData.users;
    fetch(`${that.props.queryLink}/api/v1/loyalties/${this.props.id}/user-loyalties/${idLoyalties}/status`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({"userLoyaltyStatus": arrUsers[index].userLoyaltyStatus !== 'ACTIVE' ? 'ACTIVE' : 'NOT_ACTIVE'})
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
      } else {
        arrUsers[index].userLoyaltyStatus = (arrUsers[index].userLoyaltyStatus !== 'ACTIVE' ? 'ACTIVE' : 'NOT_ACTIVE');
        res.json().then(function(data) {
          that.setState(prevState => ({
            bonuseData: {...prevState.bonuseData, users: arrUsers}
          }));
        })
      }
    })
  }
  handlerGetDate (event) {
    event.preventDefault();
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalAddUser: false,
        modalDisabled: false,
        dateModal: true
      }));
    }, 500);
    let fullYear = this.state.date.getFullYear();
    let month = this.state.date.getMonth();
    let current = this.state.date.getDate();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      dates: arr,
      fullYear: fullYear,
      pushElemsTotal: pushElemsNum,
      month: month
    }));
  }
  getLastDayOfMonth (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDate();
  }
  getUnshiftElemsNum (year, month) {
    let jsDayNum = this.getFirstWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return realDayNum - 1;
  }
  getPushElemsNum (year, month) {
    let jsDayNum = this.getLastWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return 7 - realDayNum;
  }
  createArr (from, to) {
    const arr = [];
    for(let i = from; i <= to; i++) {
      arr.push(i);
    }
    return arr;
  }
  unshiftElems (num, lastDayPrevMonth, arr) {
    this.setState(prevState => ({
      countFirstElems: num
    }));
    let count =  lastDayPrevMonth - num;
    for(let i = lastDayPrevMonth; i > count; i--) {
      arr.unshift(i);
    }
    return arr;
  }
  pushElems (num, arr) {
    this.setState(prevState => ({
      countEndedElems: num
    }));
    for(let i = 1; i <= num; i++) {
      arr.push(i);
    }
    return arr;
  }
  chunkArr (num, arr) {
    let result = [];
    let chunk = [];
    let iterCount = arr.length / num;
    for(let i = 0; i <iterCount; i++) {
      chunk = arr.splice(0, num);
      result.push(chunk);
    }
    return result;
  }
  getRealDayOfWeekNum (jsNumOfDay) {
    if(jsNumOfDay === 0){
      return 7;
    } else {
      return jsNumOfDay;
    }
  }
  getFirstWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month, 1);
    return date.getDay();
  }
  getLastWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDay();
  }
  getMonthName (num) {
    const monthes = [
      this.props.getTranslate('modal-calendarMonthNameJan'),
      this.props.getTranslate('modal-calendarMonthNameFeb'),
      this.props.getTranslate('modal-calendarMonthNameMar'),
      this.props.getTranslate('modal-calendarMonthNameApr'),
      this.props.getTranslate('modal-calendarMonthNameMay'),
      this.props.getTranslate('modal-calendarMonthNameJun'),
      this.props.getTranslate('modal-calendarMonthNameJul'),
      this.props.getTranslate('modal-calendarMonthNameAug'),
      this.props.getTranslate('modal-calendarMonthNameSep'),
      this.props.getTranslate('modal-calendarMonthNameOct'),
      this.props.getTranslate('modal-calendarMonthNameNov'),
      this.props.getTranslate('modal-calendarMonthNameDec')
    ];
    return monthes[num];
  }
  handlerCloseGetDate (event) {
    event.preventDefault();
    this.setState(prevState => ({
      dateModal: false,
      modal: !prevState.modal,
      modalAddUser: !prevState.modalAddUser
    }));
  }
  handlerPrevMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() - 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month
    }));
  }
  handlerNextMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() + 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month
    }));
  }
  chooseDate (iterStart, nextMonth, prevMonth, event) {
    event.preventDefault();
    let month = Number(this.state.month) + 1;
    let year = this.state.fullYear;
    if(nextMonth){
      month = Number(month) + 1;
      if(month === 13){
        month = 1;
        year = year + 1;
      }
    }
    if(prevMonth){
      month = Number(month) - 1;
      if(month === 0){
        month = 12;
        year = year - 1;
      }
    }
    if(!this.state.finishStartDate){
      this.setState(prevState => ({
        chooseDateType: 'finish',
        startDate: prevState.fullYear + '-' + prevState.month + '-' + event.target.innerHTML,
        finishStartDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + ((prevState.month + 1) < 10 ? '0' + (prevState.month + 1) : prevState.month + 1) + '.' + prevState.fullYear,
        iterStart: iterStart,
        iterStartSave: iterStart,
        countMonthSave: prevState.countMonth
      }));
    } else if(this.state.chooseDateType === 'start') {
      this.setState(prevState => ({
        chooseDateType: 'finish',
        startDate: prevState.fullYear + '-' + prevState.month + '-' + event.target.innerHTML,
        finishStartDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + ((prevState.month + 1) < 10 ? '0' + (prevState.month + 1) : prevState.month + 1) + '.' + prevState.fullYear,
        iterStart: iterStart,
        iterStartSave: iterStart,
        countMonthSave: prevState.countMonth
      }));
    } else if(this.state.chooseDateType === 'finish'){
      this.setState(prevState => ({
        endDate: new Date(prevState.startDate) > new Date(prevState.fullYear + '-' + (nextMonth ? prevState.month + 1 : prevState.month) + '-' + event.target.innerHTML) ? null : prevState.fullYear + '-' + (nextMonth ? prevState.month + 1 : prevState.month) + '-' + event.target.innerHTML,
        finishEndDate: new Date(prevState.startDate) > new Date(prevState.fullYear + '-' + (nextMonth ? prevState.month + 1 : prevState.month) + '-' + event.target.innerHTML) ? null : (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + (nextMonth ? ((prevState.month + 2) < 10 ? '0' + (prevState.month + 2) : prevState.month + 2) : ((prevState.month + 1) < 10 ? '0' + (prevState.month + 1) : prevState.month + 1)) + '.' + prevState.fullYear,
        iterFinish: iterStart,
        countMonthFinish: prevState.countMonth
      }));
    } else {
      this.setState(prevState => ({
        endDate: new Date(prevState.startDate) > new Date(prevState.fullYear + '-' + (nextMonth ? prevState.month + 1 : prevState.month) + '-' + event.target.innerHTML) ? null : prevState.fullYear + '-' + (nextMonth ? prevState.month + 1 : prevState.month) + '-' + event.target.innerHTML,
        finishEndDate: new Date(prevState.startDate) > new Date(prevState.fullYear + '-' + (nextMonth ? prevState.month + 1 : prevState.month) + '-' + event.target.innerHTML) ? null : (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + (nextMonth ? ((prevState.month + 2) < 10 ? '0' + (prevState.month + 2) : prevState.month + 2) : ((prevState.month + 1) < 10 ? '0' + (prevState.month + 1) : prevState.month + 1)) + '.' + prevState.fullYear,
        iterFinish: iterStart,
        countMonthFinish: prevState.countMonth
      }));
    }

  }
  chooseDateHover (iterHover, event) {
    event.preventDefault();
    if(this.state.startDate){
      this.setState(prevState => ({
        iterHover: iterHover
      }));
    }
  }
  chooseEndHover (event) {
    event.preventDefault();
    this.setState(prevState => ({
      iterHover: null
    }));
  }
  onKeyPress (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode == 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += '.';
    }
    if(len === 5) {
      input.value += '.';
    }
  }
  onKeyPress2 (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode == 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += ':';
    }
  }
  handlerStartTime (event) {
    event.preventDefault();
    this.setState({
      startTime: event.target.value
    });
  }
  handlerEndTime (event) {
    event.preventDefault();
    this.setState({
      endTime: event.target.value
    });
  }
  setDateInput (event) {
    event.preventDefault();
    this.setState(prevState => ({
      chooseDateFull: prevState.finishStartDate.split("-").reverse().join(".") + ' - ' + (prevState.finishEndDate ? prevState.finishEndDate.split("-").reverse().join(".") : prevState.finishStartDate.split("-").reverse().join(".")),
      dateModal: !prevState.dateModal,
      modal: !prevState.modal,
      modalAddUser: !prevState.modalAddUser
    }));
  }
  chooseDateHandler(choose, event){
    this.setState({
      chooseDateType: choose
    });
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerCloseElements(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const div = document.querySelector( '.control-wgt');
        if(div) {
          document.addEventListener('click', e => {
            const withinBoundaries = e.composedPath().includes(div);
            if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
              if ( ! withinBoundaries ) {
                this.setState( {
                  controlRow: null
                } );
              }
            }
        });
      }
    }
  }
  handlerBonuseDelete(event){
      let that = this;
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/loyalties/${that.props.id}`, {
          method: 'DELETE',
          headers: myHeaders
        }).then(res => {
          if (res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              });
            })
          } else {
            if(typeof window !== "undefined" && typeof document !== 'undefined'){
              localStorage.setItem("successDelete", JSON.stringify({status: true, text: 'Успішно видалений'}))
            }
            that.setState({
              successDelete: true
            });
          }
        })
      })
  }
  handlerEnabled(id, index, event){
    let that = this;
    let bonuseData = that.state.bonuseData;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/loyalties/${that.props.id}/status`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({"loyaltyStatus": bonuseData.loyaltyStatus === 'NOT_ACTIVE' ? 'ACTIVE' : 'NOT_ACTIVE'})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            bonuseData.loyaltyStatus = bonuseData.loyaltyStatus === 'NOT_ACTIVE' ? 'ACTIVE' : 'NOT_ACTIVE';
            that.setState({
              bonuseData: bonuseData
            })
          })
        }
      })
    });
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = (this.state.bonuseData && this.state.bonuseData.title ? this.state.bonuseData.title + ' | ' : '') + this.props.companyName;
    }
    const addUserType = [
      { value: 'ACTIVE', label: this.props.getTranslate('bunuses-addUserStatusActive') },
      { value: 'NOT_ACTIVE', label: this.props.getTranslate('bunuses-addUserStatusNotActive') },
    ];
    let codePlaceholder = '';
    if(this.state.bonuseData){
          if(this.state.bonuseData.loyaltyType === 'COMPENSATION'){
              codePlaceholder = this.props.getTranslate('bunuses-typeCaptionCompensation');
          } else if(this.state.bonuseData.loyaltyType=== 'CARD'){
              codePlaceholder = this.props.getTranslate('bunuses-typeCaptionCard');
          } else if(this.state.bonuseData.loyaltyType === 'PROMO_CODE'){
              codePlaceholder = this.props.getTranslate('bunuses-typeCaptionPromo');
          } else if(this.state.bonuseData.loyaltyType === 'DEPOSIT'){
              codePlaceholder = this.props.getTranslate('bunuses-typeCaptionDeposit');
          } else if(this.state.bonuseData.loyaltyType === 'NUMBER'){
              codePlaceholder = this.props.getTranslate('bunuses-typeCaptionCertificate');
          } else {
              codePlaceholder = this.props.getTranslate('bunuses-typeCaptionOther');
          }
        }
    let qw;
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      qw = window.location.protocol;
    }
    let totalItem = this.state.dates.length * 7;
    let totalItemNotPush = totalItem - this.state.pushElemsTotal;
    let firstItems = this.state.countFirstElems;
    let endedItems = totalItem - this.state.countEndedElems;
    let iter = 0;
    let tableDate = this.state.dates.map((item, index) => {
      return <tr key={index}>
        {item.map((elem, i) => {
          iter++;
          if((this.state.date < this.state.currentDate && this.state.date.getMonth() !== this.state.currentDate.getMonth()) || (index === 0 && this.state.countFirstElems > i && this.state.month === this.state.currentDate.getMonth())){
            return <td className="ended" key={i}>{elem}</td>
          } else if(this.state.currentDate >= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + (elem + 1)) && iter < endedItems && (this.state.date.getMonth()) <= (this.state.currentDate.getMonth()) && this.state.date.getFullYear() <= this.state.currentDate.getFullYear()){
            return <td className="ended" key={i}>{elem}</td>
          } else if(new Date(this.state.startDate) <= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem) && new Date(this.state.hoverDate) >= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem)) {
            return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
          } else if(this.state.startDate && (new Date(this.state.startDate).getDate() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getDate()) && (new Date(this.state.startDate).getMonth() - 1 === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getMonth()) && (new Date(this.state.startDate).getFullYear() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getFullYear())) {
            return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
          } else if(this.state.iterStart && (this.state.countMonth >= this.state.countMonthSave && this.state.iterStart <= iter && this.state.iterHover >= iter)) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if(this.state.iterStart && this.state.iterFinish && (this.state.countMonth <= this.state.countMonthFinish && this.state.countMonth >= this.state.countMonthSave) && (this.state.iterStart <= iter && this.state.iterFinish >= iter)) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if(this.state.iterStart && this.state.iterFinish && this.state.countMonth < this.state.countMonthFinish && this.state.countMonth > this.state.countMonthSave){
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if((this.state.iterStart && this.state.iterFinish) && (this.state.countMonthSave !== this.state.countMonthFinish) && (this.state.countMonth === this.state.countMonthSave) && iter > this.state.iterStart) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else {
            return <td key={i} onMouseEnter={this.chooseDateHover.bind(this, iter)} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          }
        })}
      </tr>
    });
    let resultBonuses = '';
    if(this.state.error) {
      resultBonuses = <div className="errors-content">{this.props.getTranslate('loading-ErrorRenderContent')}</div>;
    } else if(!this.state.isLoaded) {
      resultBonuses = <Loading />;
    } else {
      let type = '';
      if(this.state.bonuseData){
        switch (this.state.bonuseData.loyaltyType) {
          case "COMPENSATION":
            type = this.props.getTranslate('bonuses-TypeCompensation');
            break;
          case "CARD":
            type = this.props.getTranslate('bonuses-TypeCard');
            break;
          case "PROMO_CODE":
            type = this.props.getTranslate('bonuses-TypePromo');
            break;
          case "DEPOSIT":
            type = this.props.getTranslate('bonuses-TypeDeposit');
            break;
          case "NUMBER":
            type = this.props.getTranslate('bonuses-TypeCertificate');
            break;
          default:
            type = this.props.getTranslate('bonuses-TypeOther');
        }
      }
      resultBonuses =         <div className="account-content__content">
      <div className="bonuses-info-content">
                <div className="account-wgt bonuses-info">
                  <div className="bonuses-info__content">
                    <div className="bonuses-info__content-inner">
                      <div className="bonuses-info__content-header">
                        <div className="bonuses-info__content-header-inner">
                          {this.state.bonuseData && this.state.bonuseData.logo ? <div className="bonuses-info__logo-wrap"><img className="bonuses-info__logo" src={qw === 'http:' ? this.state.bonuseData.logo.replace( /https:/g, "http:" ) : this.state.bonuseData.logo} alt="" /></div> : ''}
                          <h2 className="bonuses-info__headline">{this.state.bonuseData ? this.state.bonuseData.title : ''}</h2>
                          {this.state.bonuseData && <div className="bonuses-info__loyalty">{type}</div>}
                        </div>
                        <div className="user-statistic__info-right user-statistic__info-right-desktop view-page-nav">
                          <div className="user-statistic__info-nav">
                            <Link className="user-statistic__info-nav-item btn-edit" to={this.props.getPermalink(`/account/bonuses/edit/${this.props.id}`)}>
                              <svg className="btn-edit__icon">
                                <use href={`${sprite}#edit-icon`}></use>
                              </svg>
                            </Link>
                            <button className="user-statistic__info-nav-item btn-edit btn-edit_theme_remove" type="button" onClick={this.handlerBonuseDelete.bind(this)}>
                              <svg className="btn-edit__icon">
                                <use href={`${sprite}#delete-red`}></use>
                              </svg>
                            </button>
                            <div className="tg-list-item">
                              <input id="bonuse-status" className="tgl tgl-light" type="checkbox" checked={this.state.bonuseData.loyaltyStatus === 'NOT_ACTIVE' ? false : true} onChange={this.handlerEnabled.bind(this)} /><label className="tgl-btn" htmlFor="bonuse-status"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.bonuseData && <div className="bonuses-info__description" dangerouslySetInnerHTML={{ __html: this.state.bonuseData.description }}></div>}
                      {this.state.bonuseData && <p className="bonuses-info__address">{this.state.bonuseData.address}</p>}
                    </div>
                  </div>
                </div>
                {this.state.bonuseData.companyLoyalty ? <div className="bonuses-info__general-label">
                  <div className="bonuses-info__general-label-inner">
                    <div className="bonuses-info__general-label-txt">
                      <p>Тип бонуса: <b>Загальний</b> (Даний бонус розповсюджується на всіх юзерів аккаунту, щоб додавати бонуси індивідуально, відредагуйте це бонус, та зніміть опцію “Загальний бонус”)</p>
                      {this.state.codeValue ? <p>Промокод для всіх юзерів: <b>{this.state.codeValue}</b></p> : ''}
                    </div>
                    <Link className="btn-edit" to={this.props.getPermalink(`/account/bonuses/edit/${this.state.bonuseData.loyaltyID}`)}>
                      <svg className="btn-edit__icon">
                        <use href={`${sprite}#edit`}></use>
                      </svg>
                    </Link>
                  </div>
                </div> : <div className="bonuses-info__users">
                  <button className="btn btn_with_icon team-nav__btn" type="button" onClick={this.handlerAddUser.bind(this)}>
                    <svg className="btn__icon-add">
                      <use href={`${sprite}#plus-icon`}></use>
                    </svg>
                  {this.props.getTranslate('bunusesView-AddEmployees')}</button>
                  <div className="bonuses-info__users-content">
                    <div className="bonuses-info__users-content-wgt">{this.state.bonuseData && this.state.bonuseData.users ? this.state.bonuseData.users.length : 0} {this.props.getTranslate('bunusesView-CountEmployees')}</div>
                  </div>
                </div>}
                </div>
                {this.state.bonuseData && this.state.bonuseData.users.length > 0 ? <><div className="bonuse-users-mob">
                {this.state.bonuseData.users.map((item, index) => {
                  return <div className="item-list-wgt" key={index}>
                    <div className="item-list-wgt__header">
                      <div className="item-list-wgt__name bonuse-users-mob__name">{item.userName} <img src={qw === 'http:' ? item.userPhoto.replace( /https:/g, "http:" ) : item.userPhoto} alt="" /></div>
                      <div className="item-list-wgt-nav item-list-wgt__nav">
                        <button className="item-list-wgt__nav-btn" type="button" onClick={this.handlerAddUser.bind(this, item.userID, item.userLoyaltyIID)}>
                          <svg className="item-list-wgt__nav-icon">
                            <use href={`${sprite}#edit`}></use>
                          </svg>
                        </button>
                        <button className="item-list-wgt__nav-btn" type="button" onClick={this.handlerDeleteUser.bind(this, item.userLoyaltyIID, index)}>
                          <svg className="item-list-wgt__nav-icon">
                            <use href={`${sprite}#delete`}></use>
                          </svg>
                        </button>
                        <div className="tg-list-item">
                          <input className="tgl tgl-light" id={'m-' + item.userID}  type="checkbox" checked={item.userLoyaltyStatus === 'ACTIVE' ? true : false} onChange={this.handlerUserStatus.bind(this, index, item.userID, item.userLoyaltyIID)} /><label className="tgl-btn" htmlFor={'m-' + item.userID}></label>
                        </div>
                      </div>
                    </div>
                    <div className="item-list-wgt__content">
                      <div className="item-list-wgt__content-row item-list-wgt__content-row_padding">
                        <div className="item-list-wgt__content-wgt">
                          <div className="item-list-wgt__content-wgt-label">{this.props.getTranslate('bunusesView-TableCode')}</div>
                          <div className="item-list-wgt__content-wgt-value">{item.code}</div>
                        </div>
                        <div className="item-list-wgt__content-wgt item-list-wgt__content-wgt_right">
                          <div className="item-list-wgt__content-wgt-label">{this.props.getTranslate('bunusesView-TableDate')}</div>
                          <div className="item-list-wgt__content-wgt-value">{item.startTime} - {item.endTime}</div>
                        </div>
                      </div>
                    </div>
                  </div>})}
                </div>
                <div className="standart-table__bonuses-wgt">
                <table className="standart-table standart-table__bonuses-users">
                  <thead>
                    <tr>
                      <th>{this.props.getTranslate('bunusesView-TableUsers')}</th>
                      <th>{this.props.getTranslate('bunusesView-TableDate')}</th>
                      <th>{this.props.getTranslate('bunusesView-TableCode')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.bonuseData.users.map((item, index) => {
                    let classRow = '';
                    if(this.state.removeIndex === index){
                      classRow = ' row-disabled';
                    } else {
                      classRow = '';
                    }
                    if(item.userLoyaltyStatus === 'NOT_ACTIVE'){
                      classRow = ' standart-table__row_disabled';
                    }
                    return <tr className={`standart-table__row${item.activeUser ? '' : ' standart-table__row_disabled'} ${classRow}`} key={index}>
                      <td>
                        <div className="bonuses-info__user">
                          <img src={qw === 'http:' ? item.userPhoto.replace( /https:/g, "http:" ) : item.userPhoto} alt="" />{item.userName}
                          {item.activeUser ? '' : <span className="bonuses-info__user_no-active">{this.props.getTranslate('bunusesView-TableNoActive')}</span>}
                        </div>
                      </td>
                      <td><DateFormating start={item.startTime} end={item.endTime} /> <span className="bonuses-info__days">{getNumberOfDays(item.startTime, item.endTime)} {this.props.getTranslate('bunusesView-PeriodDaysLabel')}</span></td>
                      <td>{item.code}</td>

                      <td className="team-table__control">
                      <div className="data-table__control-inner">
                        {item.userLoyaltyStatus === 'NOT_ACTIVE' ? <div className="data-table__hide">
                          <svg className="data-table__hide-icon">
                            <use href={`${sprite}#hide-pass`}></use>
                          </svg>
                          <div className="data-table__hide-content">Приховано з відображення в додатку</div>
                        </div> : ''}
                        <div className="control-wgt control-wgt__pos">
                          <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                            <svg className="control-btn__icon">
                              <use href={`${sprite}#more-icon`}></use>
                            </svg>
                          </button>
                          <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                            <ul className="control-content__list">
                              <li>
                                <button className="control-content__btn" type="button" onClick={this.handlerUserStatus.bind(this, index, item.userID, item.userLoyaltyIID)}>
                                  <svg className="control-content__icon">
                                    <use href={`${sprite}#view-icon-stroke`}></use>
                                  </svg>
                                  {item.userLoyaltyStatus === 'ACTIVE' ? this.props.getTranslate('tableDropdown-StatusLabel') : this.props.getTranslate('tableDropdown-StatusLabelShow')}</button>
                              </li>
                              <li>
                                <button className="control-content__btn" type="button" onClick={this.handlerAddUser.bind(this, item.userID, item.userLoyaltyIID)}>
                                  <svg className="control-content__icon">
                                    <use href={`${sprite}#edit-icon`}></use>
                                  </svg>{this.props.getTranslate('tableDropdown-EditLabel')}</button>
                              </li>
                              <li>
                                <button className="control-content__btn control-content__btn_delete" onClick={this.handlerDeleteUser.bind(this, item.userLoyaltyIID, index)}>
                                  {this.state.indexRowEvent !== index ? <svg className="control-content__icon">
                                    <use href={`${sprite}#delete-red`}></use>
                                  </svg> : <Loading />}{this.props.getTranslate('tableDropdown-EditRemove')}</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        </div>
                      </td>
                    </tr>
                  })}
                  </tbody>
                </table></div></> : ''}
              </div>
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successDelete && <Navigate to={this.props.getPermalink("/account/bonuses")} replace={true} />) : ''}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap" onClick={this.handlerCloseElements.bind(this)}>
    <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
    <div className="account-content">
      <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
          backBtn={true}
          backBtnType="link"
          backBtnClass="button-back_theme_gray"
          backBtnHandler={this.props.getPermalink("/account/bonuses")}
          backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
        />

        {resultBonuses}

        <div className="account-content__footer">

        </div>
      </div>
    </div>
    </div>
    {this.state.modal && this.state.modalAddUser ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.state.editIdUser && this.state.editIdLoyaltyID ? this.props.getTranslate('bunusesView-modalEditTitle') : this.props.getTranslate('bunusesView-modalAddTitle')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <form className="add-bonuse-user" onSubmit={this.handlerAddUserSubmit.bind(this)}>
          {this.state.editIdUser && this.state.editIdLoyaltyID ? '' : <div className="field-wrap">
            {this.state.usersSelect && <Select
              styles={customStyles}
              options={this.state.usersSelect}
              value={this.state.usersSelect[this.state.usersSelect.findIndex(el => el.value === this.state.addUserID)]}
              onChange={this.handleraddUserID.bind(this)}
              isSearchable={false}
              placeholder={this.props.getTranslate('bunusesView-modalUsersPlaceholder')}
            />}
          </div>}
          <div className="field-wrap">
            <input className="field-wrap__input" type="text" defaultValue={this.state.finishStartDate ? this.state.finishStartDate + ' - ' + this.state.finishEndDate : ''} onClick={this.handlerGetDate.bind(this)} placeholder={this.props.getTranslate('bunusesView-modalDatePlaceholder')} />
          </div>
          <div className="field-wrap">
            <Select
              styles={customStyles}
              options={addUserType}
              value={addUserType[addUserType.findIndex(el => el.value === this.state.statusValue)]}
              onChange={this.handlerStatusValue.bind(this)}
              isSearchable={false}
              placeholder={this.props.getTranslate('bunusesView-modalStatusPlaceholder')}
            />
          </div>
          {this.state.bonuseData && !this.state.bonuseData.companyLoyalty ? <div className="field-wrap">
            <input className="field-wrap__input" type="text" value={this.state.codeValue} onChange={this.handlerCodeValue.bind(this)} placeholder={this.state.bonuseData.loyaltyType ? 'Введіть ' + this.state.bonuseData.loyaltyType.toLowerCase() : this.props.getTranslate('bunuses-typeCaptionOther')} />
          </div> : ''}
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="submit" disabled={this.state.loadingSubmit ? true : false}>{this.state.editIdUser && this.state.editIdLoyaltyID ? this.props.getTranslate('bunusesView-modalEditBtnSubmit') : this.props.getTranslate('bunusesView-modalAddBtnSubmit')}{this.state.loadingSubmit && <Loading />}</button>
            </div>
        </form>
        </div></div> : ''}
        {this.state.dateModal && <div className="date-modal">
          <div className="date-modal__overlay" onClick={this.handlerCloseGetDate.bind(this)}></div>
          <div className="date-modal__content">
            <div className="date-modal__header">
              <div className="date-modal__headline">{this.props.getTranslate('bunuses-Date')}</div>
              <button className="date-modal__close-modal" type="button" onClick={this.handlerCloseGetDate.bind(this)}>
                <svg className="date-modal__close-modal-icon">
                  <use href={`${sprite}#close-icon`}></use>
                </svg>
              </button>
            </div>
            <div className="date-fields date-fields__simple">
              <div className={`date-field ${this.state.chooseDateType === 'start' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'start')}>
                <input className="date-field__date" type="text" maxLength="10" placeHolder="dd.mm.yyyy" value={this.state.finishStartDate ? this.state.finishStartDate : ''} onKeyPress={this.onKeyPress.bind(this)} />
              </div>
              <div className={`date-field ${this.state.chooseDateType === 'finish' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'finish')}>
                <input className="date-field__date" type="text" maxLength="10" placeHolder="dd.mm.yyyy" value={this.state.finishEndDate ? this.state.finishEndDate : ''} onKeyPress={this.onKeyPress.bind(this)} />
              </div>
            </div>
            <div id="calendar" className="calendar">
              <div className="calendar__info">
                <button className="calendar__month-nav" type="button" onClick={this.handlerPrevMonth.bind(this)}>
                  <svg className="calendar__month-nav-icon">
                    <use href={`${sprite}#chevron-left`}></use>
                  </svg>
                </button>
                <div className="calendar__month-year">{this.getMonthName(this.state.date.getMonth())}, {this.state.date.getFullYear()}</div>
                <button className="calendar__month-nav" type="button" onClick={this.handlerNextMonth.bind(this)}>
                  <svg className="calendar__month-nav-icon">
                    <use href={`${sprite}#chevron-right`}></use>
                  </svg>
                </button>
              </div>
              <table className="calendar-table">
                <thead className="calendar-table__head">
                  <tr>
                    <th>{this.props.getTranslate('modal-calendarDayWeekMonday')}</th>
                    <th>{this.props.getTranslate('modal-calendarDayWeekTuesday')}</th>
                    <th>{this.props.getTranslate('modal-calendarDayWeekWednesday')}</th>
                    <th>{this.props.getTranslate('modal-calendarDayWeekThursday')}</th>
                    <th>{this.props.getTranslate('modal-calendarDayWeekFriday')}</th>
                    <th>{this.props.getTranslate('modal-calendarDayWeekSutarday')}</th>
                    <th>{this.props.getTranslate('modal-calendarDayWeekSunday')}</th>
                  </tr>
                </thead>
                <tbody className="calendar-table__dates">{tableDate}</tbody>
              </table>
            </div>
            <div className="date-modal__nav">
              <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="button" onClick={this.setDateInput.bind(this)}>{this.props.getTranslate('modal-calendarAddBtn')}</button>
            </div>
          </div>
        </div>}
    </>;
  }
}
export default (props) => <BonusesView {...useParams()} {...props} />
