import React from "react";
import Field from "./Field";
import Button from "./Button";
import Select from 'react-select';
import sprite from '../media/icons.svg';
import asset from '../media/asset.png';
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const customStylesError = {
  control: () => ({
    height: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class DemoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusModal: false,
      submitSuccess: false,
      countValues: {},
      nameValue: '',
      contactValue: '',
      messageValue: '',
      companyNameValue: '',
      submitSuccess: false,
      loading: false
    }
  }
  componentDidMount() {
    let that = this;
    const myHeaders = new Headers({
        'Content-Type': 'application/json'
    });
    fetch(`${this.props.queryLink}/bo/v1/external/configs/demo-requests/employee-types`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {

      } else {
        res.json().then(function(data) {
          let values = data;
          let valuesObj = [];
          data.forEach((item, i) => {
            if(item === 'TO_10'){
              valuesObj.push({ value: item, label: 'До 10' });
            } else if(item === 'FROM_10_TO_30') {
              valuesObj.push({ value: item, label: 'Від 10 до 30' });
            } else if(item === 'FROM_30_TO_50'){
              valuesObj.push({ value: item, label: 'Від 30 до 50' });
            } else {
              valuesObj.push({ value: item, label: 'Більше 50' });
            }
          });
          that.setState({
            countValues: valuesObj
          });
        })
      }
    })
  }
  handlerStatusModal(event) {
    this.setState((prevState) => ({
      statusModal: !prevState.statusModal,
    }));
  }
  handlerNameValue(event){
    this.setState({
      nameValue: event.target.value
    });
  }
  handlerContactValue(event){
    this.setState({
      contactValue: event.target.value
    });
  }
  handlerCountValue(event){
    this.setState({
      countValue: event.value
    });
  }
  handlerCompanyValue(event){
    this.setState({
      companyNameValue: event.target.value
    });
  }
  handlerMessageValue(event){
    this.setState({
      messageValue: event.target.value
    });
  }
  handlerSubmit(event) {
    event.preventDefault();
    this.setState({
      loading: true
    });
    let submitObj = {
      "message": this.state.messageValue,
      "contacts": this.state.contactValue,
      "name": this.state.nameValue,
      "companyName": this.state.companyNameValue,
      "employeeCountType": this.state.countValue,
    };
    const myHeaders = new Headers({
        'Content-Type': 'application/json'
    });
    fetch(`${this.props.queryLink}/bo/v1/external/configs/demo-requests`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(submitObj)
    }).then(res => {
      if(res.status !== 200) {
        this.setState({
          loading: false
        });
      } else {
        this.setState({
          loading: false,
          submitSuccess: true,
          message: '',
          contacts: '',
          name: '',
          companyNameValue: '',
          employeeCountType: ''
        })
      }
    })
  }
  render() {
    return  <div className="demo-modal-wgt">
        <button className={this.props.openBtnClass} type="button" onClick={this.handlerStatusModal.bind(this)}>{this.props.getTranslate('demoBtn-BtnTxt')}</button>
        <div className={`demo-modal${this.state.statusModal ? ' demo-modal_active' : ''}`}>
          <div className="demo-modal__overlay" onClick={this.handlerStatusModal.bind(this)}></div>
          <div className="demo-modal-content">
            <div className="demo-modal-content__inner">
              <div className="demo-modal-content__info">
                <div className="demo-modal-content__thumb">
                  <img src={asset} alt="" />
                </div>
                <div className="demo-modal-content__info-content">
                  <div className="demo-modal-content__headline-wrap">
                    <h2 className="demo-modal-content__headline">{this.props.getTranslate('demo-Headline')}</h2>
                  </div>
                  <div className="demo-modal-content__description">{this.props.getTranslate('demo-Description')}</div>
                </div>
              </div>
              <div className="demo-modal-content__form">
                <div className="demo-modal-content__form-header">
                  <h2 className="demo-modal-content__form-headline">{this.props.getTranslate('demo-FormHeadline')}</h2>
                  <button className="close-modal" type="button" onClick={this.handlerStatusModal.bind(this)}>
                    <svg className="close-modal__icon">
                      <use href={`${sprite}#close-icon`}></use>
                    </svg>
                  </button>
                </div>
                {this.state.submitSuccess ? <div className="demo-modal-success">
                  <div className="demo-modal-success__inner">
                    <div className="demo-modal-success__icon-wrap">
                      <svg className="demo-modal-success__icon">
                        <use href={`${sprite}#form-success`}></use>
                      </svg>
                    </div>
                    <div className="demo-modal-success__headline">Запит на демо відправлено</div>
                    <div className="demo-modal-success__description">В найбилжчий час наш менеджер зав’яжиться з вами для уточнення деталей по демо.</div>
                    <Button type="button" text="Закрити" btnWidth="full" handler={this.handlerStatusModal.bind(this)} btnSize="large" />
                  </div>
                </div> : <form>
                  <Field
                    label={this.props.getTranslate('demo-FormLabelName')}
                    for="name"
                    placeholder={this.props.getTranslate('demo-FormPlaceholderName')}
                    value={this.state.nameValue}
                    onChange={this.handlerNameValue.bind(this)}
                  />
                  <Field
                    label={this.props.getTranslate('demo-FormLabelContacts')}
                    for="contact"
                    placeholder={this.props.getTranslate('demo-FormPlaceholderContacts')}
                    value={this.state.contactValue}
                    onChange={this.handlerContactValue.bind(this)}
                  />
                  <Field
                    label={this.props.getTranslate('demo-FormLabelCompany')}
                    for="company"
                    value={this.state.companyNameValue}
                    onChange={this.handlerCompanyValue.bind(this)}
                    placeholder={this.props.getTranslate('demo-FormPlaceholderCompany')}
                  />
                  <div className="field-wrap">
                    <label className="field-wrap__label">Кількість співробітників</label>
                    <Select
                      styles={customStyles}
                      options={this.state.countValues}
                      isSearchable={false}
                      onChange={this.handlerCountValue.bind(this)}
                      placeholder={this.props.getTranslate('demo-FormPlaceholderCompany')}
                    />
                  </div>
                  <Field
                    label={this.props.getTranslate('demo-FormLabelComment')}
                    for="comment"
                    placeholder={this.props.getTranslate('demo-FormPlaceholderComment')}
                    value={this.state.messageValue}
                    onChange={this.handlerMessageValue.bind(this)}
                    textarea={true}
                  />
                  <div className="field-wrap__submit">
                    <Button type="button" text={this.props.getTranslate('demo-FormBtnSubmit')} btnWidth="full" handler={this.handlerSubmit.bind(this)} btnSize="large" btnWidth="full" typeBtn="submit" loader={this.state.loading} />
                  </div>
                </form>}
              </div>
            </div>
          </div>
        </div>
      </div>
  }
}
export default DemoModal;
