import React from "react";
import { Link } from "react-router-dom";
import sprite from '../media/icons.svg';
import logo_nav from '../../logo_dark.svg';
class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuItems: []
    }
  }
  handlerSubMenu(item, event){
    let items = this.state.subMenuItems;
    let indexIsset = items.indexOf(item);
    if(indexIsset !== -1){
      this.setState(prevState => ({
        subMenuItems: prevState.subMenuItems.filter(el => el !== item)
      }))
    } else {
      this.setState(prevState => ({
        subMenuItems: [...prevState.subMenuItems, item]
      }));
    }
  }
  render() {
    let location;
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      location = window.location.pathname;
    }
    return <>
      {this.props.status && <div className="account-nav__background" onClick={this.props.handler.bind(this, false)}></div>}
      <div className={this.props.status ? 'account-nav account-nav__admin active' : !this.props.statusStart ? 'account-nav account-nav__admin disabled' : 'account-nav account-nav__admin'}>
        <div className="account-nav__inner">
          <div className="account-nav__header">
            <Link to="/controlpanel/dashboard">
              <img className="account-nav__logo" src={logo_nav} alt="" />
            </Link>
            <Link className="account-nav__log-out" to="/controlpanel">
              <svg>
                <use href={`${sprite}#log-out`}></use>
              </svg>
            </Link>
          </div>
          <div className="account-nav__content">
            <ul className="account-nav-list account-nav-list__personal">
              <li className="account-nav-list__item">
                <Link className={location === "/controlpanel/account" ? 'account-nav-list__link active' : 'account-nav-list__link'} to="/controlpanel/account">
                  <span className="account-nav-list__name">
                  <svg className="account-nav-list__icon">
                    <use href={`${sprite}#user-icon`}></use>
                  </svg>Иван</span>
                  <span className="account-nav-list__role">SuperAdmin</span>
                </Link>
              </li>
            </ul>
            <ul className="account-nav-list">
              <li className="account-nav-list__item">
                <Link className={location === "/controlpanel/companies" ? 'account-nav-list__link active' : 'account-nav-list__link'} to="/controlpanel/companies">
                  <span className="account-nav-list__name">
                  <svg className="account-nav-list__icon">
                    <use href={`${sprite}#companies-icon`}></use>
                  </svg>Компанії</span>
                </Link>
              </li>
              <li className="account-nav-list__item">
                <Link className={location === "/controlpanel/payments" ? 'account-nav-list__link active' : 'account-nav-list__link'} to="/controlpanel/payments">
                  <span className="account-nav-list__name">
                  <svg className="account-nav-list__icon">
                    <use href={`${sprite}#payments-icon`}></use>
                  </svg>Оплати</span>
                </Link>
              </li>
              <li className="account-nav-list__item">
                <Link className={location === "/controlpanel/translations" ? 'account-nav-list__link active' : 'account-nav-list__link'} to="/controlpanel/translations">
                  <span className="account-nav-list__name">
                  <svg className="account-nav-list__icon">
                    <use href={`${sprite}#translate-icon`}></use>
                  </svg>Переклади</span>
                </Link>
              </li>
              <li className="account-nav-list__item">
                <Link className={location === "/controlpanel/messages" ? 'account-nav-list__link active' : 'account-nav-list__link'} to="/controlpanel/messages">
                  <span className="account-nav-list__name">
                  <svg className="account-nav-list__icon">
                    <use href={`${sprite}#messages-icon`}></use>
                  </svg>Підтримка</span>
                </Link>
              </li>
              <li className="account-nav-list__item">
                <Link className={location === "/controlpanel/access" || location === "/controlpanel/group" ? 'account-nav-list__link active' : 'account-nav-list__link'} to="/controlpanel/access">
                  <span className="account-nav-list__name">
                  <svg className="account-nav-list__icon">
                    <use href={`${sprite}#roles-icon`}></use>
                  </svg>Доступи</span>
                </Link>
              </li>
              <li className="account-nav-list__item">
                <Link className={location === "/controlpanel/pages" ? 'account-nav-list__link active' : 'account-nav-list__link'} to="/controlpanel/pages">
                  <span className="account-nav-list__name">
                  <svg className="account-nav-list__icon">
                    <use href={`${sprite}#pages-icon`}></use>
                  </svg>Системні сторінки</span>
                </Link>
              </li>
              <li className="account-nav-list__item">
                <Link className={location === "/controlpanel/demo" ? 'account-nav-list__link active' : 'account-nav-list__link'} to="/controlpanel/demo">
                  <span className="account-nav-list__name">
                  <svg className="account-nav-list__icon">
                    <use href={`${sprite}#demo-icon`}></use>
                  </svg>Запити на демо</span>
                </Link>
              </li>
              <li className={`${this.state.subMenuItems.indexOf('content') !== -1 || location === '/controlpanel/blog' || location === '/controlpanel/glossary' ? "account-nav-list__item active" : "account-nav-list__item"}`}>
                <span className="account-nav-list__link" onClick={this.handlerSubMenu.bind(this, 'content')}>
                  <span className="account-nav-list__name">
                    <svg className="account-nav-list__icon">
                      <use href={`${sprite}#content-icon`}></use>
                    </svg>Контент</span>
                    <svg className="account-nav-list__arrow">
                      <use href={`${sprite}#arrow-down`}></use>
                    </svg>
                </span>
                <ul className="account-nav-list__sub">
                  <li className="account-nav-list__item">
                    <Link className={location === '/controlpanel/blog' ? 'account-nav-list__link active' : 'account-nav-list__link'} to={'/controlpanel/blog'}>
                      <span className="account-nav-list__name">Блог</span>
                    </Link>
                  </li>
                  <li className="account-nav-list__item">
                    <Link className={location === '/controlpanel/glossary' ? 'account-nav-list__link active' : 'account-nav-list__link'} to={'/controlpanel/glossary'}>
                      <span className="account-nav-list__name">Глосарій</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          </div>
        </div>
    </>
  }
}
export default Navigation;
