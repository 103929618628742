import React from "react";
import DateFormating from './components/DateFormating';
import AccountContentHeader from './components/AccountContentHeader';
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import PrevPage from './components/PrevPage';
import Loading from './components/Loading';
import { Link, useParams } from "react-router-dom";
import ResultErrors from './components/ResultErrors';
import sprite from './media/icons.svg';
import { getAuth } from "firebase/auth";
import ratingStar from './media/star-rating.svg';
import star from './media/star.svg';
class SurveyResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      modalClass: null,
      modalDisabled: false,
      modal: false,
      modalData: {},
      questionUserModal: false,
      resultData: null,
      modalUsersAnswer: null,
      errors: null,
      error: false,
      isLoaded: false,
      dropDownResult: false
    }
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        questionUserModal: false,
        modalData: {}
      }));
    }, 500);
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerDropDownResult(){
    this.setState(prevState => ({
      dropDownResult: !prevState.dropDownResult
    }));
  }
  handlerUserQuestionModal (users, answer, event) {
    event.preventDefault();
    if(answer){
      this.setState(prevState => ({
        modal: !prevState.modal,
        questionUserModal: !prevState.questionUserModal,
        modalClass: null,
        modalData: {},
        modalUsersAnswer: users.filter(item => item.answer === answer)
      }));
    } else {
      this.setState(prevState => ({
        modal: !prevState.modal,
        questionUserModal: !prevState.questionUserModal,
        modalClass: null,
        modalData: {},
        modalUsersAnswer: users
      }));
    }
  }
  handlerRelated(id, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/surveys/${id}/answers`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            errors: data.errors,
            error: true
          })
        });
      } else {
        res.json().then(function(data) {
          let questions = data.questions;
          let members = [];
          questions.forEach((item, i) => {
            item.options.forEach((option) => {
              if(option.userAnswers){
                if(members.indexOf(option.userAnswers.userID) === -1){
                  members.push(option.userAnswers.userID);
                }
              }
            });
          });
          that.setState({
            isLoaded: true,
            resultData: data,
            membersQuestion: members
          });
        })
      }
    })
  })
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    const onClick = (e) => {
      if(!e.target.classList.contains('questions-result-dropdown__btn')){
        this.setState(prevState => ({
             dropDownResult: false
      }));
    }
  }
  if(typeof window !== "undefined" && typeof document !== 'undefined'){
    document.body.addEventListener('click', onClick);
  }
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/surveys/${that.props.id}/answers`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            errors: data.errors,
            error: true
          })
        });
      } else {
        res.json().then(function(data) {
          let questions = data.questions;
          let members = [];
          questions.forEach((item, i) => {
            item.options.forEach((option) => {
              if(option.userAnswers){
                if(members.indexOf(option.userAnswers.userID) === -1){
                  members.push(option.userAnswers.userID);
                }
              }
            });
          });
          that.setState({
            isLoaded: true,
            resultData: data,
            membersQuestion: members
          });
        })
      }
    })
  })
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('resultPolls-Title') + ' | ' + this.props.companyName;
    }
    let resultContent = '';
    if(this.state.error) {
      resultContent = <div className="errors-content">Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultContent = <Loading />;
    } else {
      resultContent =  <div className="questions-result">
            <h2 className="questions-result__info-headline-mob">{this.props.getTranslate('resultPolls-DateHolding')} <DateFormating start={this.state.resultData.startTime} end={this.state.resultData.endTime} /></h2>
            <h2 className="questions-result__info-headline-mob">{this.props.getTranslate('resultPolls-amountUsersTitle')} {this.state.membersQuestion.length} / {this.state.resultData && this.state.resultData.employeeCount} чел.</h2>
            <div className="questions-result__info">
              <div className="questions-result__info-content">
                {this.state.resultData.surveyType === 'NORMAL' ?<h2 className="questions-result__info-headline">{this.props.getTranslate('resultPolls-DateHolding')} <DateFormating start={this.state.resultData.startTime} end={this.state.resultData.endTime} /></h2> : this.state.resultData.relatedSurveys ? this.state.resultData.relatedSurveys.length === 1 && this.state.resultData.relatedSurveys[0].surveyID === this.state.resultData.surveyID ? '' : <div className="questions-result__info-dropdown">
                  <span className="questions-result__info-dropdown-headline">{this.props.getTranslate('resultPolls-dropdownResult')}</span>
                  <div className="questions-result-dropdown">
                    <button className="questions-result-dropdown__btn" type="button" onClick={this.handlerDropDownResult.bind(this)}><DateFormating start={this.state.resultData.startTime} time="off" /></button>
                    <div className={`questions-result-dropdown__content ${this.state.dropDownResult ? 'active' : ''}`}>
                      <ul>
                      {this.state.resultData.relatedSurveys.map((item, index) => {
                        if(this.state.resultData.surveyID !== item.surveyID){
                          return <li key={index}>
                            <Link to={this.props.getPermalink(`/account/surveys/result/${item.surveyID}`)} onClick={this.handlerRelated.bind(this, item.surveyID)}><DateFormating start={item.surveyDate} time="off" /></Link>
                          </li>
                        }
                      })}
                      </ul>
                    </div>
                  </div>
                </div> : ''}
                <h2 className="questions-result__info-headline">{this.props.getTranslate('resultPolls-amountUsersTitle')} {this.state.membersQuestion.length} / {this.state.resultData && this.state.resultData.employeeCount} {this.props.getTranslate('polls-amountUsersTitlePerson')}</h2>
                <div className="questions-result__info-question">
                  {this.state.resultData ? <ul className="questions-result__users">
                    {this.state.resultData.usersWithPhotos.map((item, index) => {
                      return <li key={index}>
                        <Link to={this.props.getPermalink(`/account/users/view/${item.userID}`)}>
                          <img key={index} src={item.photo} alt="" />
                        </Link>
                      </li>
                    })}
                  </ul> : ''}
                  <div className="questions-result__type">{this.state.resultData && this.state.resultData.anonymous ? this.props.getTranslate('polls-Anonim') : this.props.getTranslate('polls-Public')}</div>
                </div>
              </div>
              <div className="questions-result__share">
                <button className="btn" type="button">
                  <span className="questions-result__share-txt">{this.props.getTranslate('polls-resultSharelabel')}</span>
                  <span className="questions-result__share-txt-mob">{this.props.getTranslate('polls-resultShareButton')}</span>
                </button>
              </div>
            </div>
            {this.state.resultData ? this.state.resultData.questions.map((item, index) => {
              let optionType = null;
              let contentOptions = null;
              return <div className="questions-result-wgt" key={index}>
                {this.state.resultData.surveyType === 'NORMAL' ? <><div className="questions-result-wgt__header">
                  <h3 className="questions-result-wgt__headline">№ {index + 1} {item.question}</h3>
                </div>
                <div className="questions-result-wgt__options">
                  {item.options.map((option, i) => {
                    return           <div className="questions-result-option" key={i}>
                                      <div className="questions-result-option__header">
                                        <div className="questions-result-option__header-inner">
                                          <div className="questions-result-option__percent">{option.statistic + '%'}</div>
                                          <div className="questions-result-option__headline">{option.answerOptionType !== 'FREE' ? option.name : this.props.getTranslate('polls-resultFreeOption')}</div>
                                        </div>
                                        <div className="questions-result-option__info">
                                          {option.userAnswers ? <div className="questions-result-option__amount" onClick={this.handlerUserQuestionModal.bind(this, option.userAnswers, null)}>{option.userAnswers.length} {this.props.getTranslate('polls-amountUsersTitlePerson')}</div> : <div className="questions-result-option__amount-empty">0 {this.props.getTranslate('polls-amountUsersTitlePerson')}</div>}
                                        </div>
                                      </div>
                                      <div className="questions-result-option__progress-wrap">
                                        <div className="questions-result-option__progress">
                                          <div className="questions-result-option__progress-inner" style={{width: option.statistic + '%'}}></div>
                                        </div>
                                      </div>
                                      {option.userAnswers && option.answerOptionType === 'FREE' ? <div className="questions-result-option__free-wrapper">
                                      {option.userAnswers.map((answer, answerIter) => {
                                        return <div className="questions-result-option__free-wgt" key={answerIter}>
                                          <div className="questions-result-option__free-wgt-name">{answer.answer}</div>
                                          <img className="questions-result-option__free-wgt-photo" src={answer.userPhoto} alt="" />
                                          <div className="questions-result-option__free-wgt-username">{answer.userName}</div>
                                        </div>
                                      })}</div> : ''}
                                    </div>
                  })}
                </div></> : <>
                  {item.options.map((option, i) => {
                    if(option.answerOptionType === 'RATE'){
                      let onePercentageLength = option.userAnswers ? option.userAnswers.filter(item => item.answer === '1').length : 0;
                      let twoPercentageLength = option.userAnswers ? option.userAnswers.filter(item => item.answer === '2').length : 0;
                      let threePercentageLength = option.userAnswers ? option.userAnswers.filter(item => item.answer === '3').length : 0;
                      let fourPercentageLength = option.userAnswers ? option.userAnswers.filter(item => item.answer === '4').length : 0;
                      let fivePercentageLength = option.userAnswers ? option.userAnswers.filter(item => item.answer === '5').length : 0;
                      let totalVotes = onePercentageLength + twoPercentageLength + threePercentageLength + fourPercentageLength + fivePercentageLength;
                      let onePercentage = onePercentageLength ? Math.round((onePercentageLength / totalVotes) * 100) : 0;
                      let twoPercentage = twoPercentageLength ? Math.round((twoPercentageLength / totalVotes) * 100) : 0;
                      let threePercentage = threePercentageLength ? Math.round((threePercentageLength / totalVotes) * 100) : 0;
                      let fourPercentage = fourPercentageLength ? Math.round((fourPercentageLength / totalVotes) * 100) : 0;
                      let fivePercentage = fivePercentageLength ? Math.round((fivePercentageLength / totalVotes) * 100) : 0;
                      optionType = this.props.getTranslate('polls-resultAnswerRating');
                      contentOptions = <>

                      <div className="questions-result-option">
                      <div className="questions-result-option__header">
                        <div className="questions-result-option__header-inner">
                          <div className="questions-result-option__percent">{fivePercentage + '%'}</div>
                          <div className="questions-result-option__headline">
                            <ul>
                              <li><img src={ratingStar} alt="" /></li>
                              <li><img src={ratingStar} alt="" /></li>
                              <li><img src={ratingStar} alt="" /></li>
                              <li><img src={ratingStar} alt="" /></li>
                              <li><img src={ratingStar} alt="" /></li>
                            </ul>
                          </div>
                        </div>
                        <div className="questions-result-option__info">
                          {option.userAnswers ? <div className="questions-result-option__amount" onClick={this.handlerUserQuestionModal.bind(this, option.userAnswers, '5')}>{option.userAnswers.filter(item => item.answer === '5').length} {this.props.getTranslate('polls-amountUsersTitlePerson')}</div> : <div className="questions-result-option__amount-empty">0 {this.props.getTranslate('polls-amountUsersTitlePerson')}</div>}
                        </div>
                      </div>
                                        <div className="questions-result-option__progress-wrap">
                                        <div className="questions-result-option__progress">
                                          <div className="questions-result-option__progress-inner" style={{width: fivePercentage + '%'}}></div>
                                        </div>
                                      </div></div>

                      <div className="questions-result-option">
                      <div className="questions-result-option__header">
                        <div className="questions-result-option__header-inner">
                          <div className="questions-result-option__percent">{fourPercentage + '%'}</div>
                          <div className="questions-result-option__headline">
                            <ul>
                              <li><img src={ratingStar} alt="" /></li>
                              <li><img src={ratingStar} alt="" /></li>
                              <li><img src={ratingStar} alt="" /></li>
                              <li><img src={ratingStar} alt="" /></li>
                              <li><img src={star} alt="" /></li>
                            </ul>
                          </div>
                        </div>
                        <div className="questions-result-option__info">
                          {option.userAnswers ? <div className="questions-result-option__amount" onClick={this.handlerUserQuestionModal.bind(this, option.userAnswers, '4')}>{option.userAnswers.filter(item => item.answer === '4').length} {this.props.getTranslate('polls-amountUsersTitlePerson')}</div> : <div className="questions-result-option__amount-empty">0 {this.props.getTranslate('polls-amountUsersTitlePerson')}</div>}
                        </div>
                      </div>
                                        <div className="questions-result-option__progress-wrap">
                                        <div className="questions-result-option__progress">
                                          <div className="questions-result-option__progress-inner" style={{width: fourPercentage + '%'}}></div>
                                        </div>
                                      </div></div>

                      <div className="questions-result-option">
                      <div className="questions-result-option__header">
                        <div className="questions-result-option__header-inner">
                          <div className="questions-result-option__percent">{threePercentage + '%'}</div>
                          <div className="questions-result-option__headline">
                            <ul>
                              <li><img src={ratingStar} alt="" /></li>
                              <li><img src={ratingStar} alt="" /></li>
                              <li><img src={ratingStar} alt="" /></li>
                              <li><img src={star} alt="" /></li>
                              <li><img src={star} alt="" /></li>
                            </ul>
                          </div>
                        </div>
                        <div className="questions-result-option__info">
                          {option.userAnswers ? <div className="questions-result-option__amount" onClick={this.handlerUserQuestionModal.bind(this, option.userAnswers, '3')}>{option.userAnswers.filter(item => item.answer === '3').length} {this.props.getTranslate('polls-amountUsersTitlePerson')}</div> : <div className="questions-result-option__amount-empty">0 {this.props.getTranslate('polls-amountUsersTitlePerson')}</div>}
                        </div>
                      </div>
                                        <div className="questions-result-option__progress-wrap">
                                        <div className="questions-result-option__progress">
                                          <div className="questions-result-option__progress-inner" style={{width: threePercentage + '%'}}></div>
                                        </div>
                                      </div></div>

                      <div className="questions-result-option">
                      <div className="questions-result-option__header">
                        <div className="questions-result-option__header-inner">
                          <div className="questions-result-option__percent">{twoPercentage + '%'}</div>
                          <div className="questions-result-option__headline">
                            <ul>
                              <li><img src={ratingStar} alt="" /></li>
                              <li><img src={ratingStar} alt="" /></li>
                              <li><img src={star} alt="" /></li>
                              <li><img src={star} alt="" /></li>
                              <li><img src={star} alt="" /></li>
                            </ul>
                          </div>
                        </div>
                        <div className="questions-result-option__info">
                          {option.userAnswers ? <div className="questions-result-option__amount" onClick={this.handlerUserQuestionModal.bind(this, option.userAnswers, '2')}>{option.userAnswers.filter(item => item.answer === '2').length} {this.props.getTranslate('polls-amountUsersTitlePerson')}</div> : <div className="questions-result-option__amount-empty">0 {this.props.getTranslate('polls-amountUsersTitlePerson')}</div>}
                        </div>
                      </div>
                                        <div className="questions-result-option__progress-wrap">
                                        <div className="questions-result-option__progress">
                                          <div className="questions-result-option__progress-inner" style={{width: twoPercentage + '%'}}></div>
                                        </div>
                                      </div></div>


                      <div className="questions-result-option">
                      <div className="questions-result-option__header">
                        <div className="questions-result-option__header-inner">
                          <div className="questions-result-option__percent">{onePercentage + '%'}</div>
                          <div className="questions-result-option__headline">
                            <ul>
                              <li><img src={ratingStar} alt="" /></li>
                              <li><img src={star} alt="" /></li>
                              <li><img src={star} alt="" /></li>
                              <li><img src={star} alt="" /></li>
                              <li><img src={star} alt="" /></li>
                            </ul>
                          </div>
                        </div>
                        <div className="questions-result-option__info">
                          {option.userAnswers ? <div className="questions-result-option__amount" onClick={this.handlerUserQuestionModal.bind(this, option.userAnswers, '1')}>{option.userAnswers.filter(item => item.answer === '1').length} {this.props.getTranslate('polls-amountUsersTitlePerson')}</div> : <div className="questions-result-option__amount-empty">0 {this.props.getTranslate('polls-amountUsersTitlePerson')}</div>}
                        </div>
                      </div>
                                        <div className="questions-result-option__progress-wrap">
                                        <div className="questions-result-option__progress">
                                          <div className="questions-result-option__progress-inner" style={{width: onePercentage + '%'}}></div>
                                        </div>
                                      </div></div>

                                      </>
                    } else if(option.answerOptionType === 'YES_NO' || option.answerOptionType === 'CHECKBOX') {
                      let yesPercentageLength = option.userAnswers ? option.userAnswers.filter(item => item.answer === 'yes').length : 0;
                      let noPercentageLength = option.userAnswers ? option.userAnswers.filter(item => item.answer === 'no').length : 0;
                      let totalVotes = yesPercentageLength + noPercentageLength;
                      let yesPercentage = yesPercentageLength ? Math.round((yesPercentageLength / totalVotes) * 100) : 0;
                      let noPercentage = noPercentageLength ? Math.round((noPercentageLength / totalVotes) * 100) : 0;
                      optionType = this.props.getTranslate('polls-resultYesNoRating');
                      contentOptions = <><div className="questions-result-option">
                      <div className="questions-result-option__header">
                        <div className="questions-result-option__header-inner">
                          <div className="questions-result-option__percent">{yesPercentage + '%'}</div>
                          <div className="questions-result-option__headline">{this.props.getTranslate('polls-resultYesRating')}</div>
                        </div>
                        <div className="questions-result-option__info">
                          {option.userAnswers ? <div className="questions-result-option__amount" onClick={this.handlerUserQuestionModal.bind(this, option.userAnswers, 'yes')}>{option.userAnswers.filter(item => item.answer === 'yes').length} {this.props.getTranslate('polls-amountUsersTitlePerson')}</div> : <div className="questions-result-option__amount-empty">0 {this.props.getTranslate('polls-amountUsersTitlePerson')}</div>}
                        </div>
                      </div>
                                        <div className="questions-result-option__progress-wrap">
                                        <div className="questions-result-option__progress">
                                          <div className="questions-result-option__progress-inner" style={{width: yesPercentage + '%'}}></div>
                                        </div>
                                      </div></div>
                                      <div className="questions-result-option">
                                      <div className="questions-result-option__header">
                                        <div className="questions-result-option__header-inner">
                                          <div className="questions-result-option__percent">{noPercentage + '%'}</div>
                                          <div className="questions-result-option__headline">{this.props.getTranslate('polls-resultNoRating')}</div>
                                        </div>
                                        <div className="questions-result-option__info">
                                          {option.userAnswers ? <div className="questions-result-option__amount" onClick={this.handlerUserQuestionModal.bind(this, option.userAnswers, 'no')}>{option.userAnswers.filter(item => item.answer === 'no').length} {this.props.getTranslate('polls-amountUsersTitlePerson')}</div> : <div className="questions-result-option__amount-empty">0 {this.props.getTranslate('polls-amountUsersTitlePerson')}</div>}
                                        </div>
                                      </div>
                                      <div className="questions-result-option__progress-wrap">
                                                        <div className="questions-result-option__progress">
                                                          <div className="questions-result-option__progress-inner" style={{width: noPercentage + '%'}}></div>
                                                        </div>
                                                      </div></div>
                                      </>;
                    } else if(option.answerOptionType === 'YES_NO_DONT_KNOW') {
                      let yesPercentageLength = option.userAnswers ? option.userAnswers.filter(item => item.answer === 'yes').length : 0;
                      let noPercentageLength = option.userAnswers ? option.userAnswers.filter(item => item.answer === 'no').length : 0;
                      let undecidedVotesLength = option.userAnswers ? option.userAnswers.filter(item => item.answer === 'dont_know').length : 0;
                      let totalVotes = yesPercentageLength + noPercentageLength + undecidedVotesLength;
                      let yesPercentage = yesPercentageLength ? Math.round((yesPercentageLength / totalVotes) * 100) : 0;
                      let noPercentage = noPercentageLength ? Math.round((noPercentageLength / totalVotes) * 100) : 0;
                      let undecidedVotes = undecidedVotesLength ? Math.round((undecidedVotesLength / totalVotes) * 100) : 0;
                      optionType = this.props.getTranslate('polls-resultYesNoDontKnowRating');
                      contentOptions = <><div className="questions-result-option">
                      <div className="questions-result-option__header">
                        <div className="questions-result-option__header-inner">
                          <div className="questions-result-option__percent">{yesPercentage + '%'}</div>
                          <div className="questions-result-option__headline">{this.props.getTranslate('polls-resultYesRating')}</div>
                        </div>
                        <div className="questions-result-option__info">
                          {option.userAnswers ? <div className="questions-result-option__amount" onClick={this.handlerUserQuestionModal.bind(this, option.userAnswers, 'yes')}>{option.userAnswers.filter(item => item.answer === 'yes').length} {this.props.getTranslate('polls-amountUsersTitlePerson')}</div> : <div className="questions-result-option__amount-empty">0 {this.props.getTranslate('polls-amountUsersTitlePerson')}</div>}
                        </div>
                      </div>
                                        <div className="questions-result-option__progress-wrap">
                                        <div className="questions-result-option__progress">
                                          <div className="questions-result-option__progress-inner" style={{width: yesPercentage + '%'}}></div>
                                        </div>
                                      </div></div>
                                      <div className="questions-result-option">
                                      <div className="questions-result-option__header">
                                        <div className="questions-result-option__header-inner">
                                          <div className="questions-result-option__percent">{noPercentage + '%'}</div>
                                          <div className="questions-result-option__headline">{this.props.getTranslate('polls-resultNoRating')}</div>
                                        </div>
                                        <div className="questions-result-option__info">
                                          {option.userAnswers ? <div className="questions-result-option__amount" onClick={this.handlerUserQuestionModal.bind(this, option.userAnswers, 'no')}>{option.userAnswers.filter(item => item.answer === 'no').length} {this.props.getTranslate('polls-amountUsersTitlePerson')}</div> : <div className="questions-result-option__amount-empty">0 {this.props.getTranslate('polls-amountUsersTitlePerson')}</div>}
                                        </div>
                                      </div>
                                      <div className="questions-result-option__progress-wrap">
                                                        <div className="questions-result-option__progress">
                                                          <div className="questions-result-option__progress-inner" style={{width: noPercentage + '%'}}></div>
                                                        </div>
                                                      </div></div>
                                                      <div className="questions-result-option">
                                                      <div className="questions-result-option__header">
                                                        <div className="questions-result-option__header-inner">
                                                          <div className="questions-result-option__percent">{undecidedVotes + '%'}</div>
                                                          <div className="questions-result-option__headline">{this.props.getTranslate('polls-resultDontKnowRating')}</div>
                                                        </div>
                                                        <div className="questions-result-option__info">
                                                          {option.userAnswers ? <div className="questions-result-option__amount" onClick={this.handlerUserQuestionModal.bind(this, option.userAnswers, 'dont_know')}>{option.userAnswers.filter(item => item.answer === 'dont_know').length} {this.props.getTranslate('polls-amountUsersTitlePerson')}</div> : <div className="questions-result-option__amount-empty">0 {this.props.getTranslate('polls-amountUsersTitlePerson')}</div>}
                                                        </div>
                                                      </div>
                                                      <div className="questions-result-option__progress-wrap">
                                                                        <div className="questions-result-option__progress">
                                                                          <div className="questions-result-option__progress-inner" style={{width: undecidedVotes + '%'}}></div>
                                                                        </div>
                                                                      </div></div>
                                      </>
                    } else {
                      optionType = this.props.getTranslate('polls-resultFreeOption');
                      contentOptions = option.userAnswers ? <div className="questions-result-option__free-wrapper questions-result-option__free-wrapper-quick">
                      {option.userAnswers.map((answer, answerIter) => {
                        return <div className="questions-result-option__free-wgt" key={answerIter}>
                          <div className="questions-result-option__free-wgt-name">{answer.answer}</div>
                          <img className="questions-result-option__free-wgt-photo" src={answer.userPhoto} alt="" />
                          <div className="questions-result-option__free-wgt-username">{answer.userName}</div>
                        </div>
                      })}</div> : ''
                    }
                  })}
                <div className="questions-result-option">
                 <div className="questions-result-wgt__header">
                  <h3 className="questions-result-wgt__headline">{item.question + ' ' + '(' + optionType + ')'}</h3>
                </div>
                <div className="questions-result-wgt__options">
                  {contentOptions}
                </div></div></>}
              </div>
            }) : ''}
          </div>
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      <div className="account-content">
        <div className="account-content__inner">

            {/* Account header component */}
            <AccountContentHeader
              handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
              languageActive={this.props.chooseLanguage}
              prevPageBtn={<PrevPage text={this.props.getTranslate('buttonBack-buttonText')} />}
            />

          <div className="account-content__header-title">
            <h1 className="account-content__headline">{this.props.getTranslate('resultPolls-Title')} {this.state.resultData && this.state.resultData.title}</h1>
          </div>
          {resultContent}
        </div>
      </div>
    </div>
    {this.state.modal && this.state.questionUserModal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.modalClass}` : `two ${this.state.modalClass}`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.state.modalUsersAnswer ? this.state.modalUsersAnswer.length : 0} {this.props.getTranslate('polls-amountUsersTitlePerson')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        {this.state.modalUsersAnswer ? <><div className="field-wrap field-wrap__search-members">
          <input className="field-wrap__input" type="text" placeholder={this.props.getTranslate('modal-addUsersSearchPlaceholder')} />
          <svg className="field-wrap__search-icon">
            <use href={`${sprite}#search-icon`}></use>
          </svg>
        </div>
        <div className="question-members-wgt question-members-wgt__result">
          {this.state.modalUsersAnswer.map((item, index) => {
            return <div className="question-members-wgt__item" key={index}>
              <div className="question-members-wgt__img">
                <img src={item.userPhoto} alt="" />
              </div>
              <span className="question-members-wgt__name">{item.userName}</span>
            </div>
          })}
        </div></> : ''}
      </div>
    </div> : ''}
    </>;
  }
}
export default (props) => <SurveyResult {...useParams()} {...props} />
