import React from "react";
import Select from 'react-select';
import AccountHeader from './components/AccountHeader';
import ResultErrors from './components/ResultErrors';
import Navigation from './components/Navigation';
import Pagination from './components/Pagination';
import Loading from './components/Loading';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class ControlPanelTranslations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      modalCreate: false,
      searchField: false,
      translationsData: [],
      englishValue: '',
      ukrainianValue: '',
      valueTranslationsAddKey: '',
      englishTranslationsAddValue: '',
      ukrainianTranslationsAddValue: '',
      valueTranslationsAddType: '',
      translationID: null,
      labelValue: null,
      translationType: null,
      translationIndex: null,
      isLoadedSubmit: false,
      totalPages: null,
      currentPage: 0,
      searchEmpty: false,
      timer: null,
      isLoaded: false,
      error: false,
      errors: null,
      controlRow: null,
      token: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("token-admin") ? JSON.parse(localStorage.getItem("token-admin")) : JSON.parse('')) : ''
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modal: false,
        modalCreate: false
      }));
    }, 500);
  }
  handlerPaginationItems = (translationItems, current) => {
    this.setState({
      translationsData: translationItems,
      currentPage: current
    });
  }
  handlerGetModal(id, index, event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/translations/${id}`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            englishValue: data.english,
            ukrainianValue: data.ukrainian,
            labelValue: data.label,
            translationID: data.translationID,
            translationType: data.translationType,
            translationIndex: index
          });
        })
        that.setState(prevState => ({
          modal: !prevState.modal
        }));
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.message
          });
        })
      }
    })
  })
  }
  handlerGetModalCreate(event) {
    let that = this;
    that.setState(prevState => ({
      modalCreate: !prevState.modalCreate
    }));
  }
  componentDidMount() {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/translations/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({"itemsPerPage": 20})
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            translationsData: data.data,
            totalPages: data.totalPages,
            isLoaded: true
          });
        })
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            errors: [data.message],
            isLoaded: true,
            error: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.message,
            isLoaded: true,
            error: true
          });
        })
      }
    })
  })
  }
  handlerTranslationsValue(language, event){
    if(language === 'english'){
      this.setState({
        englishValue: event.target.value
      });
    } else {
      this.setState({
        ukrainianValue: event.target.value
      });
    }
  }
  handlerSubmit(event){
    let that = this;
    event.preventDefault();
    that.setState({
      isLoadedSubmit: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/translations/${that.state.translationID}`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        "english": that.state.englishValue,
        "label": that.state.labelValue,
        "translationID": that.state.translationID,
        "translationType": that.state.translationType,
        "ukrainian": that.state.ukrainianValue
      })
    }).then(res => {
      if (res.status === 200) {
        let data = that.state.translationsData;
        data[that.state.translationIndex].ukrainian = that.state.ukrainianValue;
        data[that.state.translationIndex].english = that.state.englishValue;
        that.setState({
          translationsData: data,
          isLoadedSubmit: false,
          englishValue: '',
          labelValue: null,
          translationID: null,
          translationType: null,
          ukrainianValue: '',
          translationIndex: null
        });
        that.handleModalDisabled();
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            isLoadedSubmit: false
          });
        })
      }
    })
  })
  }
  handlerSearchField () {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    if(that.state.searchField){
      that.setState({
        translationsData: [],
        isLoaded: false,
        searchEmpty: false
      });
      fetch(`${that.props.queryLink}/bo/v1/configs/translations/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20})
      }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            translationsData: data.data,
            totalPages: data.totalPages,
            isLoaded: true
          });
        })
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            errors: [data.message],
            isLoaded: true,
            error: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.message,
            isLoaded: true,
            error: true
          });
        })
      }
    })
  }
    that.setState(prevState => ({
      searchField: !prevState.searchField
    }));
  })
  }
  handlerSearch(event){
    let that = this;
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + that.props.token()
    });
    if(that.state.timer){
      clearTimeout(that.state.timer);
    }
    that.setState({
      translationsData: [],
      searchValue: event.target.value,
      searchEmpty: false,
      isLoaded: false
    });
    let timer = setTimeout(() => {
      fetch(`${that.props.queryLink}/bo/v1/configs/translations/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20, "label": that.state.searchValue})
      }).then(res => {
        if (res.status === 200) {
          res.json().then(function(data) {
            that.setState({
              translationsData: data.data,
              totalPages: data.totalPages,
              isLoaded: true,
              timer: null
            });
            if(data.data.length < 1){
              that.setState({
                searchEmpty: true
              });
            }
          })
        } else if(res.status === 401) {
          res.json().then(function(data) {
            that.setState({
              errors: [data.message],
              isLoaded: true,
              error: true
            });
          })
        } else {
          res.json().then(function(data) {
            that.setState({
              errors: data.message,
              isLoaded: true,
              error: true
            });
          })
        }
      })
    }, 1000);
    that.setState({
      timer: timer
    });
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerCloseElements(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const div = document.querySelector( '.control-wgt');
      document.addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(div);
        if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
          if ( ! withinBoundaries ) {
            this.setState( {
              controlRow: null
            } );
          }
        }
      });
    }
  }
  handlerTranslationsAddKey(event){
    this.setState({
      valueTranslationsAddKey: event.target.value
    });
  }
  handlerTranslationsAddValue(language, event){
    if(language === 'english'){
      this.setState({
        englishTranslationsAddValue: event.target.value
      });
    } else {
      this.setState({
        ukrainianTranslationsAddValue: event.target.value
      });
    }
  }
  handlerTranslationsAddType(event){
    this.setState({
      valueTranslationsAddType: event.value
    });
  }
  handlerAddTranslatedSubmit(event){
    let that = this;
    event.preventDefault();
    that.setState({
      isLoadedSubmit: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/translations`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "english": that.state.englishTranslationsAddValue,
        "label": that.state.valueTranslationsAddKey,
        "translationType": that.state.valueTranslationsAddType,
        "ukrainian": that.state.ukrainianTranslationsAddValue
      })
    }).then(res => {
      if (res.status === 200) {
        that.setState({
          isLoadedSubmit: false
        });
        that.handleModalDisabled();
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            isLoadedSubmit: false
          });
        })
      }
    })
  })
  }
  render() {
    let translationsDataTable = '';
    if(this.state.error) {
      translationsDataTable = 'Помилка';
    } else if(!this.state.isLoaded){
      translationsDataTable = <Loading />;
    } else {
      translationsDataTable = <table className="data-table">
        <thead>
          <tr>
            <th>Ключ</th>
            <th>English</th>
            <th>Українська</th>
            <th></th>
          </tr>
        </thead>
      <tbody>
      {this.state.translationsData.map((item, index) => {
        return <tr className="standart-table__row" key={index}>
                <td>{item.label}</td>
                <td className="standart-table__translation">{item.english}</td>
                <td className="standart-table__translation">{item.ukrainian}</td>
                <td>
                <div className="team-table__control">
                  <div className="control-wgt">
                    <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                      <svg className="control-btn__icon">
                        <use href={`${sprite}#more-icon`}></use>
                      </svg>
                      </button>
                      <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                        <ul className="control-content__list">
                          <li>
                            <button className="control-content__btn" type="button" onClick={this.handlerGetModal.bind(this, item.translationID, index)}>
                              <svg className="control-content__icon">
                                <use href={`${sprite}#edit-icon`}></use>
                              </svg>Редагувати</button>
                          </li>
                        </ul>
                      </div>
                      </div>
                  </div>
                </td>
              </tr>
      })}
      </tbody>
    </table>;
    }
    return <>
      <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
      <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-wrap account-wrap__admin" onClick={this.handlerCloseElements.bind(this)}>
        <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
        <div className="account-content">
          <div className="account-content__inner">
            <div className="account-content__header">
              <div className="account-content__header-inner">
                <h1 className="account-content__headline">Переклади</h1>
                <div className="account-content__header-add-button">
                  <button className="add-button" type="button" onClick={this.handlerGetModalCreate.bind(this)}><svg className="add-button__icon"><use href={`${sprite}#plus-icon`}></use></svg></button>
                </div>
                {!this.state.searchField && <button className="search-btn" type="button" onClick={this.handlerSearchField.bind(this)}>
                  <svg className="order-search-icon"><use href={`${sprite}#search-icon`}></use></svg>
                </button>}
              {this.state.searchField && <div className="search-orders-wrap"><form className="search-orders">
                <div className="search-field-wrap">
                  <button className="search-submit" type="submit">
                    <svg className="order-search-icon"><use href={`${sprite}#search-icon`}></use></svg>
                  </button>
                  <button className="search-close" type="button" onClick={this.handlerSearchField.bind(this)}>
                    <svg className="search-close__icon">
                      <use href={`${sprite}#close-icon`}></use>
                    </svg>
                  </button>
                  <input className="search-field" placeholder="Введіть ключ або текст переводу" value={this.state.searchValue} onChange={this.handlerSearch.bind(this)} type="text" />
                </div>
              </form></div>}
              </div>
              <a className="header__language header__language-content" href="#">RU</a>
            </div>
            <div className="account-content__content">
              <div className="account-wgt">
                {translationsDataTable}
              </div>
            </div>
            <div className="account-content__footer">
              <Pagination total={this.state.totalPages} perPage="20" path={`${this.props.queryLink}/bo/v1/configs/translations/search`} currentPage={this.state.currentPage} items={this.handlerPaginationItems} otherParams={{label: this.state.searchValue}} />
            </div>
          </div>
        </div>
      </div>
      {this.state.modal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">{this.state.labelValue}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <form onSubmit={this.handlerSubmit.bind(this)}>
            <div className="field-wrap">
              <label className="field-wrap__label">English</label>
              <textarea className="field-wrap__input field-wrap__input-area" value={this.state.englishValue} onChange={this.handlerTranslationsValue.bind(this, 'english')} placeholder="Введіть назву" />
            </div>
            <div className="field-wrap">
              <label className="field-wrap__label">Українська</label>
              <textarea className="field-wrap__input field-wrap__input-area" value={this.state.ukrainianValue} onChange={this.handlerTranslationsValue.bind(this, 'ukrainian')} placeholder="Введіть назву" />
            </div>
            <div className="field-wrap__submit">
              <button className="btn btn_width_100 btn_size_lg" type="submit" disabled={this.state.isLoadedSubmit ? true : false}>Зберегти{this.state.isLoadedSubmit && <Loading />}</button>
            </div>
          </form>
        </div>
      </div> : ''}

      {this.state.modalCreate ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">Додати ключ та переклад</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <form onSubmit={this.handlerAddTranslatedSubmit.bind(this)}>
            <div className="field-wrap">
              <input className="field-wrap__input" value={this.state.valueTranslationsAddKey} onChange={this.handlerTranslationsAddKey.bind(this)} placeholder="Введіть ключове слово до перекладу" />
            </div>
            <div className="field-wrap">
              <Select
                styles={customStyles}
                options={[
                  { value: 'BO', label: 'Адмінка компанії' },
                  { value:  'ADMIN_PANEL', label: 'Суперадмінка'}
                ]}
                onChange={this.handlerTranslationsAddType.bind(this)}
                placeholder="Виберіть куди додавати ключ"
                isSearchable={false}
              />
            </div>
            <div className="field-wrap">
              <label className="field-wrap__label">English</label>
              <textarea className="field-wrap__input field-wrap__input-area" value={this.state.englishTranslationsAddValue} onChange={this.handlerTranslationsAddValue.bind(this, 'english')} placeholder="Введіть назву" />
            </div>
            <div className="field-wrap">
              <label className="field-wrap__label">Українська</label>
              <textarea className="field-wrap__input field-wrap__input-area" value={this.state.ukrainianTranslationsAddValue} onChange={this.handlerTranslationsAddValue.bind(this, 'ukrainian')} placeholder="Введіть назву" />
            </div>
            <div className="field-wrap__submit">
              <button className="btn btn_width_100 btn_size_lg" type="submit" disabled={this.state.isLoadedSubmit ? true : false}>Зберегти{this.state.isLoadedSubmit && <Loading />}</button>
            </div>
          </form>
        </div>
      </div> : ''}
    </>
  }
}
export default ControlPanelTranslations;
